import React from 'react'
import {
  Elements,
  ElementsConsumer,
  CardElement,
} from "@stripe/react-stripe-js";
import NewCheckOutForm from "./NewCheckOutForm"
import {loadStripe} from '@stripe/stripe-js';
const CheckoutForm = () => {
  let stripePromise = loadStripe("pk_test_LSX57tvD3Cp8Ac9vdcsgc9Dr");
  const CLIENT_SECRET ="sk_test_WJqza5T42u1ORiPgYoz4bSHw";

  const options = {
    // passing the client secret obtained from the server
    clientSecret: CLIENT_SECRET,
  };
  return (
 <>
    <Elements stripe={stripePromise}>
    <NewCheckOutForm />
      </Elements>
 </>
  )
}

export default CheckoutForm