import * as actionTypes from "./actionTypes";
import Axios from "../../helper/axios";
import firebase from "firebase";
import { SuccessMessage } from "./contactActions";
import { getUserRollDetails } from "./accountsettingAction";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START2,
  };
};

export const inviteUsers = () => {
  return {
    type: actionTypes.INVITEUSERS,
  };
};
export const inviteUsersSuccess = (token, user) => {
  return {
    type: actionTypes.INVITEUSERSSUCCESS,
    user,
  };
};
export const inviteUsersFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};
export const authSuccess = (token, user) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    user,
  };
};

export const isLoading = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const Success = (response) => {
  return response;
};

export const Fail = (error) => {
  return {
    type: actionTypes.AUTHERROR,
    error: error,
  };
};

export const FailReg = (error) => {
  return {
    type: actionTypes.AUTHERROR,
    error: error,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const email_verify = (data, history) => {
  return (dispatch) => {
    dispatch(isLoading());
    Axios()
      .post("auth/verify_email", data)
      .then((response) => {
        // console.log(response)

        if (response.data.status === "1") {
          dispatch(
            Success({
              type: actionTypes.EMAIL_VERIFY,
              payload: response.data,
            })
          );

          history.push("/login");
        } else {
          // console.log(response.data.message)
          dispatch(Fail(response.data.message));
        }
      })
      .catch((err) => {
        dispatch(Fail(err.message));
      });
  };
};

export const deactivate_account = (data) => {
  let resp = {
    status: false,
  };

  return async (dispatch) => {
    try {
      dispatch(isLoading());
      const response = await Axios().post("auth/deactivate_account", data);
      if (response.data.status === "1") {
        await localStorage.clear();
        dispatch(SuccessMessage(response.data.message));
        resp.status = true;
      } else {
        dispatch(Fail(response.data.message));
      }
    } catch (error) {
      dispatch(Fail(error.message));
    }

    return resp;
  };
};

export const auth = (email, password) => {
  return (dispatch) => {
    dispatch(authStart());

    let device_token = localStorage.getItem("device_token");

    var sessionToken = sessionStorage.getItem("token_de");

    console.warn("sessionToken123", sessionToken);

    const authData = {
      email: email,
      password: password,
      device_token: sessionToken,
      platform: "Desktop",
    };

    Axios()
      .post("auth/login", authData)
      .then((response) => {
        console.warn("response123", response);
        if (response.data.status === "1") {
          // Firebase login
          firebase
            .auth()
            .signInWithEmailAndPassword(authData.email, authData.password);

          const {
            _id,
            user_id,
            business_code,
            country_name,
            device_token,
            notification_status,
            notification_chat_status,
            notification_post_status,
            notification_msg_center_status,
            profile_image,
            first_name,
            parent_id,
            last_name,
            sc_bessi_name,
            address,
            is_subscribe,
            post_code,
            email,
            mobile_no,
            account_type,
            first_login,
            default_category,
            olduid,
          } = response.data.data;

          const userData = {
            _id,
            parent_id,
            user_id,
            business_code,
            country_name,
            device_token,
            notification_status,
            notification_chat_status,
            notification_post_status,
            notification_msg_center_status,
            profile_image,
            first_name,
            last_name,
            sc_bessi_name,
            is_subscribe,
            address,
            post_code,
            email,
            mobile_no,
            account_type,
            first_login,
            default_category,
            olduid,
          };

          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem("userData", JSON.stringify(userData));
          dispatch(authSuccess(response.data.idToken, response.data.data));
        } else {
          dispatch(authFail(response.data.message));
        }
      })
      .catch((err) => {
        dispatch(authFail(err.message));
      });
  };
};

export const inviteUser = (email) => {
  return (dispatch) => {
    dispatch(inviteUsers());
    const inviteData = {
      email: email,
    };
    Axios()
      .post("web/send-invite", inviteData)
      .then((response) => {
        console.warn("response123", response);
        if (response.data.status === "1") {
          email = response.data.data;
          dispatch(inviteUsersSuccess(response.data.data));
          dispatch(getUserRollDetails());
          dispatch(SuccessMessage(response.data.message));
        } else {
          dispatch(inviteUsersFail(response.data.message));
        }
      })
      .catch((err) => {
        dispatch(inviteUsersFail(err.message));
      });
  };
};
export const register = (data, history) => {
  return (dispatch) => {
    dispatch(isLoading());
    console.log("sigggg");
    Axios()
      .post("auth/websignup", data)
      .then((response) => {
        if (response.data.status === "1") {
          // Firebase register
          firebase
            .auth()
            .createUserWithEmailAndPassword(data.email, data.password);

          dispatch(
            Success({
              type: actionTypes.SIGNUP,
              payload: response.data.data,
            })
          );
          // history.push('/authenticate/'+data.email+'/'+1234);
          console.log("register");
          history.push("/mail-sent/" + data.email + "/1");
          // history.push('/login');
        } else {
          // console.log('register message : ',response.data.message)
          dispatch(FailReg(response.data.message));
          //dispatch(Fail2(null));
        }
      })
      .catch((err) => {
        dispatch(FailReg(err.message));
        //dispatch(Fail2(null));
      });
  };
};

export const setupProfile = (data, history) => {
   
  return (dispatch) => {
    console.log(data, history, "setup data");
    dispatch(isLoading());
    console.log("setupProfile");
    Axios()
      .post("web/setup-profile", data)
      .then((response) => {
        if (response.data.status === "1") {
          const record = response.data.data;
          console.log("record", record);
          dispatch(
            Success({
              type: actionTypes.SETUPPROFILE,
              payload: record,
            })
          );
          history.push("/mail-sent/" + data.email + "/1");
          dispatch(SuccessMessage(response.data.message));
          console.log("HERE I AM", history);
        } else {
          console.log("register message : ", response.data.message);
          dispatch(setupProfileFail(response.data.message));
          history.push("/login");
          //dispatch(Fail2(null));0
        }
      })
      .catch((err) => {
        console.log("profile error", err);
        dispatch(setupProfileFail(err.message));
        //dispatch(Fail2(null));
      });
  };
};
// export const setupProfilFail = (error) => {
//   return {
//     type: actionTypes.PROFILEFAIL,
//     error: error,
//   };
// };
export const setupProfileFail = (error) => {
  return {
    type: actionTypes.PROFILEFAIL,
    error: error,
  };
};

export const requestOtp = (data, history) => {
  return (dispatch) => {
    dispatch(isLoading());

    Axios()
      .post("users/requestOneTimePassword", data)
      .then((response) => {
        // console.log(response)

        if (response.data.status === "1") {
          dispatch(
            Success({
              type: actionTypes.EMAIL_VERIFY,
              payload: response.data,
            })
          );
          //  
          // history.push('/login');
          console.log("testettttttt");
          history.push("/mail-sent/" + data.email + "/0");
          // history.push('/reset-password/'+data.email);
        } else {
          // console.log(response.data.message)
          dispatch(Fail(response.data.message));
          console.log("response.data.message", response.data.message);
        }
      })
      .catch((err) => {
        dispatch(Fail(err.message));
      });
  };
};

export const reset_password = (data, history) => {
  return (dispatch) => {
    dispatch(isLoading());
    Axios()
      .post("users/reset_password", data)
      .then((response) => {
        // console.log(response)

        if (response.data.status === "1") {
          dispatch(
            Success({
              type: actionTypes.EMAIL_VERIFY,
              payload: response.data,
            })
          );

          history.push("/login");
        } else {
          // console.log(response.data.message)
          dispatch(Fail(response.data.message));
        }
      })
      .catch((err) => {
        dispatch(Fail(err.message));
      });
  };
};
