import { makeStyles, styled, withStyles } from '@material-ui/core/styles';
import { Link, Route, Switch, useHistory, useParams } from 'react-router-dom';
import RymindrOriginal from '../../assets/images/rymindr_original.png';
import alertImg from '../../assets/images/alert_web.png';
import ResponsiveDialog from './Modal/AlertModalDelete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularSpinner from '../../component/CircularSpinner/index';
import {
  Box,
  Button,
  Divider,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  InputAdornment,
  TextField,
  IconButton,
  Hidden,
  MenuItem,
  Badge,
  Menu,
  Dialog,
  DialogContent,
  DialogTitle,
  AppBar,
  Tabs,
  FormControlLabel,
  Tab,
  CardMedia,
} from '@material-ui/core';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import {
  Comment,
  Description,
  Delete,
  Edit,
  Search,
  ArrowBack,
  Chat,
  Send,
  Videocam,
  Headset,
  PersonAdd,
  Print,
  Drafts,
  MoreVert,
  ThumbUp,
  Message,
  Close,
  FiberManualRecord,
  Check,
  Attachment,
  Update,
} from '@material-ui/icons';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import clsx from 'clsx';
import Axios from '../../helper/axios';
import ReactDOM from 'react-dom';
import Pdf from 'react-to-pdf';
import parse from 'html-react-parser';
import io from 'socket.io-client';

import {
  getAlretList,
  getAlertDetailsById,
  deleteAlert,
  getTemplateList,
  getAlertTemplateDetails,
  CreateAlertTemplate,
} from '../../store/actions/messageCenterAction';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import ckEditorConfig from '../../helper/ckEditorConfig';

//  *********** UI Styles Start *****************
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    position: 'relative',
    height: 100 + '%',
    maxHeight: 1000,
    overflow: 'auto',
  },
  rightPanel: {
    [theme.breakpoints.up('md')]: {
      display: 'block !important',
    },
  },
  iconAttach: {
    color: theme.palette.primary.dark,
    background: theme.palette.primary.light,
    height: 32,
    width: 32,
    borderRadius: 16,
    padding: 5,
    float: 'left',
    marginTop: -5,
    marginRight: '10px',
  },
  icon: {
    width: 150,
    marginBottom: 40,
  },
  content: {
    textAlign: 'center',
    textAlign: '-webkit-center',
    paddingTop: '15%',
    height: '80vh',
  },
}));

const PaperStyled = styled(Paper)({
  padding: '10px 15px',
});
const SlideBoxStyled = styled(Box)(() => ({
  textAlign: 'center',
  display: 'inline-block',

  padding: '10px 15px',
  borderRadius: 10,
  '& img': {
    display: 'inline-block',
    height: 75,
    width: 75,
  },
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  borderRadius: 8,
}));

const ButtonColor = styled(Button)(({ theme }) => ({
  background: theme.palette.props.main,
  color: theme.palette.props.contrastText,
}));

const AvatarShareStyled = styled(Avatar)({
  height: 50,
  width: 50,
});

const TypoListSubtext = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.contrastText,
}));

const SearchFieldStyled = styled(TextField)(({ theme }) => ({
  padding: 20,
  paddingLeft: 0,
  paddingRight: 0,
}));

const TypoHeadStyled = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
  fontWeight: 'bold',
}));

const TypoHeadInnerStyled = styled(TypoHeadStyled)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
const GridStyled = styled(Grid)(({ theme }) => ({
  paddingLeft: '10rem',
  paddingRight: '10rem',
  [theme.breakpoints.down('md')]: {
    paddingLeft: '8rem',
    paddingRight: '8rem',
  },

  [theme.breakpoints.down('sm')]: {
    paddingLeft: '2rem',
  },
}));
const BoxStyled = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
});

const ButtonPlain = styled(Button)(({ theme }) => ({
  color: '#98a5af',
  fontSize: 12,
  textTransform: 'capitalize',
  background: 'none',
  boxShadow: 'none',
}));

const ListStyled = styled(List)(({ theme }) => ({
  paddingLeft: 20,
}));

const AvatarStyled = styled(Avatar)({
  height: 'auto',
  width: 25,
  '& img': {
    height: 'auto',
  },
});

const TypoTitleStyled = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.contrastText,
  marginBottom: 10,
}));

const TypoContentStyled = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text.primary,
}));

const TypoStyled = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text.primary,
  marginTop: 10,
  marginBottom: 10,
  marginRight: 10,
  cursor: 'pointer',
}));

const DialogTitleStyled = styled(DialogTitle)({
  borderBottom: '1px solid #e0e0e0',
  padding: '10px 24px',
});

const TypoPopHeadStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontSize: 18,
  fontWeight: '600',
}));

const StyledReadBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const StyledUnreadBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#1773bf',
    color: '#1773bf',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadgeReject = withStyles((theme) => ({
  badge: {
    backgroundColor: '#e50214',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

//  *********** UI Styles Start *****************

const CreateTemplate = (props) => {
  const history = useHistory();
  // const [showParticularALert, setShowParticularALert] = useState([]);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [files, setFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [newDeleteFiles, setNewDeleteFiles] = useState([]);
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();
  const [dublicateAttachement, setDublicateAttachement] = useState([]);

  const FormFields = {
    subject: '',
    message: '',
    title: '',
  };
  const classes = useStyles();

  let { ID } = useParams();

  const [formvalue, setFormvalue] = useState(FormFields);
  const [loading, setLoading] = useState(false);
  const [formValidation, setFormValidation] = useState(FormFields);

  const { user_id, business_code, first_name, last_name, is_subscribe } = JSON.parse(localStorage.getItem('userData'));

  useEffect(() => {
    if (ID) {
      setMenu(true);
      dispatch(getAlertTemplateDetails({ user_id: user_id, template_id: ID }));
    }
  }, [ID]);

  const { templatedetails } = useSelector((state) => state.messageCenterReducer);

  useEffect(() => {
    if (templatedetails && menu) {
      console.log(templatedetails, 'templatedetails');
      setSubject(templatedetails.subject);
      setTitle(templatedetails.title);
      setMessage(templatedetails.message);
      setDublicateAttachement(templatedetails.attachement);
      setFiles(templatedetails.attachement);
    }
  }, [templatedetails]);
  const handleToSubmit = () => {
    // console.log(subject, "sub", title, 'title');
    // if (validate()) return false;
    const sendAttachment = files;

    console.log(formvalue, 'test here', validate);
    if (validate()) return false;
    console.log(formvalue, 'form submit value');
    setLoading(true);
    dispatch(
      CreateAlertTemplate(
        { user_id: user_id, subject: subject, message: message, title: title },
        history,
        sendAttachment
      )
    );
  };

  const validate = () => {
    const formvalidation = { ...formValidation };
    formvalue.subject = subject;
    formvalue.title = title;
    formvalue.message = message;
    let isError = false;
    if (!formvalue.subject) {
      isError = true;
      formvalidation.subject = 'Please input Subject!';
      setFormValidation(formvalidation);
    } else {
      formvalidation.subject = '';
      setFormValidation(formvalidation);
    }
    if (!formvalue.title) {
      isError = true;
      formvalidation.title = 'Please input title!';
      setFormValidation(formvalidation);
    } else {
      formvalidation.title = '';
      setFormValidation(formvalidation);
    }

    if (!formvalue.message) {
      isError = true;
      formvalidation.message = 'Please input Message!';
      setFormValidation(formvalidation);
    } else {
      formvalidation.message = '';
      setFormValidation(formvalidation);
    }

    return isError;
  };

  const deleteImage = (index, file) => {
    if (file && file.image_name) {
      var removeIndex = files
        .map(function (item) {
          return item.name;
        })
        .indexOf(file.image_name);
      files.splice(removeIndex, 1);
      if (file.event_id) {
        let delFiles = [...newDeleteFiles];
        delFiles.push(file);
        setNewDeleteFiles(delFiles);
      }
    }
    if (files.length == 0) {
      setFiles([]);
    } else {
      setFiles([...files]);
      console.log('files===', files);
    }
  };

  const handleSubjectKeyUp = (e) => {
    const formvalidation = { ...formValidation };
    console.log('searchStr=====');
    formvalidation.subject = '';
    setFormValidation(formvalidation);
  };

  const handleTitleKeyUp = (e) => {
    const formvalidation = { ...formValidation };
    console.log('searchStr=====');
    formvalidation.title = '';
    setFormValidation(formvalidation);
  };

  const handleMessageKeyUp = (e) => {
    // const formvalidation = { ...formValidation };
    console.log('Message=====');
    // formvalidation.message = '';
    // setFormValidation(formvalidation);
  };

  const getFiles = (e) => {
    const fileObj = [];
    const fileArray = [];
    // alert('fileee-target'+ e.target.files);
    fileObj.push(e.target.files);

    for (let i = 0; i < fileObj[0].length; i++) {
      if (files.length > 0) {
        getBase64(fileObj[0][i], (result) => {
          const fileInfo = {
            image_name: fileObj[0][i].name,
            type: fileObj[0][i].type,
            size: Math.round(fileObj[0][i].size / 1000) + ' kB',
            base64: fileObj[0][i].type == 'video/mp4' ? result.replace('data:video/mp4;base64,', '') : result,
            file: fileObj[0][i],
          };
          files.push(fileInfo);
          // files.push({'base64': result, 'name': fileObj[0][i].name})
          setFiles([...files]);
        });
      } else {
        getBase64(fileObj[0][i], (result) => {
          // idCardBase64 = result;
          // Make a fileInfo Object
          const fileInfo = {
            image_name: fileObj[0][i].name,
            type: fileObj[0][i].type,
            size: Math.round(fileObj[0][i].size / 1000) + ' kB',
            base64: fileObj[0][i].type == 'video/mp4' ? result.replace('data:video/mp4;base64,', '') : result,
            file: fileObj[0][i],
          };
          fileArray.push(fileInfo);
          setFiles([...fileArray]);
        });
      }
    }
  };

  const getBase64 = (file, cb) => {
    //, cb
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
      // console.log('cccccc', reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  return (
    <>
      <Grid className="main-wrap-head" container style={{ marginBottom: 20 }} alignItems="center">
        <Grid item xs={4}>
          <Box display="flex" alignItems="center">
            <TypoHeadStyled variant="h4">
              <ArrowBackIcon onClick={() => history.push('/alert-template')} />
              Create <TypoHeadInnerStyled component="span">Template</TypoHeadInnerStyled>
            </TypoHeadStyled>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box display="flex" justifyContent="flex-end">
            {/* <Button
              variant="contained"
              color="primary"
              size="large"
              className="mr-10"
              onClick={() => history.push('/alert-template')}
            >
              Template List
            </Button>{' '} */}
            {/* <ButtonColor variant="contained" color="primary" size="large">
              create new template
            </ButtonColor> */}
          </Box>
        </Grid>
      </Grid>
      <PaperStyled className="main-wrap-body create-msg-wrap">
        <GridStyled container className="article-content">
          <Grid lg={12}></Grid>
          <Grid lg={12}>
            <TextField
              label="Template Name"
              fullWidth
              variant="outlined"
              value={title}
              onChange={(e) => {
                setFormvalue({ ...formvalue, title: e.target.value });
                setTitle(e.target.value);
              }}
              onKeyUp={handleTitleKeyUp}
              error={!!formValidation.title}
              helperText={formValidation.title}
              name="title"
            />
          </Grid>
          <Grid lg={12}>
            <TextField
              label="Alert"
              fullWidth
              variant="outlined"
              value={subject}
              // onChange={(e) => setSubject(e.target.value)}
              onChange={(e) => {
                setFormvalue({ ...formvalue, subject: e.target.value });
                setSubject(e.target.value);
              }}
              onKeyUp={handleSubjectKeyUp}
              // onChange={handleSubject}
              error={!!formValidation.subject}
              helperText={formValidation.subject}
              name="subject"
            />
          </Grid>
          <Grid lg={12}>
            <CKEditor
              config={ckEditorConfig}
              editor={Editor}
              data={message}
              name="message"
              label="Instruction"
              onReady={(editor) => {
                console.log('Editor is ready to use!', editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormvalue({ ...formvalue, message: data });
                setMessage(data);

                const formvalidation = { ...formValidation };
                formvalidation.message = '';
                setFormValidation(formvalidation);
              }}
              onBlur={(event, editor) => {
                console.log('Blur.', editor);
              }}
              onFocus={(event, editor) => {
                console.log('Focus.', editor);
              }}
              onBeforeOutputHtml={(evt, data) => {
                // Regular expression to match anchor tags with href attributes
                const anchorRegex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1[^>]*?>/gi;

                // Replace <br> tags within href attributes with an empty string
                data.dataValue = data.dataValue.replace(anchorRegex, (match, p1, p2) => {
                  return match.replace(/<br\s*\/?>/gi, '');
                });
              }}
            // onKeyUp={handleMessageKeyUp}
            />
            <p
              style={{
                color: 'red',
                marginLeft: '1rem',
                fontSize: '12px',
                marginTop: '4px',
              }}
            >
              {formValidation.message}
            </p>
            {/* <TextField
                rows={4}
                rowsMax={100}
                multiline
                label='Message' fullWidth variant='outlined' value={message}
                onClick={handleMessage} onChange={handleMessage}
                error={!!formValidation.message}
                helperText={formValidation.message}
                name='message'
              /> */}
          </Grid>
          <Grid lg={12}>
            <Box display="flex" className="attach-files">
              <FormControlLabel
                className="attachement"
                control={
                  <input
                    type="file"
                    multiple
                    onChange={getFiles}
                    onClick={(e) => (e.target.value = null)}
                    className="displayNone"
                    style={{ display: 'none' }}
                  />
                }
                label={
                  <Typography variant="subtitle1" component="p">
                    <Attachment style={{ marginBottom: 0, marginRight: 5 }} color="primary" /> Attachment
                  </Typography>
                }
              />
            </Box>
            <Box alignItems="center" display="flex">
              {files &&
                files.map((file, index) => {
                  if (file.attachment_object != undefined) {
                    const extension_arr = file.attachment_object.split('.');
                    const extension = extension_arr[1];

                    if (extension == 'jpeg' || extension == 'jpg' || extension == 'png' || extension == 'gif') {
                      return (
                        <TypoStyled variant="subtitle1" component="p" key={index}>
                          <img src={file.file_name} width="32" height="32" />{' '}
                          <Box className={classes.fileName}>{file.attachment_object}</Box>{' '}
                          <CancelIcon onClick={() => deleteImage(index, file)} />
                        </TypoStyled>
                      );
                    } else if (extension == 'mp3') {
                      return (
                        <TypoStyled variant="subtitle1" component="p" key={index}>
                          <Headset className={classes.iconAttach} /> {file.attachment_object}{' '}
                          <CancelIcon onClick={() => deleteImage(index, file)} />
                        </TypoStyled>
                      );
                    } else if (extension == 'mp4') {
                      return (
                        <TypoStyled variant="subtitle1" component="p" key={index}>
                          <Videocam className={classes.iconAttach} /> {file.attachment_object}{' '}
                          <CancelIcon onClick={() => deleteImage(index, file)} />
                        </TypoStyled>
                      );
                    } else if (extension == 'doc' || extension == 'docx' || extension == 'pdf') {
                      return (
                        <TypoStyled variant="subtitle1" component="p" key={index}>
                          <Description className={classes.iconAttach} /> {file.attachment_object}{' '}
                          <CancelIcon onClick={() => deleteImage(index, file)} />
                        </TypoStyled>
                      );
                    } else {
                      return (
                        <TypoStyled variant="subtitle1" component="p" key={index}>
                          <Description className={classes.iconAttach} /> {file.attachment_object}{' '}
                          <CancelIcon onClick={() => deleteImage(index, file)} />
                        </TypoStyled>
                      );
                    }
                  } else {
                    const extension_arr = file.image_name.split('.');
                    const extension = extension_arr[1];
                    if (extension == 'jpeg' || extension == 'jpg' || extension == 'png' || extension == 'gif') {
                      return (
                        // <TypoStyled variant='subtitle1' component='p' key={index}>
                        //   <img src={file.base64} width='32' height='32' className={classes.iconAttach} /> <Box className={classes.fileName}>{file.image_name}</Box> <CancelIcon onClick={() => deleteImage(index, file)} />
                        // </TypoStyled>

                        <TypoStyled variant="subtitle1" component="p" key={index}>
                          <img src={file.base64} className={classes.iconAttach} /> {file.image_name}
                          <CancelIcon onClick={() => deleteImage(index, file)} className={classes.verticalClass} />
                        </TypoStyled>
                      );
                    } else if (extension == 'mp3') {
                      return (
                        <TypoStyled variant="subtitle1" component="p" key={index}>
                          <Headset className={classes.iconAttach} /> {file.image_name}{' '}
                          <CancelIcon onClick={() => deleteImage(index, file)} />
                        </TypoStyled>
                      );
                    } else if (extension == 'mp4') {
                      return (
                        <TypoStyled variant="subtitle1" component="p" key={index}>
                          <Videocam className={classes.iconAttach} /> {file.image_name}{' '}
                          <CancelIcon onClick={() => deleteImage(index, file)} />
                        </TypoStyled>
                      );
                    } else if (extension == 'doc' || extension == 'docx' || extension == 'pdf') {
                      return (
                        <TypoStyled variant="subtitle1" component="p" key={index}>
                          <Description className={classes.iconAttach} /> {file.image_name}{' '}
                          <CancelIcon onClick={() => deleteImage(index, file)} />
                        </TypoStyled>
                      );
                    } else {
                      return (
                        <TypoStyled variant="subtitle1" component="p" key={index}>
                          <Description className={classes.iconAttach} /> {file.image_name}{' '}
                          <CancelIcon onClick={() => deleteImage(index, file)} />
                        </TypoStyled>
                      );
                    }
                  }
                })}
            </Box>
          </Grid>
          <Grid lg={12}></Grid>
          <Grid lg={12}></Grid>
          <Grid lg={12}>
            <Box display="flex" justifyContent="flex-end">
              {/* <ButtonColor variant='contained' color='primary' size='large' className='mr-30 width180' startIcon={<Visibility />}>
                  Preview
          </ButtonColor> */}
              <Button
                variant="contained"
                size="large"
                // className="width180"
                // startIcon={<Cancel />}
                onClick={() => history.goBack()}
                style={{ marginRight: 20 }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                // className="width180"
                startIcon={<Send />}
                onClick={handleToSubmit}
                disabled={loading}
              >
                {loading && <CircularSpinner />}
                Save Template
              </Button>
            </Box>
          </Grid>
        </GridStyled>
      </PaperStyled>
    </>
  );
};

export default connect()(withSnackbar(CreateTemplate));
