import {
  ArrowBack,
  Comment,
  Delete,
  Description,
  Edit,
  Headset,
  Image,
  Message,
  MoreVert,
  Search,
  ThumbUp,
  Videocam,
  Close,
  FiberManualRecord,
  Update,
  Check,
} from "@material-ui/icons";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  List,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Dialog,
  ThemeProvider,
  DialogContent,
  DialogTitle,
  CardMedia,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import CircularSpinner from "../../../component/CircularSpinner/index";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  delete_rymindr2,
  getRymidrDetails,
  getUpcommingRymindrs,
  generateQrCodeDB,
  statusData,
} from "../../../store/actions/rymidr";
import Axios from "../../../helper/axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import FullscreenSpinner from "../../../component/FullscreenSpinner";
import Comments from "../../../container/Comments/Comments";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useHistory, useParams, useLocation } from "react-router-dom";
import { withSnackbar } from "notistack";

import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  MailruIcon,
  EmailIcon,
  LivejournalIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  WeiboIcon,
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
import Share from "@material-ui/icons/Share";
import GetApp from "@material-ui/icons/GetApp";
import RymindrOriginal from "../../../assets/images/rymindr_original.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: "0px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    position: "relative",
    overflow: "auto",
    // height: 130 + "%",
  }, // => {maxHeight: 'calc(100vh - 80px)', minHeight: 100 + 'vh', }
  rightPanel: {
    [theme.breakpoints.up("md")]: {
      display: "block !important",
    },
  },

  iconAttach: {
    color: theme.palette.primary.dark,
    background: theme.palette.primary.light,
    height: 32,
    width: 32,
    borderRadius: 60,
    padding: 5,
    float: "left",
    marginTop: -5,
    marginRight: "10px",
  },
  icon: {
    width: 150,
    marginBottom: 40,
  },
  content: {
    textAlign: "center",
    textAlign: "-webkit-center",
    // paddingTop: "0%",
    padding: "0 5%",
    // paddingLeft: "10%",
    // height: "100vh",
  },
  wrapText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  overFlowHide: {
    overflow: "inherit",
  },
}));
const styles = {
  input: {
    backgroundColor: "red",
  },
};
const AvatarStyled = styled(Avatar)({
  height: 25,
  width: 25,
  "& img": {
    height: "auto",
  },
});
const DialogTitleStyled = styled(DialogTitle)({
  borderBottom: "1px solid #e0e0e0",
  padding: "10px 24px",
});
const TypoStatusStyled = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.contrastText,
  width: 60,
  textAlign: "right",
  fontWeight: "600",
}));
const TypoStatusStyledAccept = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: "#44b700",
  width: 60,
  textAlign: "right",
  fontWeight: "600",
}));
const TypoStatusStyledReject = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: "#FF0000",
  width: 60,
  textAlign: "right",
  fontWeight: "600",
}));
const TypoTitleStyled = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.contrastText,
}));
const TypoPopHeadStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontSize: 18,
  fontWeight: "600",
}));
const TypoContentStyled = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  color: theme.palette.text.primary,
}));

const AvatarShareStyled = styled(Avatar)({
  height: 50,
  width: 50,
});

const BoxStyled = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  "&>span": { marginRight: 10 },
});

const TypoParaStyled = styled(Typography)(({ theme }) => ({
  fontSize: 18,
}));
const TypoHeadStyled = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.primary.main,
  textTransform: "uppercase",
  fontWeight: "bold",
}));

const TypoSecHeadStyled = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.primary.main,
  // textTransform: "uppercase",
  // fontWeight: "bold",
}));
const TypoThirdHeadStyled = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.primary.main,
  // textTransform: "uppercase",
  fontWeight: "bold",
}));

const TypoFreeStyled = styled(Typography)(({ theme }) => ({
  fontSize: 45,
  color: "#31d631",
}));

const TypoSizeStyled = styled(Typography)(({ theme }) => ({
  paddingTop: 30,
}));

const TypoForeverStyled = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  color: "#212529",
  fontWeight: "bold",
}));

const TypoFreeForeverStyled = styled(Typography)(({ theme }) => ({
  fontSize: 30,
  color: "#31d631",
  fontWeight: "bold",
}));

const TypoEuroStyled = styled(Typography)(({ theme }) => ({
  fontSize: 40,
  color: "rgb(3 129 254)",
}));
const TypoUnlimitedStyled = styled(Typography)(({ theme }) => ({
  fontSize: 30,
  color: "rgb(3 129 254)",
  fontWeight: "bold",
}));
const TypoMainStyled = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: "rgb(41 119 152)",
  // textTransform: "uppercase",
  fontWeight: "bold",
}));

const TypoListStyled = styled(Typography)(({ theme }) => ({
  backgroundColor: "rgb(234 245 237 / 48%)",
}));
const TypoSideStyled = styled(Typography)(({ theme }) => ({
  backgroundColor: "rgb(220 234 243 / 41%)",
}));

const TypoHeadInnerStyled = styled(TypoHeadStyled)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const TypoHeadTopStyled = styled(TypoHeadStyled)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const TypoListSubtext = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.contrastText,
}));

const TypoListSubtext2 = styled(TypoListSubtext)(({ theme }) => ({
  color: "#696969",
  display: "-webkit-box",
  "-webkit-line-clamp": 2,
}));

const SearchFieldStyled = styled(TextField)(({ theme }) => ({
  paddingTop: 10,
  paddingBottom: 10,
}));

const ListStyled = styled(List)(({ theme }) => ({
  paddingLeft: 20,
}));

const GridStyled = styled(Grid)({
  padding: "15px 15px 15px 30px",
});

const AvatarComntStyled = styled(Avatar)({
  height: 42,
  width: 42,
  marginRight: 20,
});

const TextFieldStyled = styled(TextField)({
  width: 100 + "%",
  height: 100,
});

const TypoCmntName = styled(Typography)({
  color: "#757575",
  fontWeight: "bold",
  fontSize: 12,
  "& span": {
    fontWeight: "normal",
    marginLeft: 20,
  },
});
const TypoCmntTxt = styled(Typography)({
  color: "#3d3d3d",
  fontSize: 14,
});

const ButtonStyled = styled(Button)({
  color: "#757575",
  fontSize: 12,
});

const BoxColorStyled = styled(Box)({
  background: "#f0f6fb",
  fontSize: 12,
  padding: "10px 15px",
  borderRadius: 15,
  minWidth: 160,
  justifyContent: "space-between",
});

const BoxImgStyled = styled(Box)({
  textAlign: "center",
  marginRight: 20,
  "& div": {
    margin: "auto",
  },
});
const TypoStyled = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  color: theme.palette.text.primary,
  marginTop: 10,
  marginBottom: 10,
  marginRight: 10,
  cursor: "pointer",
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadgeReject = withStyles((theme) => ({
  badge: {
    backgroundColor: "#e50214",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadgePending = withStyles((theme) => ({
  badge: {
    backgroundColor: "#1773bf",
    color: "#1773bf",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    padding: 0,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const ButtonPlain = styled(Button)(({ theme }) => ({
  color: "#98a5af",
  fontSize: 12,
  textTransform: "capitalize",
  background: "none",
  boxShadow: "none",
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  borderRadius: 8,
  maxWidth: 600,
}));

const DialogContentStyled = styled(DialogContent)({
  textAlign: "center",
});

const PaperStyled = styled(Paper)(({ theme }) => ({
  padding: 30,
  height: 105 + "%",
}));

const TypoTxtStyled = styled(Typography)(({ theme }) => ({
  textTransform: "uppercase",
  color: theme.palette.text.primary,
  fontSize: 16,
}));

const TypoBusStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 18,
  fontWeight: "800",
}));

const options = [];

const UpgradeRymindr = (props) => {
  const history = useHistory();
  const {
    enqueueSnackbar,
    error,
    getUpcommingRymindrs,
    upcommingrymindr,
    loading,
    getRymidrDetails,
    rymindrDetails2,
    delete_rymindr2,
    success_message,
    generateQrCodeDB,
    qrImg,
    statusData,
    statusList,
  } = props;
  const {
    user_id,
    business_code,
    first_name,
    last_name,
    is_subscribe,
  } = JSON.parse(localStorage.getItem("userData"));
  const [showPanel, setShowPanel] = useState(true);
  const [isSubscribed, setIsSubscribe] = useState();

  const local = JSON.parse(localStorage.getItem("userData"));
  const subscribe = local.is_subscribe;
  console.log("details=>>>>>>>>>>>>>>>>>>>>", local.is_subscribe, local);

  const classes = useStyles();

  const regex = "/<(.|\n)*?>/g";

  // Query string params
  const { param, paramType } = useParams();
  const { account } = useSelector((state) => state);
  const subscribed = account?.userdetails?.is_subscribe;
  console.log("isSubscribed", isSubscribed, subscribed)
  const handelCheckout = () => {
    const { user_id } = JSON.parse(localStorage.getItem("userData"));

    const data = Axios()
      .post("https://api.rymindr.com/api/web/create-checkout-session", {
        user_id: user_id,
      })
      .then((res) => {
        if (res.data.url) {
          window.location.href = res.data.url;
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const [width, setWidth] = React.useState(0);
  const [width1, setWidth1] = React.useState(0);
  const measuredRef = React.useCallback((node) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);
  const measuredRef1 = React.useCallback((node) => {
    if (node !== null) {
      setWidth1(node.getBoundingClientRect().width);
    }
  }, []);
  useEffect(() => {
    setIsSubscribe(subscribed);
  }, [account, account?.userdetails]);

  return (
    <>
      <Grid
        noWrap
        className="main-wrap-head"
        container
        style={{ marginBottom: 20 }}
        alignItems="center"
      >
        <Grid item xs={4}>
          <Box display="flex" alignItems="center">
            <TypoHeadStyled>
              Upgrade{" "}
              <TypoHeadInnerStyled component="span">
                Rymindr
              </TypoHeadInnerStyled>
            </TypoHeadStyled>
          </Box>
        </Grid>
      </Grid>
      <Grid
        className="main-wrap-body upcmgRym-cont-wrap"
        container
        alignItems="stretch"
      >
        <Grid
          ref={measuredRef}
          item
          xs={12}
          md={12}
          className={classes.rightPanel}
        >
          <PaperStyled>
            <Box className={classes.content}>
              {is_subscribe != 1 ? (
                <Grid
                  noWrap
                  className="main-wrap-head "
                  container
                  style={{
                    marginBottom: 55,
                    padding: "0 5%",
                    justifyContent: "center",
                  }}
                  alignItems="center"
                >
                  <Grid item xs={2} style={{ margin: "0 5%" }}>
                    <img
                      src={require("../../../assets/images/plan.svg")}
                      style={{ height: 50, width: 50 }}
                    />
                    <TypoThirdHeadStyled>
                      <TypoMainStyled component="span">
                        View Plan
                      </TypoMainStyled>
                    </TypoThirdHeadStyled>
                  </Grid>
                  <img
                    src={require("../../../assets/images/next_step.svg")}
                    style={{ height: 50, width: 50 }}
                  />
                  <Grid item xs={2} style={{ margin: "0 5%" }}>
                    <img
                      src={require("../../../assets/images/payment_disable.svg")}
                      style={{ height: 50, width: 50 }}
                    />
                    <TypoSecHeadStyled>
                      <TypoHeadInnerStyled component="span">
                        Payment
                      </TypoHeadInnerStyled>
                    </TypoSecHeadStyled>
                  </Grid>
                  <img
                    src={require("../../../assets/images/next_step.svg")}
                    style={{ height: 50, width: 50 }}
                  />
                  <Grid item xs={2} style={{ margin: "0 5%" }}>
                    <img
                      src={require("../../../assets/images/done_disable.svg")}
                      style={{ height: 50, width: 50 }}
                    />
                    <TypoSecHeadStyled>
                      <TypoHeadInnerStyled component="span">
                        Done
                      </TypoHeadInnerStyled>
                    </TypoSecHeadStyled>
                  </Grid>
                </Grid>
              ) : null}
              <Grid
                className="main-wrap-body upcmgRym-cont-wrap"
                container
                style={{ justifyContent: "center" }}
                alignItems="stretch"
              >
                {is_subscribe == 1 ? (
                  <Grid
                    item
                    xs={12}
                    md={5}
                    className="shadow"
                    style={{
                      maxWidth: 650,
                      margin: "0px 5% 0px 2%",
                      borderRadius: "16px",
                    }}
                  >
                    <Paper className={classes.paper}>
                      <TypoSideStyled>
                        <TypoSizeStyled style={{ paddingTop: "30px" }}>
                          <Grid noWrap className="main-wrap-head" container>
                            {/* <Grid item xs={7}>
                              <Grid item xs={8}>
                                <TypoHeadStyled>
                                  <TypoEuroStyled component="span">
                                    <img
                                      src={require("../../../assets/images/icons8-british-pound-blue.svg")}
                                      // style={{ height: 30, width: 30 }}
                                    />
                                    29.99
                                  </TypoEuroStyled>
                                </TypoHeadStyled>
                              </Grid>
                              <Grid item xs={12}>
                                <TypoForeverStyled component="span">
                                  Per month (Paid annually)
                                </TypoForeverStyled>
                              </Grid>
                            </Grid> */}
                            <Grid item xs={2}>
                              <TypoUnlimitedStyled component="span" style={{ paddingLeft: "60px" }}>
                                UNLIMITED
                              </TypoUnlimitedStyled>
                            </Grid>
                          </Grid>
                        </TypoSizeStyled>
                        <List
                          sx={{
                            width: "100%",
                            maxWidth: 360,
                            bgcolor: "background.paper",
                          }}
                        >
                          <ListItem>
                            {" "}
                            <img
                              src={require("../../../assets/images/done.png")}
                              style={{ height: 30, width: 30 }}
                            />
                            <TypoParaStyled style={{ paddingLeft: "20px" }}>
                              Unlimited{" "}
                              <TypoHeadInnerStyled component="span">
                                storage
                              </TypoHeadInnerStyled>
                            </TypoParaStyled>
                          </ListItem>
                          <ListItem>
                            {" "}
                            <img
                              src={require("../../../assets/images/done.png")}
                              style={{ height: 30, width: 30 }}
                            />
                            <TypoParaStyled style={{ paddingLeft: "20px" }}>
                              Unlimited{" "}
                              <TypoHeadInnerStyled component="span">
                                Share Term dates and holydays
                              </TypoHeadInnerStyled>
                            </TypoParaStyled>
                          </ListItem>
                          <ListItem>
                            {" "}
                            <img
                              src={require("../../../assets/images/done.png")}
                              style={{ height: 30, width: 30 }}
                            />
                            <TypoParaStyled style={{ paddingLeft: "20px" }}>
                              Unlimited{" "}
                              <TypoHeadInnerStyled component="span">
                                Plan & Manage Parent's evening & appointments.
                              </TypoHeadInnerStyled>
                            </TypoParaStyled>
                          </ListItem>
                          <ListItem>
                            {" "}
                            <img
                              src={require("../../../assets/images/done.png")}
                              style={{ height: 30, width: 30 }}
                            />
                            <TypoParaStyled style={{ paddingLeft: "20px" }}>
                              Unlimited{" "}
                              <TypoHeadInnerStyled component="span">
                                Accept payments online with no platform fees.
                              </TypoHeadInnerStyled>
                            </TypoParaStyled>
                          </ListItem>
                          <ListItem>
                            {" "}
                            <img
                              src={require("../../../assets/images/done.png")}
                              style={{ height: 30, width: 30 }}
                            />
                            <TypoParaStyled style={{ paddingLeft: "20px" }}>
                              Unlimited{" "}
                              <TypoHeadInnerStyled component="span">
                                Real-time live chat
                              </TypoHeadInnerStyled>
                            </TypoParaStyled>
                          </ListItem>
                          <ListItem>
                            {" "}
                            <img
                              src={require("../../../assets/images/done.png")}
                              style={{ height: 30, width: 30 }}
                            />
                            <TypoParaStyled style={{ paddingLeft: "20px" }}>
                              Unlimited{" "}
                              <TypoHeadInnerStyled component="span">
                                Rymindrs
                              </TypoHeadInnerStyled>
                            </TypoParaStyled>
                          </ListItem>
                          <ListItem>
                            {" "}
                            <img
                              src={require("../../../assets/images/done.png")}
                              style={{ height: 30, width: 30 }}
                            />
                            <TypoParaStyled style={{ paddingLeft: "20px" }}>
                              Unlimited{" "}
                              <TypoHeadInnerStyled component="span">
                                Share newsletters, messages, videos, photos &
                                more
                              </TypoHeadInnerStyled>
                            </TypoParaStyled>
                          </ListItem>
                          <ListItem>
                            {" "}
                            <img
                              src={require("../../../assets/images/done.png")}
                              style={{ height: 30, width: 30 }}
                            />
                            <TypoParaStyled style={{ paddingLeft: "20px" }}>
                              Unlimited{" "}
                              <TypoHeadInnerStyled component="span">
                                Contacts
                              </TypoHeadInnerStyled>
                            </TypoParaStyled>
                          </ListItem>
                          <ListItem>
                            {" "}
                            <img
                              src={require("../../../assets/images/done.png")}
                              style={{ height: 30, width: 30 }}
                            />
                            <TypoParaStyled style={{ paddingLeft: "20px" }}>
                              Unlimited{" "}
                              <TypoHeadInnerStyled component="span">
                                User accounts
                              </TypoHeadInnerStyled>
                            </TypoParaStyled>
                          </ListItem>
                          <ListItem>
                            {" "}
                            <img
                              src={require("../../../assets/images/done.png")}
                              style={{ height: 30, width: 30 }}
                            />
                            <TypoParaStyled style={{ paddingLeft: "20px" }}>
                              24/7 support
                            </TypoParaStyled>
                          </ListItem>
                          <Grid item xs={15} style={{ paddingBottom: "10px" }}>
                            <Box
                              display="flex"
                              justifyContent="flex-end"
                              style={{ maxWidth: "65%", marginTop: "55px" }}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                size="large"
                              // onClick={() => history.push("/create-rymindr")}
                              // onClick={() => handelCheckout()}
                              >
                                YOUR CURRENT PLAN
                              </Button>
                            </Box>
                          </Grid>
                        </List>
                      </TypoSideStyled>
                    </Paper>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={5}
                      className="shadow"
                      style={{
                        maxWidth: 650,
                        margin: "0px 2% 0px 5%",
                        borderRadius: "16px",
                        marginTop: "10px"
                      }}
                    >
                      <Paper className={classes.paper}>
                        <TypoListStyled>
                          <TypoSizeStyled style={{ paddingTop: "30px" }}>
                            <Grid noWrap className="main-wrap-head" container>
                              <Grid item xs={6}>
                                <Grid item xs={8}>
                                  <TypoHeadStyled>
                                    <TypoFreeStyled component="span">
                                      FREE
                                    </TypoFreeStyled>
                                  </TypoHeadStyled>
                                </Grid>
                                <Grid item xs={6}>
                                  <TypoForeverStyled component="span">
                                    Forever
                                  </TypoForeverStyled>
                                </Grid>
                              </Grid>
                              <Grid item xs={6}>
                                <TypoFreeForeverStyled component="span">
                                  FREE FOREVER
                                </TypoFreeForeverStyled>
                              </Grid>
                            </Grid>
                          </TypoSizeStyled>
                          <List
                            sx={{
                              width: "100%",
                              maxWidth: 360,
                              bgcolor: "background.paper",
                            }}
                          >
                            <ListItem>
                              <img
                                src={require("../../../assets/images/feature_green.svg")}
                                style={{ height: 30, width: 30 }}
                              />
                              <TypoParaStyled style={{ paddingLeft: "20px" }}>
                                <TypoHeadInnerStyled component="span">
                                  Share Term dates and holydays
                                </TypoHeadInnerStyled>
                              </TypoParaStyled>
                            </ListItem>
                            <ListItem>
                              <img
                                src={require("../../../assets/images/feature_green.svg")}
                                style={{ height: 30, width: 30 }}
                              />
                              <TypoParaStyled style={{ paddingLeft: "20px" }}>
                                <TypoHeadInnerStyled component="span">
                                  Plan & Manage Parent's evening & appointments
                                </TypoHeadInnerStyled>
                              </TypoParaStyled>
                            </ListItem>
                            <ListItem>
                              <img
                                src={require("../../../assets/images/feature_green.svg")}
                                style={{ height: 30, width: 30 }}
                              />
                              <TypoParaStyled style={{ paddingLeft: "20px" }}>
                                <TypoHeadInnerStyled component="span">
                                  Real-time live chat
                                </TypoHeadInnerStyled>
                              </TypoParaStyled>
                            </ListItem>
                            <ListItem>
                              <img
                                src={require("../../../assets/images/feature_green.svg")}
                                style={{ height: 30, width: 30 }}
                              />
                              <TypoParaStyled style={{ paddingLeft: "20px" }}>
                                <TypoHeadInnerStyled component="span">
                                  Share Rymindrs
                                </TypoHeadInnerStyled>
                              </TypoParaStyled>
                            </ListItem>
                            <ListItem>
                              <img
                                src={require("../../../assets/images/feature_green.svg")}
                                style={{ height: 30, width: 30 }}
                              />
                              <TypoParaStyled style={{ paddingLeft: "20px" }}>
                                <TypoHeadInnerStyled component="span">
                                  Share Newsslatters, messages videos,
                                  photos&more
                                </TypoHeadInnerStyled>
                              </TypoParaStyled>
                            </ListItem>
                            <ListItem>
                              <img
                                src={require("../../../assets/images/feature_green.svg")}
                                style={{ height: 30, width: 30 }}
                              />
                              <TypoParaStyled style={{ paddingLeft: "20px" }}>
                                <TypoHeadInnerStyled component="span">
                                  1000 Contacts
                                </TypoHeadInnerStyled>
                              </TypoParaStyled>
                              <TypoHeadInnerStyled component="span"></TypoHeadInnerStyled>
                            </ListItem>
                            <ListItem>
                              <img
                                src={require("../../../assets/images/feature_green.svg")}
                                style={{ height: 30, width: 30 }}
                              />
                              <TypoParaStyled style={{ paddingLeft: "20px" }}>
                                <TypoHeadInnerStyled component="span">
                                  10 User Accounts
                                </TypoHeadInnerStyled>
                              </TypoParaStyled>
                              <TypoHeadInnerStyled component="span"></TypoHeadInnerStyled>
                            </ListItem>
                            <ListItem>
                              <img
                                src={require("../../../assets/images/feature_green.svg")}
                                style={{ height: 30, width: 30 }}
                              />
                              <TypoParaStyled style={{ paddingLeft: "20px" }}>
                                <TypoHeadInnerStyled component="span">
                                  24/7 support
                                </TypoHeadInnerStyled>
                              </TypoParaStyled>
                            </ListItem>
                            <ListItem>
                              <img
                                src={require("../../../assets/images/feature_green.svg")}
                                style={{ height: 30, width: 30 }}
                              />
                              <TypoParaStyled style={{ paddingLeft: "20px" }}>
                                <TypoHeadInnerStyled component="span">
                                  Manage school clubs (coming soon)
                                </TypoHeadInnerStyled>
                              </TypoParaStyled>
                            </ListItem>
                            <Grid item xs={18}>
                              <Box
                                display="flex"
                                justifyContent="center"
                                style={{ maxWidth: "100%", marginTop: "115px" }}
                              >
                                <TypoFreeForeverStyled component="span">
                                  YOUR CURRENT PLAN
                                </TypoFreeForeverStyled>
                              </Box>
                            </Grid>
                          </List>
                        </TypoListStyled>
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={5}
                      className="shadow"
                      style={{
                        maxWidth: 650,
                        margin: "0px 2% 0px 5%",
                        borderRadius: "16px",
                        marginTop: "10px"
                      }}
                    >
                      <Paper className={classes.paper}>
                        <TypoSideStyled>
                          <TypoSizeStyled style={{ paddingTop: "30px" }}>
                            <Grid noWrap className="main-wrap-head" container>
                              <Grid item xs={6}>
                                <Grid item xs={8}>
                                  <TypoHeadStyled>
                                    <TypoEuroStyled component="span">
                                      <img
                                        src={require("../../../assets/images/icons8-british-pound-blue.svg")}
                                      // style={{ height: 30, width: 30 }}
                                      />
                                      29.99
                                    </TypoEuroStyled>
                                  </TypoHeadStyled>
                                </Grid>
                                <Grid item xs={12}>
                                  <TypoForeverStyled component="span">
                                    Per month (Paid annually)
                                  </TypoForeverStyled>
                                </Grid>
                              </Grid>
                              <Grid item xs={6}>
                                <TypoUnlimitedStyled component="span">
                                  UNLIMITED
                                </TypoUnlimitedStyled>
                              </Grid>
                            </Grid>
                          </TypoSizeStyled>
                          <List
                            sx={{
                              width: "100%",
                              maxWidth: 360,
                              bgcolor: "background.paper",
                            }}
                          >
                            <ListItem>
                              {" "}
                              <img
                                src={require("../../../assets/images/done.png")}
                                style={{ height: 30, width: 30 }}
                              />
                              <TypoParaStyled style={{ paddingLeft: "20px" }}>
                                Unlimited{" "}
                                <TypoHeadInnerStyled component="span">
                                  storage
                                </TypoHeadInnerStyled>
                              </TypoParaStyled>
                            </ListItem>
                            <ListItem>
                              {" "}
                              <img
                                src={require("../../../assets/images/done.png")}
                                style={{ height: 30, width: 30 }}
                              />
                              <TypoParaStyled style={{ paddingLeft: "20px" }}>
                                Unlimited{" "}
                                <TypoHeadInnerStyled component="span">
                                  Share Term dates and holydays
                                </TypoHeadInnerStyled>
                              </TypoParaStyled>
                            </ListItem>
                            <ListItem>
                              {" "}
                              <img
                                src={require("../../../assets/images/done.png")}
                                style={{ height: 30, width: 30 }}
                              />
                              <TypoParaStyled style={{ paddingLeft: "20px" }}>
                                Unlimited{" "}
                                <TypoHeadInnerStyled component="span">
                                  Plan & Manage Parent's evening & appointments.
                                </TypoHeadInnerStyled>
                              </TypoParaStyled>
                            </ListItem>
                            <ListItem>
                              {" "}
                              <img
                                src={require("../../../assets/images/done.png")}
                                style={{ height: 30, width: 30 }}
                              />
                              <TypoParaStyled style={{ paddingLeft: "20px" }}>
                                Unlimited{" "}
                                <TypoHeadInnerStyled component="span">
                                  Accept payments online with no platform fees.
                                </TypoHeadInnerStyled>
                              </TypoParaStyled>
                            </ListItem>
                            <ListItem>
                              {" "}
                              <img
                                src={require("../../../assets/images/done.png")}
                                style={{ height: 30, width: 30 }}
                              />
                              <TypoParaStyled style={{ paddingLeft: "20px" }}>
                                Unlimited{" "}
                                <TypoHeadInnerStyled component="span">
                                  Real-time live chat
                                </TypoHeadInnerStyled>
                              </TypoParaStyled>
                            </ListItem>
                            <ListItem>
                              {" "}
                              <img
                                src={require("../../../assets/images/done.png")}
                                style={{ height: 30, width: 30 }}
                              />
                              <TypoParaStyled style={{ paddingLeft: "20px" }}>
                                Unlimited{" "}
                                <TypoHeadInnerStyled component="span">
                                  Rymindrs
                                </TypoHeadInnerStyled>
                              </TypoParaStyled>
                            </ListItem>
                            <ListItem>
                              {" "}
                              <img
                                src={require("../../../assets/images/done.png")}
                                style={{ height: 30, width: 30 }}
                              />
                              <TypoParaStyled style={{ paddingLeft: "20px" }}>
                                Unlimited{" "}
                                <TypoHeadInnerStyled component="span">
                                  Share newsletters, messages, videos, photos &
                                  more
                                </TypoHeadInnerStyled>
                              </TypoParaStyled>
                            </ListItem>
                            <ListItem>
                              {" "}
                              <img
                                src={require("../../../assets/images/done.png")}
                                style={{ height: 30, width: 30 }}
                              />
                              <TypoParaStyled style={{ paddingLeft: "20px" }}>
                                Unlimited{" "}
                                <TypoHeadInnerStyled component="span">
                                  Contacts
                                </TypoHeadInnerStyled>
                              </TypoParaStyled>
                            </ListItem>
                            <ListItem>
                              {" "}
                              <img
                                src={require("../../../assets/images/done.png")}
                                style={{ height: 30, width: 30 }}
                              />
                              <TypoParaStyled style={{ paddingLeft: "20px" }}>
                                Unlimited{" "}
                                <TypoHeadInnerStyled component="span">
                                  User accounts
                                </TypoHeadInnerStyled>
                              </TypoParaStyled>
                            </ListItem>
                            <ListItem>
                              {" "}
                              <img
                                src={require("../../../assets/images/done.png")}
                                style={{ height: 30, width: 30 }}
                              />
                              <TypoParaStyled style={{ paddingLeft: "20px" }}>
                                24/7 support
                              </TypoParaStyled>
                            </ListItem>
                            <Grid
                              item
                              xs={15}
                              style={{ paddingBottom: "10px" }}
                            >
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                style={{ maxWidth: "65%", marginTop: "55px" }}
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  // onClick={() => history.push("/create-rymindr")}
                                  onClick={() => handelCheckout()}
                                >
                                  UPGRADE NOW
                                </Button>
                              </Box>
                            </Grid>
                          </List>
                        </TypoSideStyled>
                      </Paper>
                    </Grid>
                  </>
                )}
                {/* <Grid
                  item
                  xs={12}
                  md={5}
                  className="shadow"
                  style={{ maxWidth: 650, margin: "0px 2% 0px 5%", borderRadius: "16px" }}
                >
                  <Paper className={classes.paper}>
                    <TypoListStyled>
                      <TypoSizeStyled style={{ paddingTop: "30px" }}>
                        <Grid noWrap className="main-wrap-head" container>
                          <Grid item xs={6}>
                            <Grid item xs={8}>
                              <TypoHeadStyled>
                                <TypoFreeStyled component="span">
                                  FREE
                                </TypoFreeStyled>
                              </TypoHeadStyled>
                            </Grid>
                            <Grid item xs={6}>
                              <TypoForeverStyled component="span">
                                Forever
                              </TypoForeverStyled>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <TypoFreeForeverStyled component="span">
                              FREE FOREVER
                            </TypoFreeForeverStyled>
                          </Grid>
                        </Grid>
                      </TypoSizeStyled>
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                      >
                        <ListItem>
                          <img
                            src={require("../../../assets/images/feature_green.svg")}
                            style={{ height: 30, width: 30 }}
                          />
                          <TypoParaStyled style={{ paddingLeft: "20px" }}>
                            <TypoHeadInnerStyled component="span">
                              Share Term dates and holydays
                            </TypoHeadInnerStyled>
                          </TypoParaStyled>
                        </ListItem>
                        <ListItem>
                          <img
                            src={require("../../../assets/images/feature_green.svg")}
                            style={{ height: 30, width: 30 }}
                          />
                          <TypoParaStyled style={{ paddingLeft: "20px" }}>
                            <TypoHeadInnerStyled component="span">
                              Plan & Manage Parent's evening & appointments
                            </TypoHeadInnerStyled>
                          </TypoParaStyled>
                        </ListItem>
                        <ListItem>
                          <img
                            src={require("../../../assets/images/feature_green.svg")}
                            style={{ height: 30, width: 30 }}
                          />
                          <TypoParaStyled style={{ paddingLeft: "20px" }}>
                            <TypoHeadInnerStyled component="span">
                              Real-time live chat
                            </TypoHeadInnerStyled>
                          </TypoParaStyled>
                        </ListItem>
                        <ListItem>
                          <img
                            src={require("../../../assets/images/feature_green.svg")}
                            style={{ height: 30, width: 30 }}
                          />
                          <TypoParaStyled style={{ paddingLeft: "20px" }}>
                            <TypoHeadInnerStyled component="span">
                              Share Rymindrs
                            </TypoHeadInnerStyled>
                          </TypoParaStyled>
                        </ListItem>
                        <ListItem>
                          <img
                            src={require("../../../assets/images/feature_green.svg")}
                            style={{ height: 30, width: 30 }}
                          />
                          <TypoParaStyled style={{ paddingLeft: "20px" }}>
                            <TypoHeadInnerStyled component="span">
                              Share Newsslatters, messages videos, photos&more
                            </TypoHeadInnerStyled>
                          </TypoParaStyled>
                        </ListItem>
                        <ListItem>
                          <img
                            src={require("../../../assets/images/feature_green.svg")}
                            style={{ height: 30, width: 30 }}
                          />
                          <TypoParaStyled style={{ paddingLeft: "20px" }}>
                            <TypoHeadInnerStyled component="span">
                              1000 Contacts
                            </TypoHeadInnerStyled>
                          </TypoParaStyled>
                          <TypoHeadInnerStyled component="span"></TypoHeadInnerStyled>
                        </ListItem>
                        <ListItem>
                          <img
                            src={require("../../../assets/images/feature_green.svg")}
                            style={{ height: 30, width: 30 }}
                          />
                          <TypoParaStyled style={{ paddingLeft: "20px" }}>
                            <TypoHeadInnerStyled component="span">
                              10 User Accounts
                            </TypoHeadInnerStyled>
                          </TypoParaStyled>
                          <TypoHeadInnerStyled component="span"></TypoHeadInnerStyled>
                        </ListItem>
                        <ListItem>
                          <img
                            src={require("../../../assets/images/feature_green.svg")}
                            style={{ height: 30, width: 30 }}
                          />
                          <TypoParaStyled style={{ paddingLeft: "20px" }}>
                            <TypoHeadInnerStyled component="span">
                              24/7 support
                            </TypoHeadInnerStyled>
                          </TypoParaStyled>
                        </ListItem>
                        <ListItem>
                          <img
                            src={require("../../../assets/images/feature_green.svg")}
                            style={{ height: 30, width: 30 }}
                          />
                          <TypoParaStyled style={{ paddingLeft: "20px" }}>
                            <TypoHeadInnerStyled component="span">
                              Manage school clubs (coming soon)
                            </TypoHeadInnerStyled>
                          </TypoParaStyled>
                        </ListItem>
                        <Grid item xs={18}>
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            style={{ maxWidth: "80%", marginTop: "115px" }}
                          >
                            <TypoFreeForeverStyled component="span">
                              YOUR CURRENT PLAN
                            </TypoFreeForeverStyled>
                          </Box>
                        </Grid>
                      </List>
                    </TypoListStyled>
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5}
                  className="shadow"
                  style={{ maxWidth: 650, margin: "0px 5% 0px 2%", borderRadius: "16px" }}
                >
                  <Paper className={classes.paper}>
                    <TypoSideStyled>
                      <TypoSizeStyled style={{ paddingTop: "30px" }}>
                        <Grid noWrap className="main-wrap-head" container>
                          <Grid item xs={7}>
                            <Grid item xs={8}>
                              <TypoHeadStyled>
                                <TypoEuroStyled component="span">
                                  <img
                                    src={require("../../../assets/images/icons8-british-pound-blue.svg")}
                                  // style={{ height: 30, width: 30 }}
                                  />
                                  29.99
                                </TypoEuroStyled>
                              </TypoHeadStyled>
                            </Grid>
                            <Grid item xs={12}>
                              <TypoForeverStyled component="span">
                                Per month (Paid annually)
                              </TypoForeverStyled>
                            </Grid>
                          </Grid>
                          <Grid item xs={2}>
                            <TypoUnlimitedStyled component="span">
                              UNLIMITED
                            </TypoUnlimitedStyled>
                          </Grid>
                        </Grid>
                      </TypoSizeStyled>
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                      >
                        <ListItem>
                          {" "}
                          <img
                            src={require("../../../assets/images/done.png")}
                            style={{ height: 30, width: 30 }}
                          />
                          <TypoParaStyled style={{ paddingLeft: "20px" }}>
                            Unlimited{" "}
                            <TypoHeadInnerStyled component="span">
                              storage
                            </TypoHeadInnerStyled>
                          </TypoParaStyled>
                        </ListItem>
                        <ListItem>
                          {" "}
                          <img
                            src={require("../../../assets/images/done.png")}
                            style={{ height: 30, width: 30 }}
                          />
                          <TypoParaStyled style={{ paddingLeft: "20px" }}>
                            Unlimited{" "}
                            <TypoHeadInnerStyled component="span">
                              Share Term dates and holydays
                            </TypoHeadInnerStyled>
                          </TypoParaStyled>
                        </ListItem>
                        <ListItem>
                          {" "}
                          <img
                            src={require("../../../assets/images/done.png")}
                            style={{ height: 30, width: 30 }}
                          />
                          <TypoParaStyled style={{ paddingLeft: "20px" }}>
                            Unlimited{" "}
                            <TypoHeadInnerStyled component="span">
                              Plan & Manage Parent's evening & appointments.
                            </TypoHeadInnerStyled>
                          </TypoParaStyled>
                        </ListItem>
                        <ListItem>
                          {" "}
                          <img
                            src={require("../../../assets/images/done.png")}
                            style={{ height: 30, width: 30 }}
                          />
                          <TypoParaStyled style={{ paddingLeft: "20px" }}>
                            Unlimited{" "}
                            <TypoHeadInnerStyled component="span">
                            Accept payments online with no platform fees.
                            </TypoHeadInnerStyled>
                          </TypoParaStyled>
                        </ListItem>
                        <ListItem>
                          {" "}
                          <img
                            src={require("../../../assets/images/done.png")}
                            style={{ height: 30, width: 30 }}
                          />
                          <TypoParaStyled style={{ paddingLeft: "20px" }}>
                            Unlimited{" "}
                            <TypoHeadInnerStyled component="span">
                              Real-time live chat 
                            </TypoHeadInnerStyled>
                          </TypoParaStyled>
                        </ListItem>
                        <ListItem>
                          {" "}
                          <img
                            src={require("../../../assets/images/done.png")}
                            style={{ height: 30, width: 30 }}
                          />
                          <TypoParaStyled style={{ paddingLeft: "20px" }}>
                            Unlimited{" "}
                            <TypoHeadInnerStyled component="span">
                             Rymindrs
                            </TypoHeadInnerStyled>
                          </TypoParaStyled>
                        </ListItem>
                        <ListItem>
                          {" "}
                          <img
                            src={require("../../../assets/images/done.png")}
                            style={{ height: 30, width: 30 }}
                          />
                          <TypoParaStyled style={{ paddingLeft: "20px" }}>
                            Unlimited{" "}
                            <TypoHeadInnerStyled component="span">
                            Share newsletters,  messages, videos, photos & more
                            </TypoHeadInnerStyled>
                          </TypoParaStyled>
                        </ListItem>
                        <ListItem>
                          {" "}
                          <img
                            src={require("../../../assets/images/done.png")}
                            style={{ height: 30, width: 30 }}
                          />
                          <TypoParaStyled style={{ paddingLeft: "20px" }}>
                            Unlimited{" "}
                            <TypoHeadInnerStyled component="span">
                              Contacts
                            </TypoHeadInnerStyled>
                          </TypoParaStyled>
                        </ListItem>
                        <ListItem>
                          {" "}
                          <img
                            src={require("../../../assets/images/done.png")}
                            style={{ height: 30, width: 30 }}
                          />
                          <TypoParaStyled style={{ paddingLeft: "20px" }}>
                            Unlimited{" "}
                            <TypoHeadInnerStyled component="span">
                              User accounts
                            </TypoHeadInnerStyled>
                          </TypoParaStyled>
                        </ListItem>
                        <ListItem>
                          {" "}
                          <img
                            src={require("../../../assets/images/done.png")}
                            style={{ height: 30, width: 30 }}
                          />
                          <TypoParaStyled style={{ paddingLeft: "20px" }}>
                            24/7 support
                          </TypoParaStyled>
                        </ListItem>
                        <Grid item xs={15} style={{ paddingBottom: "10px" }}>
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            style={{ maxWidth: "65%", marginTop: "55px" }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              // onClick={() => history.push("/create-rymindr")}
                              onClick={() => handelCheckout()}
                            >
                              UPGRADE NOW
                            </Button>
                          </Box>
                        </Grid>
                      </List>
                    </TypoSideStyled>
                  </Paper>
                </Grid> */}
              </Grid>
            </Box>
          </PaperStyled>
        </Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.rymidr.loading,
    error: state.rymidr.error,
    upcommingrymindr: state.rymidr.upcommingrymindr,
    rymindrDetails2: state.rymidr.rymindrDetails,
    success_message: state.rymidr.success_message,
    qrImg: state.rymidr.qrImg,
    statusList: state.rymidr.statusList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUpcommingRymindrs: (data) => dispatch(getUpcommingRymindrs(data)),
    getRymidrDetails: (data) => dispatch(getRymidrDetails(data)),
    delete_rymindr2: (data) => dispatch(delete_rymindr2(data)),
    generateQrCodeDB: (data, history) =>
      dispatch(generateQrCodeDB(data, history)),
    statusData: (dataTosend) => dispatch(statusData(dataTosend)),
  };
};

UpgradeRymindr.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  getUpcommingRymindrs: PropTypes.func.isRequired,
  upcommingrymindr: PropTypes.any.isRequired,
  getRymidrDetails: PropTypes.func.isRequired,
  rymindrDetails2: PropTypes.any.isRequired,
  delete_rymindr2: PropTypes.func.isRequired,
  success_message: PropTypes.any.isRequired,
  generateQrCodeDB: PropTypes.func.isRequired,
  qrImg: PropTypes.string.isRequired,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(UpgradeRymindr));
