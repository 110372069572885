import {
  ArrowBack,
  Comment,
  Delete,
  Description,
  Edit,
  Headset,
  Image,
  Message,
  MoreVert,
  Search,
  ThumbUp,
  Videocam,
  Close,
  FiberManualRecord,
  Update,
  Check,
} from "@material-ui/icons";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  List,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Dialog,
  ThemeProvider,
  DialogContent,
  DialogTitle,
  CardMedia,
} from "@material-ui/core";
import CircularSpinner from "../../../component/CircularSpinner/index";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  delete_rymindr2,
  getRymidrDetails,
  getUpcommingRymindrs,
  generateQrCodeDB,
  statusData,
} from "../../../store/actions/rymidr";
import Axios from "../../../helper/axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import FullscreenSpinner from "../../../component/FullscreenSpinner";
import Comments from "../../../container/Comments/Comments";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { withSnackbar } from "notistack";
import * as htmlToImage from "html-to-image";
import parse from "html-react-parser";
import io from "socket.io-client";
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  MailruIcon,
  EmailIcon,
  LivejournalIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  WeiboIcon,
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
import Share from "@material-ui/icons/Share";
import GetApp from "@material-ui/icons/GetApp";
import RymindrOriginal from "../../../assets/images/rymindr_original.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: "0px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    position: "relative",
    overflow: "auto",
    height: 130 + "%",
  }, // => {maxHeight: 'calc(100vh - 80px)', minHeight: 100 + 'vh', }
  rightPanel: {
    [theme.breakpoints.up("md")]: {
      display: "block !important",
    },
  },

  iconAttach: {
    color: theme.palette.primary.dark,
    background: theme.palette.primary.light,
    height: 32,
    width: 32,
    borderRadius: 60,
    padding: 5,
    float: "left",
    marginTop: -5,
    marginRight: "10px",
  },
  icon: {
    width: 150,
    marginBottom: 40,
  },
  content: {
    textAlign: "center",
    textAlign: "-webkit-center",
    paddingTop: "0%",
    paddingLeft: "20%",
    height: "80vh",
  },
  wrapText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  overFlowHide: {
    overflow: "inherit",
  },
}));
const styles = {
  input: {
    backgroundColor: "red",
  },
};
const AvatarStyled = styled(Avatar)({
  height: 25,
  width: 25,
  "& img": {
    height: "auto",
  },
});
const DialogTitleStyled = styled(DialogTitle)({
  borderBottom: "1px solid #e0e0e0",
  padding: "10px 24px",
});
const TypoStatusStyled = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.contrastText,
  width: 60,
  textAlign: "right",
  fontWeight: "600",
}));
const TypoStatusStyledAccept = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: "#44b700",
  width: 60,
  textAlign: "right",
  fontWeight: "600",
}));
const TypoStatusStyledReject = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: "#FF0000",
  width: 60,
  textAlign: "right",
  fontWeight: "600",
}));
const TypoTitleStyled = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.contrastText,
}));
const TypoPopHeadStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontSize: 18,
  fontWeight: "600",
}));
const TypoContentStyled = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  color: theme.palette.text.primary,
}));

const AvatarShareStyled = styled(Avatar)({
  height: 50,
  width: 50,
});

const BoxStyled = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  "&>span": { marginRight: 10 },
});

const TypoParaStyled = styled(Typography)(({ theme }) => ({
  fontSize: 18,
}));
const TypoHeadStyled = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.primary.main,
  // textTransform: "uppercase",
  // fontWeight: "bold",
}));
const TypoHeadMainStyled = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.primary.main,
  textTransform: "uppercase",
  fontWeight: "bold",
}));
const TypoOrderStyled = styled(Typography)(({ theme }) => ({
  fontSize: 30,
  color: theme.palette.primary.main,
  // textTransform: "uppercase",
  fontWeight: "bold",
}));

const TypoTextStyled = styled(Typography)(({ theme }) => ({
  fontSize: 23,
  // color: theme.palette.primary.main,
  // textTransform: "uppercase",
  // fontWeight: "bold",
}));

const TypoDoneStyled = styled(Typography)(({ theme }) => ({
  paddingRight: "42%",
  paddingTop: "6%",
}));

const TypoFreeStyled = styled(Typography)(({ theme }) => ({
  fontSize: 45,
  color: "#31d631",
}));

const TypoSizeStyled = styled(Typography)(({ theme }) => ({
  paddingTop: 30,
}));

const TypoForeverStyled = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  color: "#212529",
  fontWeight: "bold",
}));

const TypoFreeForeverStyled = styled(Typography)(({ theme }) => ({
  fontSize: 30,
  color: "#31d631",
  fontWeight: "bold",
}));

const TypoEuroStyled = styled(Typography)(({ theme }) => ({
  fontSize: 40,
  color: "rgb(3 129 254)",
}));
const TypoUnlimitedStyled = styled(Typography)(({ theme }) => ({
  fontSize: 30,
  color: "rgb(3 129 254)",
  fontWeight: "bold",
}));
const TypoMainStyled = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: "rgb(64, 87, 106)",
  // textTransform: "uppercase",
  // fontWeight: "bold",
}));

const TypoListStyled = styled(Typography)(({ theme }) => ({
  backgroundColor: "#e4f2e7",
}));
const TypoSideStyled = styled(Typography)(({ theme }) => ({
  backgroundColor: "rgb(220 234 243)",
}));

const TypoHeadInnerStyled = styled(TypoHeadStyled)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const TypoHeadPaymentStyled = styled(TypoHeadStyled)(({ theme }) => ({
  // color: "rgb(82 200 249)",
  color: "rgb(64, 87, 106)",
  fontSize: "24px",
  // fontWeight: "bold"
  // font-size: 24px;
  //   font-weight: bold;
}));
const TypoHeadDoneStyled = styled(TypoHeadStyled)(({ theme }) => ({
  // color: "rgb(82 200 249)",
  color: "rgb(41 119 152)",
  fontSize: "24px",
  fontWeight: "bold"
 
}));

const TypoConfirmStyled = styled(TypoHeadStyled)(({ theme }) => ({
  color: "rgb(66 222 66)",
}));

const TypoHeadTopStyled = styled(TypoHeadStyled)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const TypoListSubtext = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.contrastText,
}));

const TypoListSubtext2 = styled(TypoListSubtext)(({ theme }) => ({
  color: "#696969",

  display: "-webkit-box",
  "-webkit-line-clamp": 2,
}));

const SearchFieldStyled = styled(TextField)(({ theme }) => ({
  paddingTop: 10,
  paddingBottom: 10,
}));

const ListStyled = styled(List)(({ theme }) => ({
  paddingLeft: 20,
}));

const GridStyled = styled(Grid)({
  padding: "15px 15px 15px 30px",
});

const AvatarComntStyled = styled(Avatar)({
  height: 42,
  width: 42,
  marginRight: 20,
});

const TextFieldStyled = styled(TextField)({
  width: 100 + "%",
  height: 100,
});

const TypoCmntName = styled(Typography)({
  color: "#757575",
  fontWeight: "bold",
  fontSize: 12,
  "& span": {
    fontWeight: "normal",
    marginLeft: 20,
  },
});
const TypoCmntTxt = styled(Typography)({
  color: "#3d3d3d",
  fontSize: 14,
});

const ButtonStyled = styled(Button)({
  color: "#757575",
  fontSize: 12,
});

const BoxColorStyled = styled(Box)({
  background: "#f0f6fb",
  fontSize: 12,
  padding: "10px 15px",
  borderRadius: 15,
  minWidth: 160,
  justifyContent: "space-between",
});

const BoxImgStyled = styled(Box)({
  textAlign: "center",
  marginRight: 20,
  "& div": {
    margin: "auto",
  },
});
const TypoStyled = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  color: theme.palette.text.primary,
  marginTop: 10,
  marginBottom: 10,
  marginRight: 10,
  cursor: "pointer",
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadgeReject = withStyles((theme) => ({
  badge: {
    backgroundColor: "#e50214",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadgePending = withStyles((theme) => ({
  badge: {
    backgroundColor: "#1773bf",
    color: "#1773bf",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    padding: 0,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const ButtonPlain = styled(Button)(({ theme }) => ({
  color: "#98a5af",
  fontSize: 12,
  textTransform: "capitalize",
  background: "none",
  boxShadow: "none",
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  borderRadius: 8,
  maxWidth: 600,
}));

const DialogContentStyled = styled(DialogContent)({
  textAlign: "center",
});

const PaperStyled = styled(Paper)(({ theme }) => ({
  padding: 30,
  height: 105 + "%",
}));

const TypoTxtStyled = styled(Typography)(({ theme }) => ({
  textTransform: "uppercase",
  color: theme.palette.text.primary,
  fontSize: 16,
}));

const TypoBusStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 18,
  fontWeight: "800",
}));

const options = [];

const DoneRymindr = (props) => {
  const history = useHistory();
  const {
    enqueueSnackbar,
    error,
    getUpcommingRymindrs,
    upcommingrymindr,
    loading,
    getRymidrDetails,
    rymindrDetails2,
    delete_rymindr2,
    success_message,
    generateQrCodeDB,
    qrImg,
    statusData,
    statusList,
  } = props;
  const { user_id, business_code, first_name, last_name } = JSON.parse(
    localStorage.getItem("userData")
  );
  const [showPanel, setShowPanel] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleterymindr, setDeleterymindr] = useState(null);

  const [firstRun, setFirstRun] = useState(false);
  const Location = useLocation();

  const [selectedItem, setSelectedItem] = useState(null);
  const [openImage, setOpenImage] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState("");
  const [showParticularMessage, setShowParticularMessage] = useState([]);
  const [state, setState] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  const [searchvalue, setSearchvalue] = useState("");

  const [open, setOpen] = React.useState(false);
  const [users_list, setUserList] = useState([]);

  const [openQR, setOpenQR] = React.useState(false);

  const [openDownloadImage, setOpenDownloadImage] = React.useState(false);

  const [selectedId, setSelectedId] = React.useState("");

  const [isQRCodeImage, setQRCodeImage] = React.useState();

  const [rymindrDetails, setRymindrDetails] = useState({});

  const [Loading, setLoading] = useState(false);

  const [accepted, setAccepted] = useState(0);
  const [total, setTotal] = useState(0);
  const status_data = useRef({});
  const pageNum = useRef(1);

  var pageNumber = 1;
  const handleCloseDownloadModal = () => {
    // alert('clsoe')
    setOpenDownloadImage(false);
  };
  console.log("props11111111", props);
  const handleClickQROpen = (
    user_qr_code,
    user_qr_code_base64,
    business_code
  ) => {
    setOpenQR(true);
  };

  const classes = useStyles();

  const regex = "/<(.|\n)*?>/g";

  useEffect(() => {
    const dataToSend = {
      user_id,
    };
    getUpcommingRymindrs(dataToSend);
    // setRymindrDetails(upcommingrymindr && upcommingrymindr.upcomingList[0]);
  }, []);

  // Query string params
  const { param, paramType } = useParams();

  const [width, setWidth] = React.useState(0);
  const [width1, setWidth1] = React.useState(0);
  const measuredRef = React.useCallback((node) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);
  const measuredRef1 = React.useCallback((node) => {
    if (node !== null) {
      setWidth1(node.getBoundingClientRect().width);
    }
  }, []);

  return (
    <>
      <Grid
        noWrap
        className="main-wrap-head"
        container
        style={{ marginBottom: 20 }}
        alignItems="center"
      >
        <Grid item xs={4}>
          <Box display="flex" alignItems="center">
            <TypoHeadMainStyled>
              Upgrade{" "}
              <TypoHeadInnerStyled component="span">
                Rymindr
              </TypoHeadInnerStyled>
            </TypoHeadMainStyled>
          </Box>
        </Grid>
      </Grid>
      <Grid
        className="main-wrap-body upcmgRym-cont-wrap"
        container
        alignItems="stretch"
      >
        <Grid
          ref={measuredRef}
          item
          xs={12}
          md={12}
          className={classes.rightPanel}
          style={{ display: showPanel ? "none" : "block" }}
        >
          <PaperStyled>
            <Box className={classes.content}>
              <Grid
                noWrap
                className="main-wrap-head"
                container
                style={{ marginBottom: 55 }}
                alignItems="center"
              >
                <Grid item xs={2}>
                  <img
                    src={require("../../../assets/images/plan_disable.svg")}
                    style={{ height: 50, width: 50 }}
                  />
                  <TypoHeadStyled>
                    <TypoMainStyled component="span">View Plan</TypoMainStyled>
                  </TypoHeadStyled>
                </Grid>
                <img
                  src={require("../../../assets/images/next_step.svg")}
                  style={{ height: 50, width: 50 }}
                />
                <Grid item xs={2}>
                  <img
                    src={require("../../../assets/images/payment_disable.svg")}
                    style={{ height: 50, width: 50 }}
                  />
                  <TypoHeadStyled>
                    <TypoHeadPaymentStyled component="span" >
                      Payment
                    </TypoHeadPaymentStyled>
                  </TypoHeadStyled>
                </Grid>
                <img
                  src={require("../../../assets/images/next_step.svg")}
                  style={{ height: 50, width: 50 }}
                />
                <Grid item xs={2}>
                  <img
                    src={require("../../../assets/images/done.svg")}
                    style={{ height: 65, width: 70 }}
                  />
                  <TypoHeadStyled>
                    <TypoHeadDoneStyled component="span">
                      Done
                    </TypoHeadDoneStyled>
                  </TypoHeadStyled>
                </Grid>
              </Grid>

              <TypoDoneStyled>
                <Grid item xs={12}>
                  <Grid item xs={2}>
                    <img
                      src={require("../../../assets/images/success.svg")}
                      style={{ height: 70, width: 75 }}
                    />
                  </Grid>
                  <Grid item xs={8} style={{ paddingTop: "5%" }}>
                    <TypoOrderStyled>
                      <TypoConfirmStyled component="span">
                        Account activated
                      </TypoConfirmStyled>
                    </TypoOrderStyled>
                  </Grid>
                  <TypoTextStyled>
                    <Grid item xs={10} style={{ paddingTop: "5%" }}>
                      Congratulations! Your account has been successfully activated.
                    </Grid>
                    <Grid item xs={10}>
                      Thank you for becoming part of Rymindr.  Click the following link to go back to your dashboard.
                    </Grid>
                    <Grid item xs={10}>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        style={{ maxWidth: "28%", marginTop: "50px" }}
                      >
                        <Button
                          style={{ color: "white", backgroundColor: "rgb(41, 119, 152)" }}
                          variant="contained"
                          // color="primary"
                          size="large"
                          onClick={() => history.push("/")}
                        >
                          Go to dashboard
                        </Button>
                      </Box>
                    </Grid>
                  </TypoTextStyled>
                </Grid>
              </TypoDoneStyled>
            </Box>
          </PaperStyled>
        </Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.rymidr.loading,
    error: state.rymidr.error,
    upcommingrymindr: state.rymidr.upcommingrymindr,
    rymindrDetails2: state.rymidr.rymindrDetails,
    success_message: state.rymidr.success_message,
    qrImg: state.rymidr.qrImg,
    statusList: state.rymidr.statusList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUpcommingRymindrs: (data) => dispatch(getUpcommingRymindrs(data)),
    getRymidrDetails: (data) => dispatch(getRymidrDetails(data)),
    delete_rymindr2: (data) => dispatch(delete_rymindr2(data)),
    generateQrCodeDB: (data, history) =>
      dispatch(generateQrCodeDB(data, history)),
    statusData: (dataTosend) => dispatch(statusData(dataTosend)),
  };
};

DoneRymindr.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  getUpcommingRymindrs: PropTypes.func.isRequired,
  upcommingrymindr: PropTypes.any.isRequired,
  getRymidrDetails: PropTypes.func.isRequired,
  rymindrDetails2: PropTypes.any.isRequired,
  delete_rymindr2: PropTypes.func.isRequired,
  success_message: PropTypes.any.isRequired,
  generateQrCodeDB: PropTypes.func.isRequired,
  qrImg: PropTypes.string.isRequired,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DoneRymindr));
