import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Box,
  IconButton,
  Typography,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Grid,
  Avatar,
  Divider,
} from "@material-ui/core";
import PropTypes from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, styled } from "@material-ui/core/styles";
import React, { useEffect, useMemo, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
const ButtonStyled = styled(Button)(({ theme }) => ({
  color: theme.palette.error.main,
}));
let find_key;
const CloseButton = styled(IconButton)({
  position: "absolute",
  right: 0,
  top: 0,
});

const DialogTitleStyled = styled(DialogTitle)({
  borderBottom: "1px solid #e0e0e0",
  padding: "10px 24px",
  marginBottom: 20,
});
const ButtonDanger = styled(Button)(({ theme }) => ({
  background: theme.palette.error.main,
  color: theme.palette.warning.contrastText,
}));

const TypoPopHeadStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontSize: 18,
  fontWeight: "600",
}));

const SearchFieldStyled = styled(TextField)(({ theme }) => ({
  paddingTop: 10,
  paddingBottom: 10,
  "& div": { height: 50 },
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  borderRadius: 8,
}));

const AvatarShareStyled = styled(Avatar)({
  height: 50,
  width: 50,
  marginRight: 10,
});

const TypoListSubtext = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.contrastText,
}));

const ListStyled = styled(List)(({ theme }) => ({
  maxHeight: 550,
  overflow: "auto",
}));

const ButtonSuccess = styled(Button)(({ theme }) => ({
  background: "#00d264",
  color: theme.palette.success.contrastText,
}));

const DeleteRymindr = ({
  handleClose,
  open,
  deleteRymindr,
  getRymindrListData,
  type,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [category, setCategory] = React.useState([]);
  const [reason, setReason] = useState(null);
  const [categoryVal, setCategoryVal] = React.useState("");
  const handleChange = (e) => {
    setReason(e.target.value);
  };

  useEffect(() => {
    var obj = [];
    getRymindrListData.forEach((x) => {
      obj.push(x);
    });
    console.log("object data", obj);
    setCategory(obj);
  }, [getRymindrListData]);

  const handleCloseWithValue = () => {
    deleteRymindr(reason, categoryVal);
    setReason("");
    setCategoryVal("");
  };

  const handleCategoryChange = (event) => {
    setCategoryVal(event.target.value);
  };

  console.log("data on delete", getRymindrListData, type);

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xs"
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {/* <DialogTitle id="responsive-dialog-title" /> */}
        {type != "" ? (
          <DialogTitleStyled id="form-dialog-title">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <TypoPopHeadStyled>Delete Recurring Rymindr</TypoPopHeadStyled>
              {/* <IconButton color="default" onClick={handleClose}>
                <CloseIcon />
              </IconButton> */}
            </Box>
          </DialogTitleStyled>
        ) : (
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this Rymindr ?
            </DialogContentText>
          </DialogContent>
        )}
        <DialogContent>
          <ListStyled>
            {type != "" ? (
              <Grid item xs={12}>
                <TextField
                  select
                  label="Select Rymindr Date"
                  name="category"
                  value={categoryVal}
                  fullWidth
                  onChange={handleCategoryChange}
                  variant="outlined"
                  style={{ marginBottom: 20 }}
                >
                  {category.map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            ) : null}
            <Divider variant="inset" component="li" />
            <TextField
              id="outlined-select-currency"
              label="Reason(Optional)"
              value={reason}
              fullWidth
              onChange={handleChange}
              variant="outlined"
              name="reason"
              minRows={4}
              multiline
              style={{ width: "100%", alignSelf: "center" }}
            ></TextField>
          </ListStyled>
        </DialogContent>
        <DialogActions>
          <ButtonStyled autoFocus onClick={handleClose}>
            No
          </ButtonStyled>
          <Button onClick={handleCloseWithValue} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeleteRymindr.propTypes = {
  handleClose: PropTypes.func.isRequired,
  deleteRymindr: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DeleteRymindr;
