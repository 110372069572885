import { makeStyles, styled, withStyles } from '@material-ui/core/styles';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import RymindrOriginal from '../../assets/images/rymindr_original.png';
import alertImg from '../../assets/images/alert_web.png';
import ResponsiveDialog from './Modal/AlertModalDelete';
import {
  Box,
  Button,
  Divider,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  InputAdornment,
  TextField,
  IconButton,
  Hidden,
  MenuItem,
  Badge,
  Menu,
  Dialog,
  DialogContent,
  DialogTitle,
  AppBar,
  Tabs,
  Tab,
  CardMedia,
} from '@material-ui/core';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import {
  Comment,
  Description,
  Delete,
  Edit,
  Search,
  ArrowBack,
  Chat,
  Send,
  Videocam,
  Headset,
  PersonAdd,
  Print,
  Drafts,
  MoreVert,
  ThumbUp,
  Message,
  Close,
  FiberManualRecord,
  Check,
  Update,
} from '@material-ui/icons';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import clsx from 'clsx';
import Axios from '../../helper/axios';
import ReactDOM from 'react-dom';
import Pdf from 'react-to-pdf';
import parse from 'html-react-parser';
import io from 'socket.io-client';

import { getAlretList, getAlertDetailsById, deleteAlert, getEmergencyAlertStatus } from '../../store/actions/messageCenterAction';

//  *********** UI Styles Start *****************
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    position: 'relative',
    height: 100 + '%',
    maxHeight: 1000,
    overflow: 'auto',
  },
  rightPanel: {
    [theme.breakpoints.up('md')]: {
      display: 'block !important',
    },
  },
  iconAttach: {
    color: theme.palette.primary.dark,
    background: theme.palette.primary.light,
    height: 32,
    width: 32,
    borderRadius: 16,
    padding: 5,
    float: 'left',
    marginTop: -5,
    marginRight: '10px',
  },
  icon: {
    width: 150,
    marginBottom: 40,
  },
  content: {
    textAlign: 'center',
    textAlign: '-webkit-center',
    paddingTop: '15%',
    height: '80vh',
  },
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  borderRadius: 8,
}));

const ButtonColor = styled(Button)(({ theme }) => ({
  background: theme.palette.props.main,
  color: theme.palette.props.contrastText,
}));

const AvatarShareStyled = styled(Avatar)({
  height: 50,
  width: 50,
});

const TypoListSubtext = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.contrastText,
}));

const SearchFieldStyled = styled(TextField)(({ theme }) => ({
  padding: 20,
  paddingLeft: 0,
  paddingRight: 0,
}));

const TypoHeadStyled = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
  fontWeight: 'bold',
}));

const TypoHeadInnerStyled = styled(TypoHeadStyled)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const GridStyled = styled(Grid)({
  padding: '15px 15px 15px 30px',
});

const BoxStyled = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
});

const ButtonPlain = styled(Button)(({ theme }) => ({
  color: '#98a5af',
  fontSize: 12,
  textTransform: 'capitalize',
  background: 'none',
  boxShadow: 'none',
}));

const ListStyled = styled(List)(({ theme }) => ({
  paddingLeft: 20,
}));

const AvatarStyled = styled(Avatar)({
  height: 'auto',
  width: 25,
  '& img': {
    height: 'auto',
  },
});

const TypoStatusStyled = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.contrastText,
  width: 60,
  textAlign: 'right',
  fontWeight: '600',
}));

const TypoStatusStyledRead = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: '#44b700',
  width: 60,
  textAlign: 'right',
  fontWeight: '600',
}));

const TypoTitleStyled = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.contrastText,
  marginBottom: 10,
}));

const TypoContentStyled = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text.primary,
}));

const TypoStyled = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text.primary,
  marginTop: 10,
  marginBottom: 10,
  marginRight: 10,
  cursor: 'pointer',
}));

const DialogTitleStyled = styled(DialogTitle)({
  borderBottom: '1px solid #e0e0e0',
  padding: '10px 24px',
});

const TypoPopHeadStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontSize: 18,
  fontWeight: '600',
}));

const StyledReadBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const StyledUnreadBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#1773bf',
    color: '#1773bf',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadgeReject = withStyles((theme) => ({
  badge: {
    backgroundColor: '#e50214',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

//  *********** UI Styles Start *****************

const EmergencyAlert = (props) => {
  const history = useHistory();

  const { error, success_message, enqueueSnackbar, ...other } = props;
  // const [showParticularALert, setShowParticularALert] = useState([]);
  const [showPanel, setShowPanel] = useState(true);
  const [showAlertList, setShowAlertList] = useState([]);
  const [alertDetails, setAlertDetails] = useState('');
  const [selectedImage, setSelectedImage] = React.useState('');
  const [openImage, setOpenImage] = React.useState(false);
  const [selected, setSelected] = React.useState('');
  const [messageDetail, setMessageDetail] = useState([]);
  const [contactStatusData, setContactStatusData] = React.useState([])
  const [alerts, setAlert] = useState("")
  const [currentTab, setCurrentTab] = useState(0);
  const [Loading, setLoading] = useState(false);

  const open1 = useRef(false);
  const pageNm = useRef(1);
  const contact_data = useRef({});
  const handleClickImageOpen = (file) => {
    console.log("file", file)
    setOpenImage(true);
    setSelectedImage(file);
  };

  const handleCloseImageModal = () => {
    setOpenImage(false);
  };

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    // setTimeout(() => {
    const data = { user_id: alerts?.user_id, alert_id: alerts?._id, page: 1 }
    console.log("data#####", data)
    if (alerts && alerts._id) {
      console.log("alerts@@@@", alerts.user_id, alerts._id,)
      getEmergencyAlertStatus(data)
    }
    // }, 3000);
  }, [alerts]);

  const handleClickOpen = () => {
    setOpen(true);
    console.log("alerts", alerts?.sharedWithUsers)
    // if (contactStatus?.data) {
    setContactStatusData(alerts?.sharedWithUsers)
    getEmergencyAlertStatus({ user_id: alerts.user_id, alert_id: alerts._id, page: 1 })
    // setTimeout(() => {
    //   let Ele = document.querySelector('#scrollElement');
    //   console.log('scrollElementscrollElementscrollElement');
    //   if (Ele) {
    //     console.log('Ele-', Ele, contact_data.current);
    //   console.log('contact_data.current.current_page', contact_data.current);
    //     Ele.addEventListener('scroll', () => {
    //       console.log('Ele.offsetHeight', Ele.offsetHeight, Ele.scrollTop, Ele.scrollHeight, open1.current, currentTab);
    //     console.log('Math.floor', Math.ceil(Ele.offsetHeight + Ele.scrollTop + 1), Ele.scrollHeight);
    //       if (
    //         Math.ceil(Ele.offsetHeight + Ele.scrollTop + 1) >= Ele.scrollHeight &&
    //         open1.current &&
    //         currentTab === 0
    //       ) {
    //         if (
    //           contact_data.current.data &&
    //           contact_data.current.data !== undefined &&
    //           contact_data.current.current_page <= contact_data.current.last_page &&
    //           pageNm.current < contact_data.current.last_page
    //         ) {
    //           setLoading(true);
    //           pageNm.current = pageNm.current + 1;
    //           const dataToSend = { user_id: alerts.user_id, alert_id: alerts._id, page: pageNm.current };
    //           getEmergencyAlertStatus(dataToSend)
    //         } else {
    //           console.log('calling3-', pageNm);
    //         }
    //       }
    //     });
    //   }
    // }, 1000);
    // }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenDelete(false);
  };

  const [openDelete, setOpenDelete] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [deleteMsgId, setDeleteMsgId] = useState(null);

  const handleDelete = (id) => {
    if (id != undefined) {
      setDeleteMsgId(id);
      setOpenDelete(true);
    }
  };

  useEffect(() => {
    let mounted = true;
    setTimeout(function () {
      setOpenDelete(false);
      // if (mounted) {
      success_message && enqueueSnackbar(success_message, { variant: 'success' });
      // }
    }, 1000);
    return function cleanup() {
      mounted = false;
    };
  }, [success_message]);

  useEffect(() => {
    let mounted = true;
    setTimeout(function () {
      if (mounted) {
        error && enqueueSnackbar(error, { variant: 'error' });
      }
    }, 0);
    return function cleanup() {
      mounted = false;
    };
  }, [error]);

  const deleteMsg = (delete_id) => {
    dispatch(deleteAlert(deleteMsgId, alertDetails.user_id));
    setOpenDelete(false);
    // const getIndex = messageDetail.findIndex((element) => element.id == delete_id);
    // messageDetail.splice(getIndex, 1);
    // setMessageDetail(messageDetail);
    // console.log('messageDetail-', messageDetail);
  };

  const classes = useStyles();

  //  ***************************************************************************************************

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAlretList());
  }, []);

  const { alertList } = useSelector((state) => state.messageCenterReducer);

  console.log('alertList', alertList);
  useEffect(() => {
    if (alertList && alertList.data && alertList.data.length > 0) {
      setShowAlertList(alertList.data);
      handleShowDetailRight(alertList.data[0]);
    }
  }, [alertList]);

  const { alertDetailById, contactStatus } = useSelector((state) => state.messageCenterReducer);
  console.log("alertDetailById", alertDetailById)
  useEffect(() => {
    if (alertDetailById) {
      setAlert(alertDetailById)
      setAlertDetails(alertDetailById);
      setStatusList(alertDetailById.sharedWithUsers);
    }
  }, [alertDetailById]);
  console.log('alertDetails', alertDetails);

  const firstAlertDetails = showAlertList[0];
  console.log("firstAlertDetails", firstAlertDetails)
  useEffect(() => {
    setTimeout(() => {
      dispatch(getAlertDetailsById({ user_id: firstAlertDetails?.user_id, alert_id: firstAlertDetails?._id }));
    }, 5000);
  }, [firstAlertDetails])

  const handleShowDetailRight = (alert) => {
    // console.log('alert',alert);
    // setAlert(alert)
    dispatch(getAlertDetailsById({ user_id: alert.user_id, alert_id: alert._id }));
    // dispatch(getEmergencyAlertStatus({ user_id: alert.user_id, alert_id: alert._id, page: 1 }))
  };

  const handleContactChange = (keyboard) => {
    const searchStr = keyboard;
    const status = [...contactStatusData];
    console.log('messages', status, keyboard);
    if (searchStr == '') {
      setContactStatusData(status);
    } else {
      console.log('Here');
      // Filter them
      const filteredList = status.filter(
        (obj) =>
          obj.first_name.toLowerCase().includes(searchStr.toLowerCase()) ||
          obj.last_name.toLowerCase().includes(searchStr.toLowerCase()) ||
          obj.mobile_no.toLowerCase().includes(searchStr.toLowerCase())
      );
      setContactStatusData(filteredList);
    }
  };

  return (
    <>
      <Grid className="main-wrap-head" container style={{ marginBottom: 20 }} alignItems="stretch">
        <Grid item xs={4}>
          <Box display="flex" alignItems="center">
            <TypoHeadStyled variant="h4">
              Emergency <TypoHeadInnerStyled component="span">Alert</TypoHeadInnerStyled>
            </TypoHeadStyled>
          </Box>
        </Grid>

        <Grid item xs={8}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              size="large"
              className="mr-10"
              startIcon={<PersonAdd />}
              onClick={() => history.push('/create-emergency-alert')}
            >
              Add new alert
            </Button>{' '}
            <ButtonColor
              variant="contained"
              color="primary"
              size="large"
              onClick={() => history.push('/alert-template')}
            >
              Saved Alerts
            </ButtonColor>
          </Box>
        </Grid>
      </Grid>

      <Grid className="main-wrap-body msg-cont-wrap" container alignItems="stretch">
        {showPanel ? (
          <Grid item xs={12} md={4} className="pr-25 sidebar-list leftSide-cont">
            <Paper className={classes.paper}>
              <SearchFieldStyled
                id="input-with-icon-textfield"
                variant="outlined"
                fullWidth
                size="small"
                // onKeyUp={handleOnChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
              <List>
                {showAlertList.length > 0 ? (
                  <>
                    {showAlertList.map((alert, key) => {
                      // return <p>{alert.title}</p>
                      return (
                        <>
                          <Hidden mdUp implementation="css">
                            <ListItemStyled
                              button
                              // onClick={() => handleShowDetail(alert._id)}
                              key={key}
                            // selected={selected ? selected === alert._id : alertDetails._id === alert._id}
                            >
                              <ListItemAvatar>
                                <AvatarShareStyled alt="semy Sharp" src={alertImg} />
                              </ListItemAvatar>
                              <ListItemText
                                primary={alert.title}
                                secondary={
                                  <TypoListSubtext>
                                    {alert.day} {alert.created_at}
                                  </TypoListSubtext>
                                }
                              />
                            </ListItemStyled>
                            <Divider variant="inset" component="li" />
                          </Hidden>

                          <Hidden smDown implementation="css">
                            <ListItemStyled
                              button
                              onClick={() => handleShowDetailRight(alert)}
                              key={key}
                              // selected={
                              //   selectedItem
                              //     ? selectedItem === item.id
                              //     : messageDetail.id === item.id
                              // }
                              selected={selected ? selected === alert._id : alertDetails._id === alert._id}
                            >
                              <ListItemAvatar>
                                <AvatarShareStyled alt="semy Sharp" src={alertImg} />
                              </ListItemAvatar>
                              <ListItemText
                                primary={alert.subject}
                                secondary={
                                  <TypoListSubtext>
                                    {moment(alert.created_at).format('dddd DD MMMM YYYY , LT')}
                                  </TypoListSubtext>
                                }
                              />
                            </ListItemStyled>
                            <Divider variant="inset" component="li" />
                          </Hidden>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <Typography className={classes.commingSoonDesc}>No records Found</Typography>
                )}
              </List>
            </Paper>
          </Grid>
        ) : (
          ''
        )}

        <Grid
          item
          xs={12}
          md={8}
          className={clsx(classes.rightPanel, 'rightSide-cont')}
          style={{ display: showPanel ? 'none' : 'block' }}
        >
          <Paper className={classes.paper}>
            {alertDetails && alertDetails?.subject ? (
              <>
                <GridStyled container alignItems="center">
                  <Grid xs={6}>
                    <BoxStyled alignItems="center">
                      <AvatarShareStyled alt="semy Sharp" className="mr-0" src={alertImg} />
                      <Typography noWrap>
                        <Box fontWeight="fontWeightBold" m={1}>
                          {/* {alertDetails.title
                                ? alertDetails.title
                                : "No Subject"} */}
                          Emergency Alert | {alertDetails?.subject ? alertDetails?.subject : 'No Alert'}
                        </Box>
                      </Typography>
                    </BoxStyled>
                  </Grid>

                  <Grid xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                      <ButtonPlain
                        disableRipple
                        onClick={() => handleDelete(alertDetails._id)}
                        startIcon={<Delete style={{ color: '#ec4d4b' }} />}
                      >
                        Delete
                      </ButtonPlain>
                    </Box>
                  </Grid>
                </GridStyled>

                <ListStyled>
                  {/* <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <AvatarStyled alt="semy Sharp" variant="square" src={require('../../assets/images/note.png')} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<TypoTitleStyled>Alert</TypoTitleStyled>}
                      secondary={<TypoContentStyled>{alertDetails?.subject}</TypoContentStyled>}
                    />
                  </ListItem>

                  <Divider variant="inset" component="li" /> */}
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <AvatarStyled
                        alt="semy Sharp"
                        variant="square"
                        src={require('../../assets/images/calendar_icon.png')}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<TypoTitleStyled>Date & Time</TypoTitleStyled>}
                      secondary={
                        <TypoContentStyled>
                          {moment(alertDetails.created_at).format('dddd DD MMMM YYYY , LT')}
                        </TypoContentStyled>
                      }
                    />
                  </ListItem>

                  <Divider variant="inset" component="li" />

                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <AvatarStyled alt="semy Sharp" variant="square" src={require('../../assets/images/email.png')} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<TypoTitleStyled>Instruction</TypoTitleStyled>}
                      secondary={<Typography>{parse(alertDetails.message)}</Typography>}
                    />
                  </ListItem>

                  <Divider variant="inset" component="li" />
                  {alertDetails.sharedWithUsers.length > 0 ? (
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <AvatarStyled
                          alt="semy Sharp"
                          variant="square"
                          src={require('../../assets/images/user_icon.png')}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={<TypoTitleStyled>Contacts</TypoTitleStyled>}
                        secondary={
                          <Box display="flex" justifyContent="space-between">
                            <BoxStyled>
                              {alertDetails.sharedWithUsers.map((contact_item, contact_key) => {
                                return (
                                  <>
                                    {contact_key >= 10 ? (
                                      false
                                    ) : (
                                      <Box mr={2} mb={2}>
                                        {contact_item.status == 0 ? (
                                          <StyledUnreadBadge
                                            overlap="circle"
                                            anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'right',
                                            }}
                                            // variant='dot'
                                            badgeContent={
                                              <Update
                                                style={{
                                                  color: 'white',
                                                  fontSize: 10,
                                                }}
                                              />
                                            }
                                          >
                                            <AvatarShareStyled
                                              alt="semy Sharp"
                                              src={contact_item.profile_image}
                                              title={contact_item.first_name}
                                            />
                                          </StyledUnreadBadge>
                                        ) : contact_item.status == 1 ? (
                                          <StyledReadBadge
                                            overlap="circle"
                                            anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'right',
                                            }}
                                            // variant='dot'
                                            badgeContent={
                                              <Check
                                                style={{
                                                  color: 'white',
                                                  fontSize: 10,
                                                }}
                                              />
                                            }
                                          >
                                            <AvatarShareStyled
                                              alt="semy Sharp"
                                              src={contact_item.profile_image}
                                              title={contact_item.first_name}
                                            />
                                          </StyledReadBadge>
                                        ) : (
                                          <StyledBadgeReject
                                            overlap="circle"
                                            anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'right',
                                            }}
                                            // variant='dot'
                                            badgeContent={
                                              <Check
                                                style={{
                                                  color: 'white',
                                                  fontSize: 10,
                                                }}
                                              />
                                            }
                                          >
                                            <AvatarShareStyled
                                              alt="semy Sharp"
                                              src={contact_item.profile_image}
                                              title={contact_item.first_name}
                                            />
                                          </StyledBadgeReject>
                                        )}
                                        {/* </StyledBadge> */}
                                      </Box>
                                    )}
                                  </>
                                );
                              })}
                              <Box
                                style={{
                                  fontSize: 18,
                                  color: '#1abaff',
                                  padding: '10px 2px 0px 15px',
                                  cursor: 'pointer',
                                }}
                                onClick={handleClickOpen}
                              >
                                {alertDetails && alertDetails.sharedWithUsers.length >= 10
                                  ? '+ ' + (alertDetails.sharedWithUsers.length - 10)
                                  : ''}
                              </Box>
                            </BoxStyled>
                            <Box>
                              <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                                Status
                              </Button>
                            </Box>
                          </Box>
                        }
                      />
                    </ListItem>
                  ) : null}

                  {alertDetails.attachement != '' ? (
                    <>
                      <Divider variant="inset" component="li" />
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <AvatarStyled
                            alt="semy Sharp"
                            variant="square"
                            src={require('../../assets/images/attach.png')}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<TypoTitleStyled>Attachment</TypoTitleStyled>}
                          secondary={
                            <Box display="flex" alignItems="center">
                              {console.log("alertDetails.attachement", alertDetails.attachement)}
                              {alertDetails.attachement
                                ? alertDetails.attachement.map((file, index) => {
                                  const extension_arr = file.image_name.split('.');
                                  // const extension = extension_arr[1];
                                  const temp_extension = extension_arr[1].split('"');
                                  const extension = temp_extension[0];
                                  if (
                                    extension == 'jpeg' ||
                                    extension == 'jpg' ||
                                    extension == 'png' ||
                                    extension == 'gif'
                                  ) {
                                    return (
                                      <TypoStyled variant="subtitle1" component="p" key={index}>
                                        <img
                                          src={`${file.file_name}`}
                                          className={classes.iconAttach}
                                          onClick={() => handleClickImageOpen(file.file_name)}
                                        />
                                        Image
                                      </TypoStyled>
                                    );
                                  } else if (extension == 'mp3') {
                                    return (
                                      // <TypoStyled variant='subtitle1' component='p' key={index}>
                                      //   <a style={{ textDecoration: 'none', color: 'black' }} href={`${file.attachment}`} target='_blank'>
                                      //     <Headset className={classes.iconAttach} />{docFile}
                                      //   </a>
                                      // </TypoStyled>
                                      <TypoStyled variant="subtitle1" component="p" key={index}>
                                        <a
                                          style={{
                                            textDecoration: 'none',
                                            color: 'black',
                                          }}
                                          href={`${file.attachment}`}
                                          target="_blank"
                                        >
                                          <Headset className={classes.iconAttach} />
                                          Audio
                                        </a>
                                      </TypoStyled>
                                    );
                                  } else if (extension == 'mp4') {
                                    return (
                                      // <TypoStyled variant='subtitle1' component='p' key={index}>
                                      //   <a style={{ textDecoration: 'none', color: 'black' }} href={`${file.attachment}`} target='_blank'>
                                      //     <Videocam className={classes.iconAttach} /> {docFile}
                                      //   </a>
                                      // </TypoStyled>
                                      <TypoStyled variant="subtitle1" component="p" key={index}>
                                        <a
                                          style={{
                                            textDecoration: 'none',
                                            color: 'black',
                                          }}
                                          href={`${file.attachment}`}
                                          target="_blank"
                                        >
                                          <Videocam className={classes.iconAttach} /> Video
                                        </a>
                                      </TypoStyled>
                                    );
                                  } else if (extension == 'doc' || extension == 'docx' || extension == 'pdf') {
                                    return (
                                      // <TypoStyled variant='subtitle1' component='p' key={index}>
                                      //   <a style={{ textDecoration: 'none', color: 'black' }} href={`${file.attachment}`} target='_blank'>
                                      //     <Description className={classes.iconAttach} /> {docFile}
                                      //   </a>
                                      // </TypoStyled>
                                      <TypoStyled variant="subtitle1" component="p" key={index}>
                                        <a
                                          style={{
                                            textDecoration: 'none',
                                            color: 'black',
                                          }}
                                          href={`${file.file_name}`}
                                          target="_blank"
                                        >
                                          <Description className={classes.iconAttach} />
                                          Document
                                        </a>
                                      </TypoStyled>
                                    );
                                  } else {
                                    return (
                                      <TypoStyled variant="subtitle1" component="p" key={index}>
                                        <a
                                          style={{
                                            textDecoration: 'none',
                                            color: 'black',
                                          }}
                                          href={`${file.file_name}`}
                                          target="_blank"
                                        >
                                          <Description className={classes.iconAttach} />
                                          Document
                                        </a>
                                      </TypoStyled>
                                    );
                                  }
                                })
                                : 'No Attachments'}
                            </Box>
                          }
                        />
                      </ListItem>
                    </>
                  ) : (
                    ''
                  )}
                </ListStyled>
              </>
            ) : (
              <Box className={classes.content}>
                <CardMedia className={classes.icon} image={RymindrOriginal} title="Emergency Alert" component="img" />
                <Typography className={classes.commingSoonDesc}>No record Found</Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
      {/* <Grid className="main-wrap-body msg-cont-wrap" container alignItems="stretch"></Grid> */}
      {/* IMAGE POPUP */}
      <Dialog open={openImage} onClose={handleCloseImageModal} aria-labelledby="form-dialog-title" maxWidth="xl">
        <DialogTitleStyled id="form-dialog-title">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex">
              <TypoPopHeadStyled>Attachment</TypoPopHeadStyled>
            </Box>
            <IconButton color="default" onClick={handleCloseImageModal}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitleStyled>
        <DialogContent>
          <center>
            <img
              src={`${selectedImage}`}
            // style={{ maxWidth:'-webkit-fill-available' }}
            />{' '}
          </center>
        </DialogContent>
      </Dialog>
      {/* IMAGE POPUP */}

      {/* DELETE POPUP */}
      <Grid className="main-wrap-head" container style={{ marginBottom: 15 }} alignItems="center">
        <ResponsiveDialog open={openDelete} handleClose={handleClose} deleteMsg={() => deleteMsg(alertDetails._id)} />
      </Grid>
      {/* DELETE POPUP */}
      {/* STATUS POPUP */}
      <Dialog open={open} onClose={handleCloseModal} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
        <DialogTitleStyled id="form-dialog-title">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex">
              <TypoPopHeadStyled>Emergency Alert Status</TypoPopHeadStyled>
              {contactStatusData && contactStatusData.length > 0 ? (
                <TypoPopHeadStyled style={{ color: '#607383' }} className="ml-20">
                  {contactStatusData.length} out of {contactStatusData.length} people read
                </TypoPopHeadStyled>
              ) : (
                ''
              )}
            </Box>
            <IconButton color="default" onClick={handleCloseModal}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitleStyled>
        <div style={{ marginLeft: '20px', marginRight: '20px' }}>
          <Grid item xs={12}>
            <TextField
              // error={!!formvalidation.contact}
              // helperText={formvalidation.contact}
              id="contact"
              // label="Contact"
              placeholder="Search by mobile number or name"
              name="contact"
              variant="outlined"
              fullWidth
              onChange={(e) => handleContactChange(e.target.value)}
              // value={formvalues.contact}
              margin="normal"
            />
          </Grid>
        </div>
        <DialogContent>
          <List>
            {contactStatusData &&
              contactStatusData.length > 0 &&
              contactStatusData.map((member_item, member_key) => {
                const first_name = member_item.first_name ? member_item.first_name : '';
                const last_name = member_item.last_name ? member_item.last_name : '';
                const name = first_name + ' ' + last_name;
                return (
                  (
                    <>
                      <ListItem className="pl-0 pr-0" alignItems="center">
                        <ListItemAvatar>
                          <AvatarShareStyled alt="semy Sharp" src={member_item.profile_image} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Box display="flex" justifyContent="space-between">
                              <Typography>
                                {name} <br /> {member_item.mobile_no}
                              </Typography>
                              <Box display="flex" justifyContent="center" alignItems="center">
                                {member_item.group_data ? (
                                  <>
                                    <ListItemAvatar>
                                      <AvatarShareStyled alt="semy Sharp" src={member_item.group_data.group_icon} />
                                    </ListItemAvatar>
                                    <Typography style={{ paddingTop: '0px' }}>
                                      {member_item.group_data.group_name}
                                    </Typography>{' '}
                                  </>
                                ) : null}
                              </Box>
                              {member_item.status == 1 ? (
                                <Box display="flex" justifyContent="center" alignItems="center">
                                  <FiberManualRecord
                                    style={{
                                      color: '#44b700',
                                      fontSize: 14,
                                    }}
                                  />
                                  <TypoStatusStyledRead className="ml-10 mr-20">Read</TypoStatusStyledRead>
                                </Box>
                              ) : (
                                <Box display="flex" justifyContent="center" alignItems="center">
                                  <FiberManualRecord
                                    style={{
                                      color: '#1773bf',
                                      fontSize: 14,
                                    }}
                                  />
                                  <TypoStatusStyled className="ml-10 mr-20">Unread</TypoStatusStyled>
                                </Box>
                              )}
                            </Box>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </>
                  )
                );
              })}
          </List>
        </DialogContent>
      </Dialog>
      {/* STATUS POPUP */}
    </>
  );
};

const mapStateToProps = (state) => {
  console.log(state.messageCenterReducer);
  return {
    error: state.messageCenterReducer.error,
    success_message: state.messageCenterReducer.success_message,
  };
};

export default connect(mapStateToProps)(withSnackbar(EmergencyAlert));