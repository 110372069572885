import {
  Attachment,
  CropOriginal,
  EmojiEmotions,
  DoneAll,
  GroupAdd,
  MoreVert,
  Search,
  ArrowBack,
  PersonAdd,
} from '@material-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Typography,
  Hidden,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { makeStyles, styled } from '@material-ui/core/styles';
import CircularSpinner from '../../component/CircularSpinner';
import React, { useState, useEffect, useMemo } from 'react';
import GroupDialog from './Modal';
import { connect, useSelector } from 'react-redux';
import { getUserAndGroupList, createGroup, initChat } from '../../store/actions/LiveChatActions';
import { withSnackbar } from 'notistack';
import clsx from 'clsx';
import ChatWindow from './ChatWindow';
import { useParams, Link } from 'react-router-dom';
import { getUpcommingRymindrs } from "../../store/actions/rymidr"
// For firebase chat purpose
import firebase from 'firebase';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    position: 'relative',
  },
  rightPanel: {
    [theme.breakpoints.up('md')]: {
      display: 'block !important',
    },
  },
  newMessage: {
    backgroundColor: 'orange',
    borderRadius: 20,
    padding: 2,
    paddingHorizontal: 6,
    color: 'white',
  },
}));

const AvatarShareStyled = styled(Avatar)({
  height: 50,
  width: 50,
  marginRight: 20,
});

const AvatarChatStyled = styled(Avatar)({
  height: 25,
  width: 25,
  marginRight: 10,
  marginTop: 24,
});

const AvatarChatRightStyled = styled(AvatarChatStyled)({
  marginRight: 0,
  marginLeft: 10,
});

const TypoHeadStyled = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
  fontWeight: 'bold',
}));

const TypoHeadInnerStyled = styled(TypoHeadStyled)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const SearchFieldStyled = styled(TextField)(() => ({
  padding: 20,
}));

const PaperStyled = styled(Paper)(() => ({
  paddingRight: 30,
  paddingLeft: 30,
  height: 100 + '%',
}));

const GridHeaderStyled = styled(Grid)(() => ({
  borderBottom: '1px solid rgba(64, 87, 106, 0.1)',
}));

const GridFooterStyled = styled(Grid)(() => ({
  borderTop: '1px solid rgba(64, 87, 106, 0.1)',
  paddingTop: 10,
  paddingBottom: 10,
}));
const TypoNameStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 18,
  fontWeight: 'bold',
}));
const TypoStatusStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontSize: 14,
}));

const ChatBoxStyled = styled(Box)({
  background: '#F5F5F5',
  padding: 15,
  borderRadius: 20,
  borderTopLeftRadius: 0,
  display: 'inline-block',
  marginTop: 5,
});

const ChatBoxRightStyled = styled(ChatBoxStyled)({
  background: '#D1EDFE',
  borderRadius: 20,
  borderTopRightRadius: 0,
});

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  padding: 20,
  paddingBottom: 0,
}));

const ButtonColor = styled(Button)(({ theme }) => ({
  background: theme.palette.props.main,
  color: theme.palette.props.contrastText,
}));

const DoneAllStyled = styled(DoneAll)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 22,
  float: 'right',
  marginLeft: 10,
  '&.active': {
    color: theme.palette.primary.main,
  },
}));

const BoxDateStyled = styled(Box)(({ theme }) => ({
  display: 'block',
  margin: 'auto',
  width: 130,
  background: 'rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
  borderRadius: 15,
  padding: '5px 10px',
  '& p': {
    color: '#212121',
    fontSize: 12,
    fontWeight: '500',
  },
}));

function LiveChat(props) {
  const classes = useStyles();
  const [is_accessible, setAccessable] = useState(false);
  const filterOptions = [
    {
      value: 'all',
      label: 'All Contacts',
    },
    {
      value: 'contact',
      label: 'Contact',
    },
  ];
  const [filters, setFilter] = React.useState('all');

  const { contacts, enqueueSnackbar, error, success, loading, sidebar, dashupcomingrymindr, upcommingrymindr } = props;
  const { user_id, business_code } = JSON.parse(localStorage.getItem('userData'));
  let { chat_id, chat_type } = useParams();
  const [initChatDetails, updateInitChatDetails] = useState({});
  const [contactList, updateContactList] = useState([]);
  const [contactsData, setContactData] = useState([]);
  const [msgNotifications, updateMsgNotifications] = useState({});
  const [msgListen, setMsgListen] = useState(false);
  const [isLargeViewport, setIsLargeViewport] = useState(false);
  const [rymindrsCount, setRymindrCount] = useState(0)

  console.log("initChatDetails", initChatDetails)
  useEffect(() => {
    setRymindrCount(upcommingrymindr.count);
  }, [upcommingrymindr])

  useEffect(() => {
    const dataToSend = {
      user_id,
    };
    getUpcommingRymindrs(dataToSend);
    console.log('dataToSend', dataToSend);
  }, []);

  useEffect(() => {
    const dataTosend = {
      user_id,
      business_code,
    };
    const datasend = {
      user_id,
    };
    props.getUpcommingRymindrs(datasend);
    props.getUserAndGroupList(dataTosend);
  }, []);
  useEffect(() => {
    if (contacts && contacts.length > 0) {
      updateContactList(contacts);
      // setContactData(contacts);
      // handleShowChatWindow(contacts[0], 'individual');
    }
  }, [contacts]);

  const handleShowChatWindow = (chatParticipant, chatType) => {
    // updateInitChatDetails({});
    const dataTosend = {
      userId: user_id,
      chatType: chatType,
      chatParticipant: chatParticipant,
    };

    props.initChat(dataTosend).then(() => {
      updateInitChatDetails({
        chatParticipant: chatParticipant,
        chatType: chatType,
      });
    });
  };

  var messageRef = firebase.database().ref();

  useEffect(() => {
    listenMessages();
    return () => {
      messageRef.off();
    };
  }, [contactList]);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeViewport(window.innerWidth > 950 && window.innerHeight > 1024);
    };

    handleResize(); // Initial check

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const listenMessages = () => {
    messageRef.on('value', function (snapshot) {
      const snapshots = snapshot.val();
      let unreadMessages = {};
      var newarr = [];
      if (snapshots !== null) {
        for (const chatRoom in snapshots) {
          const chatRoomDetails = chatRoom.split('_');
          if (chatRoomDetails[1] === 'individual') {
            const msgNodes = Object.values(snapshots[chatRoom]);
            if (msgNodes.length > 0) {
              let unreadMessageCount = 0;
              if (
                msgNodes[msgNodes.length - 1].sender_id == user_id ||
                msgNodes[msgNodes.length - 1].receiver_id == user_id
              ) {
                newarr.push(msgNodes[msgNodes.length - 1]);
              }
              for (const nodeId in msgNodes) {
                if (msgNodes[nodeId].receiver_id === user_id && msgNodes[nodeId]['message_read_' + user_id] === false) {
                  unreadMessageCount++;

                  unreadMessages = {
                    ...unreadMessages,
                    ...{ [msgNodes[nodeId].sender_id]: unreadMessageCount },
                  };
                } else {
                  delete unreadMessages[msgNodes[nodeId].sender_id];
                }
              }
            }
            updateMsgNotifications(unreadMessages);
          }
        }
        var obj = contactList;

        obj.forEach((ele) => {
          let index = newarr.findIndex((dd) => ele.user_id == dd.sender_id || ele.user_id == dd.receiver_id);
          if (index > -1) {
            let msgCreatedAt = convertTZ(new Date(parseInt(newarr[index].created_at)), 'Europe/London');
            let getOnlyTime = new Date(msgCreatedAt);
            ele.created_at = moment(newarr[index].created_at).utcOffset(60).fromNow();
            ele.timestamp = newarr[index].created_at;
          } else {
            ele.created_at = '';
            ele.timestamp = '';
          }
        });
        obj.sort((a, b) => b.timestamp - a.timestamp);
        setContactData(obj);
        console.log(msgListen, 'msgListen');
        // if (contactsData.length == 0 && obj.length > 0 && msgListen == false) {
        //   setMsgListen(true);
        //   handleShowChatWindow(obj[0], 'individual');
        // }
        // if (obj.length > 0) {
        //   handleShowChatWindow(obj[0], 'individual');
        // }
      }
    });
  };

  useEffect(() => {
    var obj = [...contactList];
    console.log('contactsData testtt ****)', obj, contactsData);
    if (contactsData.length > 0 && obj.length > 0 && msgListen == false) {
      setMsgListen(true);
      handleShowChatWindow(contactsData[0], 'individual');
    }
  }, [contactsData, contactList]);

  function convertTZ(date, tzString) {
    return new Date(
      (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
        timeZone: tzString,
      })
    );
  }

  const handleFilterChange = (event) => {
    const filter = event.target.value;
    setFilter(filter);

    let list = [...contacts];
    if (filter === 'contact') {
      const users = list.filter((user) => user.contact_type === 'individual');
      updateContactList(users);
    } else if (filter === 'groups') {
      const groups = list.filter((user) => user.contact_type === 'group');
      updateContactList(groups);
    } else {
      updateContactList(list);
    }
  };

  const handleOnChange = (event) => {
    const searchStr = event.target.value;

    let tempStr = searchStr.charAt(0) === '0' ? searchStr.slice(1) : searchStr;

    let list = [...contacts];

    if (tempStr == '') {
      //console.log("list====", list);
      updateContactList(list);
    } else {
      // Filter them
      if (filters === 'contact') {
        const users = list.filter((user) => user.contact_type === 'individual');
        let filteredList = null;

        if (isNaN(tempStr)) {
          //filteredList = users.filter(obj => (obj.first_name.toLowerCase().includes(tempStr.toLowerCase())) || (obj.last_name.toLowerCase().includes(tempStr.toLowerCase())))
          let splitedName = tempStr.split(' ');
          if (splitedName.length > 1) {
            filteredList = users.filter(
              (obj) =>
                (obj.first_name.toLowerCase().includes(splitedName[0].toLowerCase()) &&
                  obj.last_name.toLowerCase().includes(splitedName[1].toLowerCase())) ||
                (obj.first_name.toLowerCase().includes(splitedName[1].toLowerCase()) &&
                  obj.last_name.toLowerCase().includes(splitedName[0].toLowerCase()))
            );
          } else {
            filteredList = users.filter(
              (obj) =>
                obj.first_name.toLowerCase().includes(tempStr.toLowerCase()) ||
                obj.last_name.toLowerCase().includes(tempStr.toLowerCase())
            );
          }
        } else {
          filteredList = users.filter((obj) => obj.mobile_no.includes(tempStr));
        }

        updateContactList(filteredList);
      } else if (filters === 'groups') {
        const groups = list.filter((user) => user.contact_type === 'group');
        const filteredList = groups.filter((obj) => obj.group_name.toLowerCase().includes(tempStr.toLowerCase()));

        updateContactList(filteredList);
      } else {
        const users = list.filter((user) => user.contact_type === 'individual');
        let filteredUserList = null;
        if (isNaN(tempStr)) {
          let splitedName = tempStr.split(' ');
          if (splitedName.length > 1) {
            if (splitedName.length > 2) {
              filteredUserList = users.filter(
                (obj) =>
                  (obj.first_name.toLowerCase().includes(splitedName[0].toLowerCase()) &&
                    obj.last_name.toLowerCase().includes(splitedName[2].toLowerCase())) ||
                  (obj.first_name.toLowerCase().includes(splitedName[2].toLowerCase()) &&
                    obj.last_name.toLowerCase().includes(splitedName[0].toLowerCase()))
              );
            } else {
              filteredUserList = users.filter(
                (obj) =>
                  (obj.first_name.toLowerCase().includes(splitedName[0].toLowerCase()) &&
                    obj.last_name.toLowerCase().includes(splitedName[1].toLowerCase())) ||
                  (obj.first_name.toLowerCase().includes(splitedName[1].toLowerCase()) &&
                    obj.last_name.toLowerCase().includes(splitedName[0].toLowerCase())) ||
                  (obj.first_name.toLowerCase().includes(splitedName[0].toLowerCase()) &&
                    obj.first_name.toLowerCase().includes(splitedName[1].toLowerCase()))
              );
            }
          } else {
            filteredUserList = users.filter(
              (obj) =>
                obj.first_name.toLowerCase().includes(tempStr.toLowerCase()) ||
                obj.last_name.toLowerCase().includes(tempStr.toLowerCase())
            );
          }
        } else {
          filteredUserList = users.filter((obj) => obj.mobile_no.includes(tempStr));
        }

        let groups = list.filter((user) => user.contact_type === 'group');

        const filteredGroupList = groups.filter((obj) => obj.group_name.toLowerCase().includes(tempStr.toLowerCase()));

        updateContactList([...filteredUserList], [...filteredGroupList]);
      }
    }
  };
  const { account, } = useSelector((state) => state);
  const subscribe = account?.userdetails?.is_subscribe;
  console.log("data here", rymindrsCount, subscribe)

  return is_accessible ? (
    <></>
  ) : (
    <>
      {/* <GroupDialog open={open} onClose={handleClose} handleSaveGroupDetails={handleSaveGroupDetails} /> */}

      <Grid container className="main-wrap-head" style={{ marginBottom: 20 }} alignItems="center">
        <Grid item xs={7}>
          <Box display="flex" alignItems="center">
            {/* {showPanel ? null : (
              <Hidden mdUp implementation="css">
                <IconButton color="inherit" 
                onClick={handleHideDetail}
                >
                  <ArrowBack />
                </IconButton>
              </Hidden>
            )} */}

            <TypoHeadStyled variant="h4">
              Live <TypoHeadInnerStyled component="span">Chat</TypoHeadInnerStyled>
            </TypoHeadStyled>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box display="flex" justifyContent="flex-end">
            {subscribe == 0 && rymindrsCount > 4 ?
              <>
                <Link
                  to={
                    "/live-chat"
                  }
                >
                  <Button variant="contained" color="primary" size="large"
                    disabled={subscribe == 0 && rymindrsCount > 4 ? true : false}
                    className="mr-10" startIcon={<PersonAdd />}>
                    Add new rymindr
                  </Button>
                </Link>
              </>
              :
              <>
                <Link
                  to={
                    initChatDetails.chatType == 'individual'
                      ? '/create-rymindr/0/' +
                      initChatDetails.chatParticipant.user_id +
                      '/' +
                      initChatDetails.chatParticipant.first_name +
                      ' ' +
                      initChatDetails.chatParticipant.last_name
                      : initChatDetails.chatType == 'group'
                        ? '/create-rymindr/' +
                        initChatDetails.chatParticipant.group_id +
                        '/0/' +
                        initChatDetails.chatParticipant.group_name
                        // : ''
                        : <></>
                  }
                >
                  <Button variant="contained" color="primary" size="large"
                    disabled={subscribe == 0 && rymindrsCount > 4 ? true : false}
                    className="mr-10" startIcon={<PersonAdd />}>
                    Add new rymindr
                  </Button>
                </Link>
              </>
            }
          </Box>
        </Grid>
      </Grid>

      <Grid className="main-wrap-body liveChat-cont-wrap" container alignItems="stretch" style={{ height: '100vh' }}>
        <Grid item xs={12} md={4} className={'leftSide-cont pr-25'}>
          <Paper className="chat-list-box">
            <TextFieldStyled
              select
              value={filters}
              fullWidth
              size="small"
              variant="outlined"
              autoComplete="off"
              onChange={handleFilterChange}
            >
              {filterOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextFieldStyled>

            <SearchFieldStyled
              id="input-with-icon-textfield"
              variant="outlined"
              fullWidth
              size="small"
              placeholder="Search by name or mobile number"
              onKeyUp={handleOnChange}
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />

            <List className="chat-user-list">
              {contactsData && contactsData.length > 0 ? (
                contactsData.map((contact, contactIndex) =>
                  contact.contact_type === 'individual' && contact.user_id != undefined ? (
                    <React.Fragment key={contactIndex}>
                      <ListItem
                        button
                        selected={
                          initChatDetails.chatParticipant !== undefined &&
                          initChatDetails.chatParticipant.user_id == contact.user_id
                        }
                        onClick={() => handleShowChatWindow(contact, 'individual')}
                      >
                        <ListItemAvatar>
                          <AvatarShareStyled alt="profile picture" src={contact.profile_image} />
                        </ListItemAvatar>
                        <div style={{ marginLeft: 7 }}>
                          <ListItemText primary={contact.first_name + ' ' + contact.last_name} />
                          <ListItemText primary={contact.mobile_no} />
                        </div>
                        <ListItemText primary={contact.created_at} style={{ textAlign: 'right' }} />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </React.Fragment>
                  ) : null
                )
              ) : (
                <Typography className={classes.commingSoonDesc} style={{ textAlign: 'center' }}>
                  No records Found
                </Typography>
              )}
            </List>
          </Paper>
        </Grid>
        {/* <div style={{width: "97%"}} > */}
        {/* <Grid item md={4} className="ms-1"> */}
        <ChatWindow initChatDetail={initChatDetails} loading={loading} />
        {/* </Grid> */}
        {/* </div> */}
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.liveChatReducer.loading,
    error: state.liveChatReducer.error,
    success: state.liveChatReducer.success,
    contacts: state.liveChatReducer.contacts,
    sidebar: state.sidebar.ryminderlist,
    dashupcomingrymindr: state.dashboard,
    upcommingrymindr: state.rymidr.upcommingrymindr,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserAndGroupList: (data) => dispatch(getUserAndGroupList(data)),
    createGroup: (data) => dispatch(createGroup(data)),
    initChat: (data) => dispatch(initChat(data)),
    getUpcommingRymindrs: (data) => dispatch(getUpcommingRymindrs(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(LiveChat));