import * as actionTypes from "../actions/actionTypes";
const initialState = {
  loading: false,
  error: null,
  sucess: null,
  success_message: null,
  showOtpform: false,
  activeDelete: true,
  userdetails: {},
  userrolldetails: {},
  searcherr: null,
  permissionDeteils: {},
  userRollDelete: false,
  permissionUpdate: false,
  paymentDetails: {},
  updatepaymentDetails: {},
  paymentData: {},
  PaymentMode: null,
};

const accountsettingReducer = (state = initialState, action) => {
  console.log(action.payload, "payload data");
  switch (action.type) {
    case actionTypes.ACISLOADING:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case actionTypes.ACERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case actionTypes.MAKEDEFAULTCATEGORY:
      return {
        ...state,
        error: null,
        loading: false,
      };

    case actionTypes.CHANGEPASSWORD:
      return {
        ...state,
        error: null,
        loading: false,
      };

    case actionTypes.UPDATEPROFILE:
      return {
        ...state,
        error: null,
        sucess: "Profile Updated Sucessfully",
        loading: false,
      };
    case actionTypes.CHANGENOTIFICATIONSETTING:
      return {
        ...state,
        error: null,
        sucess: "Notification updated sucessfully",
        loading: false,
      };

    case actionTypes.DELETEUSERACCOUNT:
      return {
        ...state,
        error: null,
        loading: false,
        activeDelete: true,
        showOtpform: false,
      };

    case actionTypes.SENDOTP:
      return {
        ...state,
        error: null,
        showOtpform: true,
        loading: false,
      };

    case actionTypes.VERIFYOTP:
      return {
        ...state,
        error: null,
        activeDelete: false,
        loading: false,
      };

    case actionTypes.RESETDATA:
      return {
        ...state,
        error: null,
        loading: false,
        activeDelete: true,
        showOtpform: false,
      };
    case actionTypes.GETUSERDETAILS:
      return {
        ...state,
        error: null,
        loading: false,
        userdetails: action.payload,
      };
    case actionTypes.GETUSERROLLDETAILS:
      return {
        ...state,
        error: null,
        loading: false,
        userrolldetails: action.payload,
      };
    case actionTypes.GETPAYMENTKEYS:
      return {
        ...state,
        error: null,
        loading: false,
        paymentData: action.payload,
      };
    case actionTypes.UPDATEPAYMENTMODE:
      return {
        ...state,
        error: null,
        loading: false,
        updatepaymentDetails: action.payload,
      };
    case actionTypes.PERMISSIONMENU:
      return {
        ...state,
        error: null,
        loading: false,
        permissionDeteils: action.payload,
      };
    case actionTypes.SEARCHUSERS:
      return {
        ...state,
        // error: action.payload.message,
        searcherr: action.payload.message,
        loading: false,
        searchuserdetails: action.payload.data,
      };

    case actionTypes.INVITEUSERS:
      return {
        ...state,
        error: null,
        message: action.payload,
        loading: false,
      };

    case actionTypes.SETPERMISSION:
      return {
        ...state,
        error: null,
        message: action.payload,
        permissionUpdate: true,
        loading: false,
      };
    case actionTypes.DELETEUSERROLL:
      return {
        ...state,
        error: null,
        loading: false,
        userRollDelete: true,
      };
    case actionTypes.PAYMENTSETTINGS:
      return {
        ...state,
        error: null,
        message: action.payload,
        loading: false,
      };
    case actionTypes.GENRATEQRCODE:
      return {
        ...state,
        error: null,
        loading: false,
        // userdetails: action.payload,
      };
    case actionTypes.SUCCESS_MESSAGE_ACCOUNT_SETTING:
      return {
        ...state,
        success_message: action.success_message,
        loading: false,
      };
    default:
      return state;
  }
};
export default accountsettingReducer;
