import React, { useState, useMemo, useEffect } from "react";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Box from "@mui/material/Box";
import CircularSpinner from "../../component/CircularSpinner";
import ReactSwitch from "react-switch";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import {
  Fail,
  paymentSettings,
  getPaymetKeys,
  updatePaymentMode,
} from "../../store/actions/accountsettingAction";
import { useDispatch, useSelector } from "react-redux";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const PaymentSetting = (props) => {
  const {
    onClose,
    value: valueProp,
    getSelectedRymindr,
    enqueueSnackbar,
    success_message,
    open,
    userdetails,
    getPaymetKeys,
    ...other
  } = props;

  const FormFields = {
    public_key: "",
    secret_key: "",
    showSecretKey: false,
    showPublickey: false,
  };
  const LiveFormFields = { live_public_key: "", live_secret_key: "" };

  const [value, setValue] = useState("1");
  const [checked, setChecked] = useState(false);
  const [paymentData, setPaymetData] = useState([]);
  const [formvalidation, setFormvalidation] = useState(FormFields);
  const [formvalues, setFormvalues] = useState(FormFields);
  const [liveFormvalues, setLiveFormvalues] = useState(LiveFormFields);
  const [liveFormvalidation, setLiveFormvalidation] = useState(LiveFormFields);
  const [key, setKeys] = useState({
    public_key: "",
    secret_key: "",
  });
  const [Livekey, setLiveKeys] = useState({
    live_public_key: "",
    live_secret_key: "",
  });
  const { user_id } = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const paymentKeys = useSelector((state) => state?.account?.paymentData.data);
  const paymentModeIs = useSelector(
    (state) => state?.account?.userdetails?.payment_mode
  );
  const formChange = (e) => {
    const form = { ...formvalues };
    const formerr = { ...formvalidation };
    form[e.target.name] = e.target.value;
    // formerr[e.target.name] = '';
    setFormvalues(form);
    setFormvalidation(FormFields);
    setKeys(key);
  };
  const liveFormChange = (e) => {
    const form = { ...liveFormvalues };
    const formerr = { ...formvalidation };
    form[e.target.name] = e.target.value;
    formerr[e.target.name] = "";
    setLiveFormvalues(form);
    setLiveFormvalidation(LiveFormFields);
    setLiveKeys(Livekey);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 1 && paymentData !== null) {
      let formData = { ...formvalues };
      formData.public_key = paymentData[0]?.pub_key;
      formData.secret_key = paymentData[0]?.sec_key;
      setFormvalues(formData);
    }
    if (newValue == 2 && paymentData !== null) {
      let LiveFormFields = { ...liveFormvalues };
      LiveFormFields.live_public_key = paymentData[1]?.pub_key;
      LiveFormFields.live_secret_key = paymentData[1]?.sec_key;
      setLiveFormvalues(LiveFormFields);
    }
  };
  const toggleChange = (val) => {
    if (!checked) {
      dispatch(updatePaymentMode({ user_id: user_id, payment_mode: "live" }));
    }
    setChecked(val);
  };

  useEffect(() => {
    if (paymentModeIs === "live") {
      setChecked(true);
    }
  }, [paymentModeIs]);
  useMemo(() => {
    success_message && enqueueSnackbar(success_message, { variant: "success" });
  }, [success_message]);

  useEffect(() => {
    props.getPaymetKeys({ user_id });
  }, []);

  useEffect(() => {
    if (user_id && paymentKeys) {
      setPaymetData(paymentKeys);
    }
  }, [user_id, paymentKeys]);

  useEffect(() => {
    setTimeout(() => {
      if (value == 1 && paymentData !== null) {
        let formData = { ...formvalues };
        formData.public_key = paymentData[0]?.pub_key;
        formData.secret_key = paymentData[0]?.sec_key;
        setFormvalues(formData);
      }
    }, 500);
  }, [paymentData, value]);

  const [values, setValues] = useState({
    public_key: "",
    secret_key: "",
    show_public_key: false,
    show_secret_key: false,
  });
  const [liveValues, setLiveValues] = useState({
    live_public_key: "",
    live_secret_key: "",
    show_live_public_key: false,
    show_live_secret_key: false,
  });
  const handlevalidation = () => {
    let error = false;
    const formerr = { ...formvalidation };
    if (!formvalues.public_key) {
      error = true;
      formerr.public_key = "Public key is required!";
      setFormvalidation(formerr);
    }
    if (!formvalues.secret_key) {
      error = true;
      formerr.secret_key = "Secret key is required!";
      setFormvalidation(formerr);
    }
    return error;
  };

  const handleLiveValidation = () => {
    let error = false;
    const formerr = { ...liveFormvalidation };
    if (!liveFormvalues.live_public_key) {
      error = true;
      formerr.live_public_key = "Public key is required!";
      setLiveFormvalidation(formerr);
    }
    if (!liveFormvalues.live_secret_key) {
      error = true;
      formerr.live_secret_key = "Secret key is required!";
      setLiveFormvalidation(formerr);
    }
    return error;
  };

  // const { user_id } = JSON.parse(localStorage.getItem('userData'));

  const paymentSubmitHandler = (data) => {
    // e.preventDefault();
    if (handlevalidation()) return false;
    props.payment(data);
    setFormvalues({
      public_key: "",
      secret_key: "",
    });
    dispatch(getPaymetKeys({ user_id }));
  };

  const paymentSubmitHandler2 = (data) => {
    // e.preventDefault();
    if (handleLiveValidation()) return false;
    props.payment(data);
    setLiveFormvalues({
      live_public_key: "",
      live_secret_key: "",
    });
  };
  // for test api key
  const handleClickShowPassword = () => {
    setValues({ ...values, show_public_key: !values.show_public_key });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowSecret = () => {
    setValues({ ...values, show_secret_key: !values.show_secret_key });
  };
  const handleMouseDownPasswordSecret = (event) => {
    event.preventDefault();
  };
  // for live api key
  const handleClickShowLivePassword = () => {
    setLiveValues({
      ...liveValues,
      show_live_public_key: !liveValues.show_live_public_key,
    });
  };

  const handleMouseDowLivePassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowLiveSecret = () => {
    setLiveValues({
      ...liveValues,
      show_live_secret_key: !liveValues.show_live_secret_key,
    });
  };
  const handleMouseDownLivePasswordSecret = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div>
        <div
          style={{
            backgroundColor: "rgb(210, 238, 255)",
            paddingLeft: "31px",
            paddingRight: "31px",
            paddingTop: "31px",
            paddingBottom: "10px",
            borderRadius: "16px",
          }}
        >
          <div
            style={{
              fontSize: "18px",
              fontWeight: "600",
              color: "rgb(64, 87, 106)",
            }}
          >
            Important Note:{" "}
          </div>
          <p
            style={{
              paddingTop: "10px",
              fontSize: "17px",
              color: "rgb(72, 72, 72)",
            }}
          >
            To enable the payments feature you will need to register for a
            Stripe account. This is free and is straight forward to set up.
            Stripe Payment is a service offered by Stripe that enables
            businesses and organisations to accept payments online securely and
            easily. With Stripe, you can accept payments using a variety of
            payment methods, including credit and debit cards, Apple Pay, Google
            Pay, and more. <br />
            <br />
            More power directly in your hands. Stripe Payment also offers
            features such as fraud detection, invoicing, and reporting, making
            it a comprehensive payment processing solution. Trusted by millions
            worldwide and is known for its reliability, security, and ease of
            use. As a certified PCI Service Provider Level 1, Stripe meets the
            highest level of certification available in the payments industry.
            For more information visit
            <a target="_blank" href="https://stripe.com/in">
              {" "}
              www.stripe.com{" "}
            </a>
            <br />
            <br />
            Note: Stripe payment processing fees{" "}
            <a target="_blank" href="https://stripe.com/gb/pricing">
              https://stripe.com/gb/pricing
            </a>
          </p>
          <p
            style={{
              paddingTop: "10px",
              fontSize: "18px",
              fontWeight: "600",
              color: "rgb(64, 87, 106)",
            }}
          >
            To create a Stripe account for your school, follow these steps:
          </p>
          <ul style={{ fontSize: "17px", color: "rgb(64, 87, 106)" }}>
            <li>
              1. If you don't have a Stripe account for your school, go to{" "}
              <a target="_blank" href="https://stripe.com/in">
                www.stripe.com{" "}
              </a>
              and create one for free. If you already have an account, log in to
              your school's Stripe account at the same website address. Visit
              this video link to watch a step by step video tutorial on how to
              do this{" "}
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=o3JKJlsGvFw "
              >
                https://www.youtube.com/watch?v=o3JKJlsGvFw{" "}
              </a>
            </li>
            <li>
              2. After creating your account, verify your school's account.
              You'll be directed to a section of settings where you can access
              your API Keys. These can be found on your 'Dashboard' when you log
              in to{" "}
              <a target="_blank" href="https://stripe.com/in">
                www.stripe.com .
              </a>
              Stripe offers two types of API Keys: Test and Live API Keys. The
              keys will need to be saved into the fields below. Watch this video
              of how to locate your Stripe test and live keys.
            </li>
            <li>
              3. Once you have saved both sets of keys, you're all set. To test
              your account has been set up correctly, switch test mode on and
              use any of the following test credit card numbers in the UK:
              <br />
              <br />
              <p>
                4000 0082 6000 0000 <br /> 4000 0582 6000 0005
              </p>
              and then any expiry date in the future, any CVC number and a
              correct postcode.
            </li>
          </ul>
        </div>

        <img
          alt=""
          src="../assets/images/stripe-logo.svg"
          style={{ width: "210px", height: "90px" }}
        />

        <Box sx={{ typography: "body1" }}>
          <TabContext value={value}>
            <div>
              <div style={{ display: "flex" }}>
                <Box>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    style={{ paddingLeft: "13px" }}
                  >
                    <Tab
                      label="TEST API KEYS"
                      value="1"
                      style={{ fontSize: "20px" }}
                    />
                    <Tab
                      label="LIVE API KEYS"
                      value="2"
                      style={{ fontSize: "20px" }}
                    />
                  </TabList>
                </Box>
              </div>
              <div>
                <form
                  validate="true"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <TabPanel value="1">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "right",
                        marginTop: "-60px",
                        paddingBottom: "20px",
                      }}
                    >
                      <div
                        className="app"
                        style={{ textAlign: "right", paddingRight: "10px" }}
                      >
                        {" "}
                        {paymentModeIs == "live" ? (
                          // <div>sjdgfjksdhfjkdsh</div>
                          <ReactSwitch
                            checked={checked}
                            onChange={toggleChange}
                          />
                        ) : (
                          <ReactSwitch
                            checked={checked}
                            onChange={toggleChange}
                          />
                        )}
                      </div>
                      <div>
                        <h4>Test Mode</h4>
                      </div>
                    </div>

                    <InputLabel htmlFor="outlined-adornment">
                      Publication Key
                    </InputLabel>
                    {/* <FormControl variant="outlined" fullWidth margin="normal" style={{paddingBottom: "10px"}}> */}
                    <div style={{ paddingBottom: "20px" }}>
                      <OutlinedInput
                        id="public_key"
                        type={values.show_public_key ? "text" : "password"}
                        // label="publication key"
                        placeholder="publication key"
                        name="public_key"
                        variant="outlined"
                        fullWidth
                        onChange={formChange}
                        margin="normal"
                        error={!!formvalidation.public_key}
                        helperText={formvalidation.public_key}
                        value={formvalues.public_key}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.show_public_key ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </div>

                    {/* </FormControl> */}
                    <InputLabel htmlFor="outlined-adornment">
                      Secret Key
                    </InputLabel>
                    <OutlinedInput
                      type={values.show_secret_key ? "text" : "password"}
                      id="secret_key"
                      // label="Secret Key"
                      placeholder="Secret Key"
                      name="secret_key"
                      variant="outlined"
                      fullWidth
                      onChange={formChange}
                      margin="normal"
                      error={!!formvalidation.secret_key}
                      helperText={formvalidation.secret_key}
                      value={formvalues.secret_key}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowSecret}
                            onMouseDown={handleMouseDownPasswordSecret}
                            edge="end"
                          >
                            {values.show_secret_key ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <input
                      type="hidden"
                      id="custId"
                      name="custId"
                      value="test"
                    />
                    <Box style={{ paddingTop: "20px" }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ width: "210px" }}
                        onClick={() =>
                          paymentSubmitHandler({
                            user_id: user_id,
                            public_key: formvalues.public_key,
                            secret_key: formvalues.secret_key,
                            type: "test",
                          })
                        }
                      >
                        {/* { props.loading && <CircularSpinner />} */}
                        Save
                      </Button>
                    </Box>
                  </TabPanel>
                </form>
                <form
                  validate="true"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <TabPanel value="2" style={{ paddingBottom: "12px" }}>
                    <div style={{ paddingBottom: "20px" }}>
                      <InputLabel htmlFor="outlined-adornment">
                        Publication Key
                      </InputLabel>
                      <div style={{ paddingBottom: "20px" }}>
                        <OutlinedInput
                          id="live_public_key"
                          type={
                            liveValues.show_live_public_key
                              ? "text"
                              : "password"
                          }
                          // label="publication key"
                          placeholder="publication key"
                          name="live_public_key"
                          variant="outlined"
                          fullWidth
                          onChange={liveFormChange}
                          margin="normal"
                          error={!!liveFormvalidation.live_public_key}
                          helperText={liveFormvalidation.live_public_key}
                          value={liveFormvalues.live_public_key}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowLivePassword}
                                onMouseDown={handleMouseDowLivePassword}
                                edge="end"
                              >
                                {liveValues.show_live_public_key ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </div>
                      <InputLabel htmlFor="outlined-adornment">
                        Secret Key
                      </InputLabel>
                      <div style={{ paddingBottom: "20px" }}>
                        <OutlinedInput
                          type={
                            liveValues.show_live_secret_key
                              ? "text"
                              : "password"
                          }
                          id="live_secret_key"
                          // label="Secret Key"
                          placeholder="Secret Key"
                          name="live_secret_key"
                          variant="outlined"
                          fullWidth
                          onChange={liveFormChange}
                          margin="normal"
                          error={!!liveFormvalidation.live_secret_key}
                          helperText={liveFormvalidation.live_secret_key}
                          value={liveFormvalues.live_secret_key}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowLiveSecret}
                                onMouseDown={handleMouseDownLivePasswordSecret}
                                edge="end"
                              >
                                {liveValues.show_live_secret_key ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <input
                          type="hidden"
                          id="custId"
                          name="custId"
                          value="3487"
                        />
                      </div>

                      <Box>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                          style={{ width: "210px" }}
                          onClick={() =>
                            paymentSubmitHandler2({
                              user_id: user_id,
                              public_key: liveFormvalues.live_public_key,
                              secret_key: liveFormvalues.live_secret_key,
                              type: "live",
                            })
                          }
                        >
                          {/* {<CircularSpinner />} */}
                          Save
                        </Button>
                      </Box>
                    </div>
                  </TabPanel>
                </form>
              </div>
            </div>
          </TabContext>
        </Box>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    success_message: state.account.success_message,
    paymentData: state.account.paymentData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    payment: (public_key, secret_key) =>
      dispatch(paymentSettings(public_key, secret_key)),
    getPaymetKeys: (data) => dispatch(getPaymetKeys(data)),
    updatePaymentMode: (data) => dispatch(updatePaymentMode(data)),
    Fail: (err) => dispatch(Fail(err)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(PaymentSetting));
