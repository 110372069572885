import {
  Visibility,
  Replay,
  Send,
  AddCircle,
  Cancel,
  Headset,
  Videocam,
  Image,
  Description,
  Attachment,
} from '@material-ui/icons';
import { useMediaQuery } from '@mui/material';
import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
} from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Cancel';
import { useDispatch, useSelector } from 'react-redux';

import { Link, useHistory, match, location, useParams } from 'react-router-dom';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import React, { useEffect, useMemo, useState, useRef } from 'react';

import {
  createEventbook,
  updateEventbook,
  getBusinessUsers,
  getGroups,
  getCategory,
} from '../../store/actions/bookevent';

import {
  getContactlist,
  getGroupMemberList,
  groupDelete,
  contactDelete,
  updateGroup,
  getRyminderList,
  addToRymindr,
  InviteUser,
  CancelInvititionRequest,
  checkGroupExistInActiveRymindrs,
  getGroupList,
  addToEvent,
  addContact,
  inviteGroup,
  getPendingList,
} from '../../store/actions/contactActions';

import {
  // createRymindr,
  // getBusinessUsers,
  // getCategories,
  // getGroups,
  // getSubCategories,
  inviteAndAddMobile,
} from '../../store/actions/rymidr';
//   Import all require Actions Here....
import { createAlert, getAlertTemplateDetails, getTemplateList } from '../../store/actions/messageCenterAction';

import { makeStyles, styled } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularSpinner from '../../component/CircularSpinner/index';
// import ConfirmationDialogRaw from './Modal'
// import DialogRaw from './Modal/Rymindrindex';
import MomentUtils from '@date-io/moment';
import PropTypes from 'prop-types';
// import Tabmenu from './Modal/Memberlist'
import { connect } from 'react-redux';
import moment from 'moment';
import { withSnackbar } from 'notistack';
// Import CK Editor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import ckEditorConfig from '../../helper/ckEditorConfig';
// Confirmation Dialog Box
import ConfirmationDialogRaw from '../Bookings/EventBooking/Modal';
import Tabmenu from '../Bookings/EventBooking/Modal/Memberlist';
import { usersContactList } from '../../store/actions/contactActions';

//  *********** UI Styles Start *****************

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    position: 'relative',
  },
  iconAttach: {
    color: theme.palette.primary.dark,
    background: theme.palette.primary.light,
    height: 32,
    width: 32,
    borderRadius: 60,
    padding: 5,
    float: 'left',
    marginTop: -5,
    marginRight: '10px',
  },
  categoryIon: {
    height: 32,
    margin: -10,
    marginLeft: 0,
    marginRight: 5,
  },
  fileName: {
    maxWidth: 140,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  verticalClass: {
    marginLeft: 10,
    verticalAlign: 'middle',
  },
  // MenuItemBox: {
  //   left: '515px !important',
  // },
}));

const TypoStyled = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text.primary,
  marginTop: 10,
  marginBottom: 20,
  cursor: 'pointer',
  marginRight: 30,
  display: 'flex',
}));

const ButtonWarn = styled(Button)(({ theme }) => ({
  background: theme.palette.warning.main,
  color: theme.palette.warning.contrastText,
}));

const TypoHeadStyled = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
  fontWeight: 'bold',
}));

const TypoHeadInnerStyled = styled(TypoHeadStyled)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const PaperStyled = styled(Paper)({
  padding: '10px 15px',
});

const GridStyled = styled(Grid)(({ theme }) => ({
  paddingLeft: '10rem',
  paddingRight: '10rem',
  [theme.breakpoints.down('md')]: {
    paddingLeft: '8rem',
    paddingRight: '8rem',
  },

  [theme.breakpoints.down('sm')]: {
    paddingLeft: '2rem',
  },
}));

//  *********** UI Styles End *****************

const CreateEmergencyAlert = (props) => {
  const FormFields = {
    title: '',
    subject: '',
    message: '',
    alert_to: '',
    to_group: '',
  };

  const dispatch = useDispatch();
  // Props used over
  const {
    getGroups,
    groups,
    totalContacts,
    businessusers,
    getGroupList,
    grouplist,
    getBusinessUsers,
    usersContactsList,
    usersContactList,
  } = props;

  const classes = useStyles();

  const [isSelectAll, setIsSelectAll] = useState(false);
  const [groupname_membersname, setGroupname_membersname] = useState([]);
  const [formvalue, setFormvalue] = useState(FormFields);
  const [formValidation, setFormValidation] = useState(FormFields);
  const [group_members, setGroup_members] = useState([]);
  const [checked, setChecked] = useState([]);
  const [memberchecked, setMemberchecked] = useState([]);
  const [userTemplateData, setUserTemplateData] = useState([]);
  const [subject, setSubject] = useState('');
  const [title, setTitle] = useState('');
  const [showContact, setShowContact] = useState(false);
  const [menu, setMenu] = useState(0);
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState([]);
  const [showTemplateList, setTemplateList] = useState([]);
  const [checkOnSelectAllGroup, setcheckOnSelectAllGroup] = useState(false);
  const [checkOnSelectAllMembers, setcheckOnSelectAllMembers] = useState(false);

  const { user_id, business_code, first_name, last_name } = JSON.parse(localStorage.getItem('userData'));

  // Import for metod
  const history = useHistory();

  const [businessuserslist, setBusinessUser] = useState([]);
  const [showTitle, setShowTitle] = useState(true);
  // useEffect(() => {
  //   // setContacts(businessusers)
  // }, [businessusers]);

  useEffect(() => {
    const SendToBusiness = {
      user_id,
      business_code,
    };
    getGroupList({ user_id });
    getBusinessUsers(SendToBusiness);
    dispatch(getTemplateList({ user_id }));
  }, []);

  useEffect(() => {
    setSubject('');
    setMenu(0);
    setTitle('');
    setMessage('');
  }, []);

  const selectAllHandler = (event) => {
    setIsSelectAll(event);
  };

  const currentTabMenu = (tab) => {
    setCurrentTab(tab);
  };

  const handleToTagvalue = (value) => {
    setPhoneNumber(value);
  };
  const isIpadView = useMediaQuery('(max-width: 1800px)');
  const [open, setOpen] = useState(false);
  const open1 = useRef(false);
  const [Loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const pageNm = useRef(1);
  const contact_data = useRef({});
  const [currentTab, setCurrentTab] = useState(0);
  const [removedUser, setRemovedUser] = useState([]);
  const [ContactList, setContactList] = useState([]);
  let { ID, member_id, group_id, get_name } = useParams();
  const [phoneNumber, setPhoneNumber] = useState([]);
  const [dublicateAttachement, setDublicateAttachement] = useState([]);

  const [newFiles, setNewFiles] = useState([]);
  const [newDeleteFiles, setNewDeleteFiles] = useState([]);

  const showTemplateDetails = (test) => {
    // alert(test);
    dispatch(getAlertTemplateDetails({ user_id: user_id, template_id: test }));
  };

  const { templatedetails } = useSelector((state) => state.messageCenterReducer);

  useEffect(() => {
    if (templatedetails && menu != 0) {
      setFormvalue({
        ...formvalue,
        title: templatedetails.title,
        subject: templatedetails.subject,
        message: templatedetails.message,
      });
      setSubject(templatedetails.subject);
      setMessage(templatedetails.message);
      setTitle(templatedetails.title);
      setDublicateAttachement(templatedetails.attachement);
      setFiles(templatedetails.attachement);
    }
  }, [templatedetails]);

  const handleToopen = () => {
    setOpen(true);
    open1.current = true;
    setLoading(true);
    // pageNm.current = 1;
    const dataToSend = {
      user_id,
      business_code,
      page: pageNm.current,
    };
    usersContactList(dataToSend);
    console.log('dataToSend', dataToSend);
    setTimeout(() => {
      let Ele = document.querySelector('#scrollElement');
      console.log('scrollElementscrollElementscrollElement');
      if (Ele) {
        console.log('Ele-', Ele, contact_data.current);
        console.log('contact_data.current.current_page', contact_data.current);
        Ele.addEventListener('scroll', () => {
          console.log('Ele.offsetHeight', Ele.offsetHeight, Ele.scrollTop, Ele.scrollHeight, open1.current, currentTab);
          console.log('Math.floor', Math.ceil(Ele.offsetHeight + Ele.scrollTop + 1), Ele.scrollHeight);
          if (
            // (Math.ceil(Ele.offsetHeight + Ele.scrollTop) <= Ele.scrollHeight || Math.ceil(Ele.offsetHeight + Ele.scrollTop) >= Ele.scrollHeight) &&
            Math.ceil(Ele.offsetHeight + Ele.scrollTop + 1) >= Ele.scrollHeight &&
            open1.current &&
            currentTab === 0
          ) {
            console.log(
              'contact_data-data-',
              contact_data.current.data,
              contact_data.current.total,
              contact_data.current.data.length
            );
            console.log('isPage-', pageNm.current, contact_data.current.current_page, contact_data.current.last_page);
            if (
              contact_data.current.data &&
              contact_data.current.data !== undefined &&
              contact_data.current.current_page <= contact_data.current.last_page &&
              pageNm.current < contact_data.current.last_page
            ) {
              setLoading(true);
              pageNm.current = pageNm.current + 1;
              const dataToSend = {
                user_id,
                business_code,
                page: pageNm.current,
              };
              usersContactList(dataToSend);
              console.log('contact_data.current.total', contact_data.current.total);
            } else {
              // setLoading(true);
              // pageNm.current = pageNm.current + 1;
              // const dataToSend = {
              //   user_id,
              //   business_code,
              //   page: pageNm.current,
              // };
              // usersContactList(dataToSend);
              console.log('calling3-', pageNm);
            }
          }
        });
        //   // Ele.setAttribute('onscroll', 'fetchMoreData');
      }
    }, 1000);
  };
  const handleGroupToggle = (value) => {
    console.log("value@@@", value)
    setcheckOnSelectAllGroup(false);
    const groupMembers = value.group_member_list;
    console.log('checked=====', checked);
    const currentIndex = checked.indexOf(value.group_id);
    const newChecked = [...checked];
    const selectedmember = [...group_members];

    if (currentIndex === -1) {
      newChecked.push(value.group_id);
      groupMembers.forEach((element) => {
        selectedmember.push(element.user_id);
      });
    } else {
      newChecked.splice(currentIndex, 1);
      groupMembers.forEach((element) => {
        const remomeitem = selectedmember.indexOf(element.user_id);
        selectedmember.splice(remomeitem, 1);
      });
    }

    console.log('newChecked=====', newChecked);
    setChecked(newChecked);
    setGroup_members(selectedmember);
    setFormvalue({
      ...formvalue,
      alert_to_group: newChecked.join(','),
      message_group: selectedmember,
      alert_to: selectedmember.join(', '),
    });
  };

  const handleMemberToggle = (value) => {
    const currentIndex = memberchecked.indexOf(value.user_id);
    const newChecked = [...memberchecked];
    let removedUserIds = [...removedUser];
    if (currentIndex === -1) {
      newChecked.push(value.user_id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (value.join_user_id && newChecked) {
      removedUserIds.push(value.join_user_id); // Push new data into the array
    }
    console.log('updatedRemovedUser', removedUserIds);
    setRemovedUser(removedUserIds);
    setMemberchecked(newChecked);
    setFormvalue({ ...formvalue, message_group: newChecked, alert_to: newChecked.join(', ') });

    // SELECT ALL
    const groupname = [];
    const membername = [];
    if (value == 0) {
      businessusers.forEach((element) => {
        membername.push(`${element.first_name} ${element.last_name}`);

        newChecked.push(element.user_id);
      });
      setcheckOnSelectAllMembers(true);
      setMemberchecked(newChecked);
    } else if (value == 1) {
      grouplist.forEach((element) => {
        groupname.push(element.group_name);
        newChecked.push(element.group_id);
      });
      setcheckOnSelectAllGroup(true);
      setChecked(newChecked);
    } else if (value == 3) {
      setcheckOnSelectAllMembers(false);
      setMemberchecked([]);
    } else if (value == 4) {
      setcheckOnSelectAllGroup(false);
      setChecked([]);
    } else {
      setcheckOnSelectAllMembers(false);
    }
    //SELECT ALL
  };

  const handleAllMembersToggle = (value) => {
    const newChecked = [...value];
    const formvalidation = { ...formValidation };

    setFormvalue({
      ...formvalue,
      message_group: newChecked,
      alert_to: newChecked.join(', '),
    });
    formvalidation.alert_to = '';
    setFormValidation(formvalidation);
    setMemberchecked(newChecked);
  };

  const deleteImage = (index, file) => {
    if (file && file.name) {
      var removeIndex = files
        .map(function (item) {
          return item.name;
        })
        .indexOf(file.name);
      files.splice(removeIndex, 1);
      if (file.event_id) {
        let delFiles = [...newDeleteFiles];
        delFiles.push(file);
        setNewDeleteFiles(delFiles);
      }
    }
    if (files.length == 0) {
      setFiles([]);
    } else {
      setFiles([...files]);
      console.log('files===', files);
    }
  };

  const handleAllGroupMembersToggle = (value) => {
    console.log("handleAllGroupMembersToggle",value)
    const newChecked = [...value];
    const selectedmember = [...newChecked];
    const formvalidation = { ...formValidation };
    formvalidation.eventForm_to = '';
    setFormvalue({
      ...formvalue,
      message_group: selectedmember,
      alert_to: selectedmember.join(', '),
      // eventForm_to: '@'
    });
    setChecked(newChecked);
    formvalidation.message_group = '';
    setFormValidation(formvalidation);
    setGroup_members(selectedmember);
  };

  const handleToSubmit = () => {
    console.log(formValidation, 'formValidation');
    if (validate()) return false;
    const sendAttachment = files;
    console.log(isSelectAll, 'isSelectAll');
    const contact = {
      selected_all: isSelectAll,
      removed_id: isSelectAll ? removedUser : [],
    };
    formvalue.contact = contact;
    formvalue.user_id = user_id;
    formvalue.message = message;
    history.push("/emergency-alert")
    setLoadingSubmit(true);
    dispatch(createAlert(formvalue, history, sendAttachment));
    // history.push('/emergency-alert');
  };

  // const { businessusers } = useSelector((state) => state.rymidr);
  //   const { userTemplateList } = useSelector((state) => state.messageCenterReducer);
  const { templatelist } = useSelector((state) => state.messageCenterReducer);

  useEffect(() => {
    if (templatelist && templatelist.data && templatelist.data.length > 0) {
      setTemplateList(templatelist.data);
    }
  }, [templatelist]);

  const AutocompleteStyled = styled(Autocomplete)({
    height: 50,
    '&>div': {
      margin: 0,
      '&>div': { height: 50, alignItems: 'flex-start' },
    },
  });

  useEffect(() => {
    console.log(usersContactsList, 'usersContactsListpayal');
    if (open1.current && usersContactsList.data !== undefined) {
      contact_data.current = usersContactsList;
      console.log('usersContactsList-', usersContactsList.data);
      setContactList([...ContactList, ...usersContactsList.data]);
      setLoading(false);
    }
  }, [usersContactsList]);

  const getFiles = (e) => {
    const fileObj = [];
    const fileArray = [];
    // alert('fileee-target'+ e.target.files);
    fileObj.push(e.target.files);

    for (let i = 0; i < fileObj[0].length; i++) {
      if (files.length > 0) {
        getBase64(fileObj[0][i], (result) => {
          const fileInfo = {
            image_name: fileObj[0][i].name,
            type: fileObj[0][i].type,
            size: Math.round(fileObj[0][i].size / 1000) + ' kB',
            base64: fileObj[0][i].type == 'video/mp4' ? result.replace('data:video/mp4;base64,', '') : result,
            file: fileObj[0][i],
          };
          files.push(fileInfo);
          // files.push({'base64': result, 'name': fileObj[0][i].name})
          setFiles([...files]);
        });
      } else {
        getBase64(fileObj[0][i], (result) => {
          // idCardBase64 = result;
          // Make a fileInfo Object
          const fileInfo = {
            image_name: fileObj[0][i].name,
            type: fileObj[0][i].type,
            size: Math.round(fileObj[0][i].size / 1000) + ' kB',
            base64: fileObj[0][i].type == 'video/mp4' ? result.replace('data:video/mp4;base64,', '') : result,
            file: fileObj[0][i],
          };
          fileArray.push(fileInfo);
          setFiles([...fileArray]);
        });
      }
    }
  };

  useEffect(() => {
    setShowContact(true);
  }, [businessuserslist]);

  const getBase64 = (file, cb) => {
    //, cb
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
      // console.log('cccccc', reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const handleClose = (newValue) => {
    console.log('selectall', removedUser);
    open1.current = false;
    setOpen(newValue);
    setContactList([]);
    pageNm.current = 1;
    const membername = [];
    const groupname = [];
    if (grouplist.length > 0) {
      grouplist.forEach((element) => {
        const checkvalue = checked.indexOf(element.group_id);
        if (checkvalue !== -1) {
          groupname.push(element.group_name);
        } else if (element.group_id == group_id) {
          groupname.push(element.group_name);
        }
      });
    }

    if (businessusers.length > 0) {
      businessusers.forEach(async (element) => {
        console.log(memberchecked, element.user_id, 'memberchecked');
        const checkvalue = memberchecked.indexOf(element.user_id);
        console.log('checkvalue', checkvalue);
        if (checkvalue != -1) {
          if (element.user_id == user_id) {
            membername.push('You');
            console.log('membernamemembername');
          } else {
            console.log('=================');
            membername.push(
              element.first_name !== '' ? `${element.first_name} ${element.last_name}` : element.mobile_no
            );
          }
        } else if (element.user_id == member_id) {
          console.log('=========//////========');
          if (element.user_id == user_id) {
            membername.push('You');
          } else {
            membername.push(
              element.first_name !== '' ? `${element.first_name} ${element.last_name}` : element.mobile_no
            );
          }
        } else {
          console.log('HERE ----------------payal');
        }
      });

      if (membername == '') {
        membername.push('You');
      }
      console.log('HERE@');
      setGroupname_membersname(groupname.concat(membername));
      if (checked.length == 0 && memberchecked.length == 0) {
        setGroupname_membersname([]);
      }
      const dataToSend = {
        mobile_no: phoneNumber.join(),
      };
      if (phoneNumber.length > 0) {
        inviteAndAddMobile(dataToSend);
      }

      if (newValue) {
        // setValue(newValue);
      }
    }
  };

  const handleSubject = (event, sub = '') => {
    let subject = event != '' ? event.target.value : sub;
    setSubject(subject);
    setFormvalue({ ...formvalue, subject: subject.trim() });
  };

  const handleTitle = (subject) => {
    setTitle(subject);
    setFormvalue({ ...formvalue, title: subject.trim() });
  };

  const validate = () => {
    const formvalidation = { ...formValidation };
    // alert('subject'+formvalue.subject);
    console.log(formvalue, 'formvalue********');
    let isError = false;
    if (!formvalue.subject) {
      isError = true;
      formvalidation.subject = 'Please input Subject!';
      setFormValidation(formvalidation);
    } else {
      formvalidation.subject = '';
      setFormValidation(formvalidation);
    }
    // if (!formvalue.title) {
    //   isError = true;
    //   formvalidation.title = 'Please input title!';
    //   setFormValidation(formvalidation);
    // } else {
    //   formvalidation.title = '';
    //   setFormValidation(formvalidation);
    // }

    if (!message) {
      isError = true;
      formvalidation.message = 'Please input Message!';
      setFormValidation(formvalidation);
    } else {
      formvalidation.message = '';
      setFormValidation(formvalidation);
    }

    if (!formvalue.alert_to) {
      isError = true;
      formvalidation.alert_to = 'Please Select Contact';
      setFormValidation(formvalidation);
    } else {
      formvalidation.alert_to = '';
      setFormValidation(formvalidation);
    }

    return isError;
  };

  const handleTitleKeyUp = (e) => {
    const formvalidation = { ...formValidation };
    formvalidation.title = '';
    setFormValidation(formvalidation);
  };

  const handleSubjectKeyUp = (e) => {
    const formvalidation = { ...formValidation };
    formvalidation.subject = '';
    setFormValidation(formvalidation);
  };

  const resetForm = () => {
    // console.log("formValue==", formvalue);
    setSubject('');
    setMenu(0);
    setTitle('');
    setMessage('');
    setFiles([]);
    setTemplateList([]);
    showTemplateDetails();
    setGroup_members([]);
    setChecked([]);
    setMemberchecked([]);
    setGroupname_membersname([]);
    setcheckOnSelectAllGroup(false);
    setcheckOnSelectAllMembers(false);
    // setTemplateList(templatelist);
    setTemplateList(templatelist.data);
  };

  console.log('test', formvalue);

  const handleTemplate = () => {
    setFormvalue({
      ...formvalue,
      title: '',
      subject: '',
      message: '',
    });
    setSubject('');
    setMessage('');
    setTitle('');
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ConfirmationDialogRaw open={open} onClose={handleClose} groupdata={grouplist} memberdata={ContactList}>
          <Tabmenu
            groupdata={grouplist}
            memberdata={ContactList}
            handleToggles={handleGroupToggle}
            currentTabMenu={currentTabMenu}
            selectAllHandler={(event) => selectAllHandler(event)}
            Loading={Loading}
            totalContacts={totalContacts}
            checked={checked}
            contact={removedUser}
            handleMemberToggle={handleMemberToggle}
            handleAllMembersToggle={handleAllMembersToggle}
            handleAllGroupMembersToggle={handleAllGroupMembersToggle}
            memberchecked={memberchecked}
            handleToTagvalue={handleToTagvalue}
          />
        </ConfirmationDialogRaw>
        <Grid className="main-wrap-head" container style={{ marginBottom: 20 }} alignItems="center">
          <Grid item xs={7}>
            <TypoHeadStyled variant="h4">
              <ArrowBackIcon onClick={() => history.goBack()} /> Create{' '}
              <TypoHeadInnerStyled component="span">Emergency Alert</TypoHeadInnerStyled>
            </TypoHeadStyled>
          </Grid>
          <Grid item xs={5}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" color="primary" size="large" startIcon={<Replay />} onClick={resetForm}>
                Reset
              </Button>
            </Box>
          </Grid>
        </Grid>

        <PaperStyled className="main-wrap-body create-msg-wrap">
          {businessusers && businessusers.length > 0 ? (
            <GridStyled container>
              <Grid item lg={12}>
                <AutocompleteStyled
                  multiple
                  limitTags={1}
                  id="tags-filled"
                  filterSelectedOptions={false}
                  options={isSelectAll ? ['All Contacts'] : groupname_membersname}
                  value={isSelectAll ? ['All Contacts'] : groupname_membersname}
                  freeSolo
                  onChange={(event, newValue) => {
                    const newVal = newValue.toString();
                    const getArray = newVal.split(',');
                    const user_id_arr = [];
                    const group_id_arr = [];
                    for (let i = 0; i < getArray.length; i++) {
                      const getNewList = businessusers.find(
                        (ele) => ele.first_name + ' ' + ele.last_name == getArray[i]
                      );
                      if (getNewList) {
                        user_id_arr.push(getNewList.user_id);
                      } else {
                        // FOR GROUPS
                        const getNewGroupList = groups.find((ele) => ele.group_name == getArray[i]);
                        if (getNewGroupList) {
                          group_id_arr.push(getNewGroupList.group_id);
                        }
                      }
                    }

                    const formdata = { ...formvalue };
                    setGroup_members(group_id_arr);
                    setGroupname_membersname(newValue);

                    formdata.message_group = user_id_arr.join() ? user_id_arr.join() : group_id_arr.join();
                    formdata.alert_to = user_id_arr.join();
                    formdata.to_group = group_id_arr.join();
                    setFormvalue(formdata);
                    const formvalidation = { ...formValidation };
                    formvalidation.alert_to = '';
                    setFormValidation(formvalidation);
                  }}
                  // value={groupname_membersname}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Type Number and Enter to Add"
                      variant="outlined"
                      margin="normal"
                      label="To"
                      onChange={handleToopen}
                      onClick={handleToopen}
                      error={!!formValidation.alert_to}
                      helperText={formValidation.alert_to}
                      name="message_group"
                    />
                  )}
                />
              </Grid>
              <Box className='menuItem' style={{width: "100%"}}>

                <Grid item lg={12} className="MenuItemBox">
                  <TextField
                    select
                    label="Select Template"
                    value={menu}
                    fullWidth
                    onChange={(e, d) => {
                      if (d.props?.value !== 0) {
                        setMenu(d.props?.value);
                        setShowTitle(true);
                        // let user = showTemplateList?.find((user) => {
                        //   return user?._id === d.props?.value;
                        // });
                        showTemplateDetails(d.props?.value);
                      } else {
                        setMenu(0);
                        handleTemplate();
                        setShowTitle(true);
                      }

                      const formvalidation = { ...formValidation };
                      formvalidation.title = '';
                      formvalidation.subject = '';
                      formvalidation.message = '';
                      setFormValidation(formvalidation);
                    }}
                    error={!!formValidation.category}
                    helperText={formValidation.category}
                    variant="outlined"
                    name="Template"
                    SelectProps={{
                      MenuProps: {
                        style: {
                          top: isIpadView ? '6%' : '9%',
                          left: isIpadView ? "0%" : "11.5%"
                        }
                      }
                    }}
                  >
                    <MenuItem key={0} value={0} >
                      Use Custom Content
                    </MenuItem>
                    {showTemplateList?.map((userTemplate, index) => (
                      <MenuItem key={userTemplate?._id} value={userTemplate?._id}>
                        {userTemplate?.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Box>


              <Grid item lg={12}>
                <TextField
                  label="Alert"
                  fullWidth
                  variant="outlined"
                  value={subject}
                  onClick={handleSubject}
                  onChange={handleSubject}
                  onKeyUp={handleSubjectKeyUp}
                  error={!!formValidation.subject}
                  helperText={formValidation.subject}
                  name="subject"
                />
              </Grid>

              <Grid item xs={12}>
                <CKEditor
                  config={ckEditorConfig}
                  editor={Editor}
                  data={message}
                  name="message"
                  label="Instruction"
                  onReady={(editor) => {
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setMessage(data);

                    const formvalidation = { ...formValidation };
                    formvalidation.message = '';
                    setFormValidation(formvalidation);
                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                   onBeforeOutputHtml={(evt, data) => {
                    // Regular expression to match anchor tags with href attributes
                    const anchorRegex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1[^>]*?>/gi;
                    
                    // Replace <br> tags within href attributes with an empty string
                    data.dataValue = data.dataValue.replace(anchorRegex, (match, p1, p2) => {
                      return match.replace(/<br\s*\/?>/gi, '');
                    });
                  }}
                />
                <p
                  style={{
                    color: 'red',
                    marginLeft: '1rem',
                    fontSize: '12px',
                    marginTop: '4px',
                  }}
                >
                  {formValidation.message}
                </p>
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" className="attach-files">
                  <FormControlLabel
                    className="attachement"
                    control={
                      <input
                        type="file"
                        multiple
                        onChange={getFiles}
                        onClick={(e) => (e.target.value = null)}
                        className="displayNone"
                        style={{ display: 'none' }}
                      />
                    }
                    label={
                      <Typography variant="subtitle1" component="p">
                        <Attachment style={{ marginBottom: 0, marginRight: 5 }} color="primary" /> Attachment
                      </Typography>
                    }
                  />
                </Box>
                <Box alignItems="center" display="flex" className='attachment1'>
                  {files &&
                    files.map((file, index) => {
                      if (file.attachment_object != undefined) {
                        const extension_arr = file.attachment_object.split('.');
                        const extension = extension_arr[1];

                        if (extension == 'jpeg' || extension == 'jpg' || extension == 'png' || extension == 'gif') {
                          return (
                            <TypoStyled variant="subtitle1" component="p" key={index}>
                              <img src={file.file_name} width="32" height="32" />{' '}
                              <Box className={classes.fileName}>{file.attachment_object}</Box>{' '}
                              <CancelIcon onClick={() => deleteImage(index, file)} />
                            </TypoStyled>
                          );
                        } else if (extension == 'mp3') {
                          return (
                            <TypoStyled variant="subtitle1" component="p" key={index}>
                              <Headset className={classes.iconAttach} /> {file.attachment_object}{' '}
                              <CancelIcon onClick={() => deleteImage(index, file)} />
                            </TypoStyled>
                          );
                        } else if (extension == 'mp4') {
                          return (
                            <TypoStyled variant="subtitle1" component="p" key={index}>
                              <Videocam className={classes.iconAttach} /> {file.attachment_object}{' '}
                              <CancelIcon onClick={() => deleteImage(index, file)} />
                            </TypoStyled>
                          );
                        } else if (extension == 'doc' || extension == 'docx' || extension == 'pdf') {
                          return (
                            <TypoStyled variant="subtitle1" component="p" key={index}>
                              <Description className={classes.iconAttach} /> {file.attachment_object}{' '}
                              <CancelIcon onClick={() => deleteImage(index, file)} />
                            </TypoStyled>
                          );
                        } else {
                          return (
                            <TypoStyled variant="subtitle1" component="p" key={index}>
                              <Description className={classes.iconAttach} /> {file.attachment_object}{' '}
                              <CancelIcon onClick={() => deleteImage(index, file)} />
                            </TypoStyled>
                          );
                        }
                      } else {
                        const extension_arr = file.image_name.split('.');
                        const extension = extension_arr[1];
                        if (extension == 'jpeg' || extension == 'jpg' || extension == 'png' || extension == 'gif') {
                          return (
                            // <TypoStyled variant='subtitle1' component='p' key={index}>
                            //   <img src={file.base64} width='32' height='32' className={classes.iconAttach} /> <Box className={classes.fileName}>{file.image_name}</Box> <CancelIcon onClick={() => deleteImage(index, file)} />
                            // </TypoStyled>

                            <TypoStyled variant="subtitle1" component="p" key={index}>
                              <img src={file.base64} className={classes.iconAttach} /> {file.image_name}
                              <CancelIcon onClick={() => deleteImage(index, file)} className={classes.verticalClass} />
                            </TypoStyled>
                          );
                        } else if (extension == 'mp3') {
                          return (
                            <TypoStyled variant="subtitle1" component="p" key={index}>
                              <Headset className={classes.iconAttach} /> {file.image_name}{' '}
                              <CancelIcon onClick={() => deleteImage(index, file)} />
                            </TypoStyled>
                          );
                        } else if (extension == 'mp4') {
                          return (
                            <TypoStyled variant="subtitle1" component="p" key={index}>
                              <Videocam className={classes.iconAttach} /> {file.image_name}{' '}
                              <CancelIcon onClick={() => deleteImage(index, file)} />
                            </TypoStyled>
                          );
                        } else if (extension == 'doc' || extension == 'docx' || extension == 'pdf') {
                          return (
                            <TypoStyled variant="subtitle1" component="p" key={index}>
                              <Description className={classes.iconAttach} /> {file.image_name}{' '}
                              <CancelIcon onClick={() => deleteImage(index, file)} />
                            </TypoStyled>
                          );
                        } else {
                          return (
                            <TypoStyled variant="subtitle1" component="p" key={index}>
                              <Description className={classes.iconAttach} /> {file.image_name}{' '}
                              <CancelIcon onClick={() => deleteImage(index, file)} />
                            </TypoStyled>
                          );
                        }
                      }
                    })}
                </Box>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    {/*  <ButtonColor variant='contained' color='primary' size='large' className='mr-30 width180' startIcon={<Visibility />}>
                        Preview
                      </ButtonColor> */}

                    {/*<ButtonWarn variant='contained' color='primary' size='large' startIcon={<Send />}>
                        Send Rymindr
                      </ButtonWarn>*/}

                    <ButtonWarn
                      className="btn-booking"
                      onClick={handleToSubmit}
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<Send />}
                      disabled={loadingSubmit}
                    >
                      {loadingSubmit && <CircularSpinner />}
                      Send Alert Now
                    </ButtonWarn>
                  </Box>
                </Grid>
              </Grid>
            </GridStyled>
          ) : (
            <div style={{ marginTop: '16rem' }}>
              <DialogContent style={{ margin: '2rem 6rem 2rem 6rem', textAlign: 'center' }}>
                <h6 style={{ letterSpacing: '0.4px', paddingLeft: '1rem' }}>
                  You'll need to have at least 1 contact to enable this feature.
                </h6>
              </DialogContent>
              <Box display="flex" justifyContent="center" style={{ marginBottom: '5rem' }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    history.push('/contacts');
                  }}
                >
                  Add Contacts
                </Button>
              </Box>
            </div>
          )}
        </PaperStyled>
      </MuiPickersUtilsProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    businessusers: state.bookevent.businessusers,
    usersContactsList: state.contact.usersContactsList,
    totalContacts: state.contact.totalContacts,
    grouplist: state.contact.grouplist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGroups: (data) => dispatch(getGroups(data)),
    getBusinessUsers: (data) => dispatch(getBusinessUsers(data)),
    inviteAndAddMobile: (data) => dispatch(inviteAndAddMobile(data)),
    usersContactList: (data) => dispatch(usersContactList(data)),
    getGroupList: (data) => dispatch(getGroupList(data)),
  };
};

CreateEmergencyAlert.propTypes = {
  getGroups: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  grouplist: PropTypes.any.isRequired,
  businessusers: PropTypes.array.isRequired,
  getBusinessUsers: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(CreateEmergencyAlert));
