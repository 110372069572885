import {
  ArrowBack,
  Comment,
  Delete,
  Description,
  Edit,
  Headset,
  Image,
  Message,
  MoreVert,
  Search,
  ThumbUp,
  Videocam,
  Close,
  FiberManualRecord,
  Update,
  Check,
} from '@material-ui/icons';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  CardMedia,
} from '@material-ui/core';
import CircularSpinner from '../../../component/CircularSpinner/index';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  delete_rymindr2,
  getRymidrDetails,
  getUpcommingRymindrs,
  generateQrCodeDB,
  statusData,
  rymindrSearchContact,
} from '../../../store/actions/rymidr';
import Axios from '../../../helper/axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { makeStyles, styled, withStyles } from '@material-ui/core/styles';
import FullscreenSpinner from '../../../component/FullscreenSpinner';
import Comments from '../../../container/Comments/Comments';
import ResponsiveDialog from './Modal/RymindrModalDelete';
import ResponsiveDialogDelete from './Modal/RymindrDeleteModal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import clsx from 'clsx';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import * as htmlToImage from 'html-to-image';
import parse from 'html-react-parser';
import io from 'socket.io-client';
import {
  FacebookIcon,
  LinkedinIcon,
  EmailIcon,
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from 'react-share';
import { getUserDetails } from '../../../store/actions/accountsettingAction';
import GetApp from '@material-ui/icons/GetApp';
import RymindrOriginal from '../../../assets/images/rymindr_original.png';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    position: 'relative',
    overflow: 'auto',
    height: 100 + '%',
  }, // => {maxHeight: 'calc(100vh - 80px)', minHeight: 100 + 'vh', }
  rightPanel: {
    [theme.breakpoints.up('md')]: {
      display: 'block !important',
    },
  },

  iconAttach: {
    color: theme.palette.primary.dark,
    background: theme.palette.primary.light,
    height: 32,
    width: 32,
    borderRadius: 60,
    padding: 5,
    float: 'left',
    marginTop: -5,
    marginRight: '10px',
  },
  icon: {
    width: 150,
    marginBottom: 40,
  },
  content: {
    textAlign: 'center',
    textAlign: '-webkit-center',
    paddingTop: '15%',
    height: '80vh',
  },
  wrapText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  overFlowHide: {
    overflow: 'inherit',
  },
}));
const styles = {
  input: {
    backgroundColor: 'red',
  },
};
const AvatarStyled = styled(Avatar)({
  height: 25,
  width: 25,
  '& img': {
    height: 'auto',
  },
});
const DialogTitleStyled = styled(DialogTitle)({
  borderBottom: '1px solid #e0e0e0',
  padding: '10px 24px',
});
const TypoStatusStyled = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.contrastText,
  width: 60,
  textAlign: 'right',
  fontWeight: '600',
}));
const TypoStatusStyledAccept = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: '#44b700',
  width: 60,
  textAlign: 'right',
  fontWeight: '600',
}));
const TypoStatusStyledReject = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: '#FF0000',
  width: 60,
  textAlign: 'right',
  fontWeight: '600',
}));
const TypoTitleStyled = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.contrastText,
}));
const TypoPopHeadStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontSize: 18,
  fontWeight: '600',
}));
const TypoContentStyled = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  color: theme.palette.text.primary,
}));

const AvatarShareStyled = styled(Avatar)({
  height: 50,
  width: 50,
});

const BoxStyled = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  '&>span': { marginRight: 10 },
});

const TypoHeadStyled = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
  fontWeight: 'bold',
}));

const TypoHeadInnerStyled = styled(TypoHeadStyled)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const TypoListSubtext = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.contrastText,
}));

const TypoListSubtext2 = styled(TypoListSubtext)(({ theme }) => ({
  color: '#696969',
  // whiteSpace: 'nowrap',
  // overflow: 'hidden',
  // textOverflow: 'ellipsis',
  // overflow: hidden,
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  // '-webkit-box-orient': vertical,
}));

const SearchFieldStyled = styled(TextField)(({ theme }) => ({
  paddingTop: 10,
  paddingBottom: 10,
}));

const ListStyled = styled(List)(({ theme }) => ({
  paddingLeft: 20,
}));

const GridStyled = styled(Grid)({
  padding: '15px 15px 15px 30px',
});

const AvatarComntStyled = styled(Avatar)({
  height: 42,
  width: 42,
  marginRight: 20,
});

const TextFieldStyled = styled(TextField)({
  width: 100 + '%',
  height: 100,
});

const TypoCmntName = styled(Typography)({
  color: '#757575',
  fontWeight: 'bold',
  fontSize: 12,
  '& span': {
    fontWeight: 'normal',
    marginLeft: 20,
  },
});
const TypoCmntTxt = styled(Typography)({
  color: '#3d3d3d',
  fontSize: 14,
});

const ButtonStyled = styled(Button)({
  color: '#757575',
  fontSize: 12,
});

const BoxColorStyled = styled(Box)({
  background: '#f0f6fb',
  fontSize: 12,
  padding: '10px 15px',
  borderRadius: 15,
  minWidth: 160,
  justifyContent: 'space-between',
});

const BoxImgStyled = styled(Box)({
  textAlign: 'center',
  marginRight: 20,
  '& div': {
    margin: 'auto',
  },
});
const TypoStyled = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  color: theme.palette.text.primary,
  marginTop: 10,
  marginBottom: 10,
  marginRight: 10,
  cursor: 'pointer',
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadgeReject = withStyles((theme) => ({
  badge: {
    backgroundColor: '#e50214',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadgePending = withStyles((theme) => ({
  badge: {
    backgroundColor: '#1773bf',
    color: '#1773bf',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    padding: 0,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const ButtonPlain = styled(Button)(({ theme }) => ({
  color: '#98a5af',
  fontSize: 12,
  textTransform: 'capitalize',
  background: 'none',
  boxShadow: 'none',
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  borderRadius: 8,
  maxWidth: 600,
}));

const DialogContentStyled = styled(DialogContent)({
  textAlign: 'center',
});
const TypographyColor = styled(DialogContent)({
  color: 'red',
});

const PaperStyled = styled(Paper)(({ theme }) => ({
  padding: 30,
  height: 100 + '%',
}));

const TypoTxtStyled = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.text.primary,
  fontSize: 16,
}));

const TypoBusStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 18,
  fontWeight: '800',
}));

const options = [];

const UpcomingRymindr = (props) => {
  const history = useHistory();
  const {
    enqueueSnackbar,
    error,
    getUpcommingRymindrs,
    upcommingrymindr,
    loading,
    getRymidrDetails,
    rymindrDetails2,
    delete_rymindr2,
    success_message,
    generateQrCodeDB,
    qrImg,
    statusData,
    statusList,
    is_access,
    sidebar,
  } = props;
  const { user_id, business_code, first_name, last_name, is_subscribe } = JSON.parse(localStorage.getItem('userData'));
  const [showPanel, setShowPanel] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [isRerender, SetIsRerender] = useState(false);
  const [deleterymindr, setDeleterymindr] = useState(null);
  const [selectdeleterymindr, setSelectDeleterymindr] = useState([]);
  const [setType, setRymindrType] = useState('');
  const showShare = () => {
    setIsHidden(!isHidden);
  };
  const [rymindrDetails, setRymindrDetails] = useState({});
  const [searchPayload, SetSearchPayload] = useState();

  const Location = useLocation();
  const FormFields = '';

  const [selectedItem, setSelectedItem] = useState(null);
  const [openImage, setOpenImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [state, setState] = useState([]);
  const [copyState, SetCopyState] = useState([]);
  const [searchtoggler, SetSearchToggler] = useState(false);
  const [searchValue, SetSearchValue] = useState('');

  const [anchorEl, setAnchorEl] = useState(null);
  const [UpcomingRymindrs, setUpcomingRymindr] = useState([]);
  const [UpcomingRymindrCount, setUpcomingRymindrCount] = useState(0);
  const [rymindrCount, setRymindrCount] = useState(0)

  const [open, setOpen] = useState(false);
  const [users_list, setUserList] = useState([]);

  const [openQR, setOpenQR] = useState(false);

  const [openDownloadImage, setOpenDownloadImage] = useState(false);

  const [Loading, setLoading] = useState(false);
  const [searchVal, setSearch] = useState('');

  const [isSubscribed, setIsSubscribe] = useState();
  const [formvalidation, setFormvalidation] = useState(FormFields);
  const [formvalues, setFormvalues] = useState(FormFields);

  const [accepted, setAccepted] = useState(0);
  const [total, setTotal] = useState(0);
  const [is_accessible, setAccessable] = useState(false);
  const status_data = useRef({});
  const pageNum = useRef(1);
  const dispatch = useDispatch();

  const handlevalidation = () => {
    let error = false;
    const formerr = { ...formvalidation };

    if (!formvalues.contact) {
      error = true;
      formerr.contact = 'Contact is required!';
      setFormvalidation(formerr);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formvalues.contact)) {
      error = true;
      formerr.contact = 'Invalid contact address';
      setFormvalidation(formerr);
    }
    return error;
  };
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   const form = { ...formvalues };
  //   const formerr = { ...formvalidation };
  //   form[name] = value;
  //   formerr[name] = '';

  //   // Set form values and validate
  //   setFormvalues(form);
  //   handlevalidation();
  //   setFormvalidation(FormFields);
  //   const dataTosend = {
  //     rymindr_id: rymindrDetails.rymindr_id,
  //     contact: form.contact,
  //   };
  //   // Check if the input value meets the minimum length requirement
  //   if (value.length >= 3) {
  //     // If the input meets the requirement, proceed with API call

  //     SetSearchPayload(dataTosend);
  //     handleBettersearch(dataTosend);
  //   } else {
  //     SetSearchPayload(dataTosend);
  //     handleBettersearch(dataTosend);
  //     // console.log('Input value should be at least 3 characters or numbers.');
  //   }
  // };
  // let timer;

  // const handleChange = (e) => {
  //   clearTimeout(timer);

  //   const updatedFormValues = { ...formvalues, [e.target.name]: e.target.value };
  //   setFormvalues(updatedFormValues);

  //   const updatedFormValidation = { ...formvalidation, [e.target.name]: '' };
  //   setFormvalidation(updatedFormValidation);

  //   const dataToSend = {
  //     rymindr_id: rymindrDetails.rymindr_id,
  //     contact: updatedFormValues.contact,
  //   };

  //   timer = setTimeout(() => {
  //     SetSearchPayload(dataToSend);
  //     handleBettersearch(dataToSend);
  //   }, 3000); // 3000 milliseconds = 3 seconds
  // };
  // useEffect(() => {
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []); 
  const debounceTimeoutRef = useRef(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    SetSearchValue(value);
  
    // Update form values and validation state
    const form = { ...formvalues, [name]: value };
    const formerr = { ...formvalidation, [name]: '' };
    setFormvalues(form);
    handlevalidation();
    setFormvalidation(FormFields);
  
    // Prepare data for the search
    const dataTosend = {
      rymindr_id: rymindrDetails.rymindr_id,
      contact: form.contact,
    };
  
    // Clear the previous debounce timeout
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
  
    // Set a new debounce timeout
    debounceTimeoutRef.current = setTimeout(() => {
      if (value.length >= 4 ) {
        SetSearchPayload(dataTosend);
        handleBettersearch(dataTosend);
      }
    }, 2000);
  
    // Reset the payload and form values after setting up the debounce timeout
    SetSearchPayload('');
    setFormvalues('');
  };
  // const handleChange = (e) => {
  //   SetSearchValue(e.target.value);
  //   const form = { ...formvalues };
  //   const formerr = { ...formvalidation };
  //   form[e.target.name] = e.target.value;
  //   formerr[e.target.name] = '';
  //   setFormvalues(form);
  //   handlevalidation();
  //   setFormvalidation(FormFields);
  //   const dataTosend = {
  //     rymindr_id: rymindrDetails.rymindr_id,
  //     contact: form.contact,
  //   };
  //   if (e.target.value >= 4 || e.target.value === '') {
  //     setTimeout(() => {
  //       SetSearchPayload(dataTosend);
  //       handleBettersearch(dataTosend);
  //     }, 3000);
  //     // SetSearchPayload(dataTosend);
  //     // handleBettersearch(dataTosend);
  //   } else {
  //     setTimeout(() => {
  //       SetSearchPayload(dataTosend);
  //       handleBettersearch(dataTosend);
  //     }, 3000);
  //     // SetSearchPayload(dataTosend);
  //     // handleBettersearch(dataTosend);
  //   }
  //   SetSearchPayload('')
  //   setFormvalues('');
  // };

  const handleBettersearch = (data) => {
    // if(e.key === "Enter"){
    props.rymindrSearchContact(data);
    SetSearchToggler(!searchtoggler);
    // }
  };
  console.log(UpcomingRymindrCount, 'rymindr count ');
  useEffect(() => {
    if (props.contactSearchList.data && searchValue != '') {
      setState(props.contactSearchList.data);
    } else {
      setState(copyState);
    }
  }, [searchValue, props.contactSearchList.data]);

  useEffect(() => {
    if (searchValue.length === 0) {
      setState(copyState);
    }
  }, [searchValue, copyState]);
  var pageNumber = 1;
  const handleCloseDownloadModal = () => {
    // alert('clsoe')
    setOpenDownloadImage(false);
  };
  // console.log("props11111111", props);
  const handleClickQROpen = (user_qr_code, user_qr_code_base64, business_code) => {
    setOpenQR(true);
    // setQrCode(user_qr_code)
    // setQrCodeBase64(user_qr_code_base64)
    // setBusinessCode(business_code)
  };
  useEffect(() => {
    dispatch(getUserDetails());
  }, []);
  const { account } = useSelector((state) => state);
  const subscribe = account?.userdetails?.is_subscribe;
  useEffect(() => {
    setIsSubscribe(subscribe);
  }, [account, subscribe]);
  useEffect(() => {
    if (!rymindrDetails?.qr_code) {
      SetIsRerender(!isRerender);
    }
  }, [rymindrDetails]);

  const handleCloseQRModal = () => {
    // alert('clsoe')
    setOpenQR(false);
  };
  // console.log("rymindrDetails11=", rymindrDetails2);
  const handleClickOpen = () => {
    setState([]);
    setOpen(true);
    // open.current = true
    setLoading(true);
    // pageNum.current = 1;
    const dataTosend = {
      rymindr_id: rymindrDetails.rymindr_id,
      page: pageNum.current,
    };
    statusData(dataTosend);
    setTimeout(() => {
      let Ele = document.querySelector('#scrollElement');
      if (Ele) {
        Ele.addEventListener('scroll', () => {
          if (
            Math.ceil(Ele.offsetHeight + Ele.scrollTop) >= Ele.scrollHeight
            // && open
          ) {
            if (
              status_data.current.data &&
              status_data.current.data !== undefined &&
              status_data.current.current_page <= status_data.current.last_page &&
              pageNum.current < status_data.current.last_page
            ) {
              setLoading(true);
              pageNum.current = pageNum.current + 1;
              const dataTosend = {
                rymindr_id: rymindrDetails.rymindr_id,
                page: pageNum.current,
              };
              statusData(dataTosend);
            } else {
              console.log('Data not found');
            }
          }
        });
        // Ele.setAttribute('onscroll', 'fetchMoreData');
      }
    }, 1000);
  };
  const removeDuplicates = (originalArray, prop) => {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  };

  useEffect(() => {
    const userList = [];
    // console.log('rymindrDetails=======', rymindrDetails2);
    if (rymindrDetails2 !== null && rymindrDetails2.user_list && rymindrDetails2.group_list) {
      setRymindrDetails(rymindrDetails2);
      rymindrDetails2.user_list.forEach((element) => {
        userList.push(element);
      });
      rymindrDetails2.group_list.forEach((element) => {
        element.group_member_list.forEach((ele) => {
          userList.push(ele);
        });
      });
      setUserList(userList);

      // console.log("userList=", rymindrDetails2);
      var uniqueArray = removeDuplicates(userList, 'user_id');
      setUserList(uniqueArray);
      setState([]);
    }

    // console.log("583==============", rymindrDetails2);
    setRymindrDetails(rymindrDetails2);

    let mounted = true;
    // var sock = io('https://app.rymindr.com:8081');
    // var sock = io("https://api.rymindr.com");
    // sock.on("rymindr-status:App\\Events\\RymindrStatus", function (data) {
    //   // console.log(
    //   //   "data --------------589",
    //   //   data.postId,
    //   //   rymindrDetails2.rymindr_id
    //   // );
    //   if (mounted) {
    //     if (data.postId == rymindrDetails2.rymindr_id) {
    //       let dataTosend = {};
    //       if (
    //         rymindrDetails2.rymindr_type == "Holiday" &&
    //         rymindrDetails2.is_bank_holiday == 1
    //       ) {
    //         dataTosend = {
    //           user_id,
    //           rymindr_id: data.postId,
    //           is_bank_holiday: 1,
    //         };
    //       } else {
    //         dataTosend = {
    //           user_id,
    //           rymindr_id: data.postId,
    //           is_page: "history",
    //         };
    //       }
    // console.log("Testrymindr", dataTosend); //
    // getRymidrDetails(dataTosend);
    //     }
    //   }
    // });
    return function cleanup() {
      mounted = false;
    };
  }, [rymindrDetails2]);

  // useEffect(() => {
  //   if (statusList.data !== undefined) {
  //     console.log("statusList", statusList.data.data);
  //     setLoading(false);
  //     console.log("status--", statusList);
  //     status_data.current = statusList.data;
  //     const newState = statusList.data.data;
  //     console.log("newState--", newState, state);
  //     setState([...state, ...newState]);
  //     SetCopyState([...state, ...newState]);
  //     setAccepted(statusList.acceptCount);
  //     setTotal(statusList.data.total);
  //   }
  // }, [state, statusList]);

  useEffect(() => {
    console.log("statusList", statusList)
    if (statusList.data !== undefined) {
      setLoading(false);
      status_data.current = statusList.data;
      if (statusList.data.current_page < statusList.data.last_page) {
        const newState = Array.isArray(statusList.data.data) ? statusList.data.data : []; // Check if newState is iterable, otherwise assign an empty array
        setState((prevState) => [...prevState, ...newState]);
        SetCopyState((prevState) => [...prevState, ...newState]);
        setAccepted(statusList.acceptCount);
        setTotal(statusList.data.total);
      } else {
        const newState = Array.isArray(statusList.data.data) ? statusList.data.data : []; // Check if newState is iterable, otherwise assign an empty array
        setState(newState);
        SetCopyState(newState);
        setAccepted(statusList.acceptCount);
        setTotal(statusList.data.total);
      }
    }
  }, [statusList]);
  const newdata = Location?.state;
  const { id, date } = newdata || {};
  console.log("id, date", id, date)
  // moksh changes
  useEffect(() => {
    if (qrImg) {
      const dataTosend = {
        user_id,
        rymindr_id: rymindrDetails.rymindr_id,
        is_page: 'history',
      };
      if (rymindrDetails !== null) {
        getRymidrDetails(dataTosend);
      }
    } else {
      console.log('Data not found!');
    }
  }, [qrImg, rymindrDetails, getRymidrDetails, user_id]);

  useEffect(() => {
    if (UpcomingRymindrs && UpcomingRymindrs.length > 0) {
      let filteredItem;
      if (Location.state && Location.state.id && id && date) {
        // Filter UpcomingRymindrs array based on id and date
        filteredItem = UpcomingRymindrs.find(item => item._id == Location.state.id && item.rymindr_date == date);
        console.log("filteredItem", filteredItem)
        if (!filteredItem) {
          // If no item matches the id and date, fallback to the first item
          filteredItem = UpcomingRymindrs[0];
        }
      } else {
        // If Location state doesn't exist or doesn't have id and date, use the first item
        filteredItem = UpcomingRymindrs[0];
      }

      if (filteredItem) {
        const dataTosend = {
          user_id,
          rymindr_id: filteredItem.rymindr_id || filteredItem.id,
          is_page: filteredItem.rymindr_id ? 'history' : 'bank_holiday', // Set the is_page based on the presence of rymindr_id
        };
        setSelectedItem(dataTosend.rymindr_id);
        console.log("filteredItem@@", filteredItem)
        setRymindrDetails(filteredItem);
        getRymidrDetails(dataTosend);
      }
    }
  }, [UpcomingRymindrs, Location.state, id, date, user_id]);




  // useEffect(() => {
  //   if (UpcomingRymindrs && UpcomingRymindrs.length > 0 && UpcomingRymindrs[0]) {
  //     if (UpcomingRymindrs[0].rymindr_id) {
  //       let dataTosend = {
  //         user_id,
  //         rymindr_id: UpcomingRymindrs[0].rymindr_id,
  //         is_page: 'history',
  //       };
  //       setSelectedItem(UpcomingRymindrs[0].rymindr_id);
  //       setRymindrDetails(UpcomingRymindrs[0]);
  //       if (Location.state && Location.state.post_id && id && date) {

  //         dataTosend = {
  //           user_id,
  //           rymindr_id: Location.state.post_id,
  //           is_page: 'history',
  //         };
  //         setSelectedItem(Location.state.post_id);
  //       }
  //       getRymidrDetails(dataTosend);
  //     } else if (UpcomingRymindrs[0].id) {
  //       // console.log("Execute", UpcomingRymindrs[0]);
  //       let dataTosend = {
  //         user_id,
  //         rymindr_id: UpcomingRymindrs[0].id,
  //         is_bank_holiday: 1,
  //       };
  //       setSelectedItem(UpcomingRymindrs[0].id);
  //       // setRymindrDetails(UpcomingRymindrs[0]);
  //       getRymidrDetails(dataTosend);
  //     }
  //   }
  //   // console.log("UpcomingRymindrs====", UpcomingRymindrs);
  // }, [UpcomingRymindrs]);

  // console.log('UpcomingRymindrs--', UpcomingRymindrs);

  // useMemo(() => {

  //   const userList = []
  //   if (rymindrDetails !== null && rymindrDetails.user_list && rymindrDetails.group_list) {
  //     rymindrDetails.user_list.forEach(element => {
  //       userList.push(element)
  //     })
  //     rymindrDetails.group_list.forEach(element => {
  //       element.group_member_list.forEach(ele => {
  //         userList.push(ele)
  //       })
  //     })
  //     setUserList(userList)
  //     var uniqueArray = removeDuplicates(userList, 'user_id')
  //     setUserList(uniqueArray)
  //   }
  // }, [rymindrDetails])
  // console.log('pageNum.current', pageNum.current);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseImageModal = () => {
    setOpenImage(false);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const handleCloseModal = () => {
    setOpen(false);
    setState([]);
    pageNum.current = 1;
    // setTimeout(() => {
    //   console.log("HERE1");
    //   setState([]);
    // }, 200);
  };
  const handleClose = () => {
    setOpenDelete(false);
  };

  const handleShowDetail = (id, rymindr_type) => {
    // const dataTosend = {
    //   user_id,
    //   rymindr_id: id,
    //   is_page: 'history'
    // }
    // getRymidrDetails(dataTosend)
    //console.log('id==',id)
    let dataTosend = {};

    if (rymindr_type == 'Holiday') {
      dataTosend = {
        user_id,
        rymindr_id: id,
        is_bank_holiday: 1,
      };
    } else {
      dataTosend = {
        user_id,
        rymindr_id: id,
        is_page: 'history',
      };
    }
    setShowPanel(false);
    getRymidrDetails(dataTosend);
  };

  const handleHideDetail = () => {
    setShowPanel(true);
  };
  const fetchRymidrDetails = (id, is_bank_holiday, rymindr_type) => {
    //setSelectedId(id);
    let dataTosend = {};
    if (rymindr_type == 'Holiday' && is_bank_holiday == 1) {
      dataTosend = {
        user_id,
        rymindr_id: id,
        is_bank_holiday: 1,
      };
    } else {
      dataTosend = {
        user_id,
        rymindr_id: id,
        is_page: 'history',
      };
    }
    setSelectedItem(id);
    getRymidrDetails(dataTosend);
  };

  const handleDelete = (type, recr_rymindr_id, rymindr_id, qiscus_id, item) => {
    setOpenDelete(true);
    const setdata = {
      type: type,
      recr_rymindr_id: recr_rymindr_id,
      rymindr_id,
      user_id,
      user_name: first_name + ' ' + last_name,
      qiscus_id,
    };
    setDeleterymindr(setdata);
    if (type != null) {
      setRymindrType(type);
    }
    var obj = [];
    obj.push(
      {
        value: recr_rymindr_id,
        label: item,
      },
      { value: 'All', label: 'All' }
    );
    setSelectDeleterymindr(obj);
  };

  const handleClickImageOpen = (file) => {
    setOpenImage(true);
    setSelectedImage(file);
  };
  const deleteRymindr = (reason1, category) => {
    var temp = {
      ...deleterymindr,
      reason: reason1,
      category: category,
    };
    if (category != '') {
      if (category == 'All') {
        temp.type = null;
      } else {
        temp.rymindr_id = category;
      }
    }
    delete_rymindr2(temp);
    setOpenDelete(false);
  };

  useMemo(() => {
    success_message && enqueueSnackbar(success_message, { variant: 'success' });
  }, [success_message]);
  const [expanded, setExpanded] = useState(false);


  // useEffect(() => {
  //   let mounted = true;
  //   setTimeout(function () {
  //     if (mounted) {
  //       success_message && enqueueSnackbar(success_message, { variant: 'success' });
  //     }
  //   }, 0);
  //   return function cleanup() {
  //     mounted = false;
  //   };
  // }, [success_message]);

  const classes = useStyles();

  const regex = '/<(.|\n)*?>/g';

  const Rymindrlist =
    UpcomingRymindrs != undefined && UpcomingRymindrs.length > 0 ? (
      UpcomingRymindrs.map((item, index) => (
        <>
          <Hidden mdUp implementation="css">
            {/* <ListItemStyled
              key={item.rymindr_id}
              button
              onClick={() =>
                handleShowDetail(item.rymindr_type == 'Holiday' ? item.id : item.rymindr_id, item.rymindr_type)
              }
              selected={
                item.rymindr_type == 'Holiday'
                  ? selectedItem
                    ? selectedItem === item.id
                    : !!(rymindrDetails && rymindrDetails.id === item.id)
                  : selectedItem
                  ? selectedItem === item.rymindr_id
                  : !!(rymindrDetails && rymindrDetails.rymindr_id === item.rymindr_id)
              }
            >
               */}
            <ListItemStyled
              key={item.id}
              button
              onClick={() => handleShowDetail(item.rymindr_type == 'Holiday' ? item.id : item.id, item.rymindr_type)}
              selected={
                item.rymindr_type == 'Holiday'
                  ? selectedItem
                    ? selectedItem === item.id
                    : !!(rymindrDetails && rymindrDetails.id === item.id)
                  : selectedItem
                    ? selectedItem === item.id
                    : !!(rymindrDetails && rymindrDetails.id === item.id)
              }
            >
              <ListItemAvatar>
                <AvatarShareStyled alt="semy Sharp" src={item.category_image} />
              </ListItemAvatar>
              <ListItemText
                primary={item.rymindr_type}
                secondary={
                  <>
                    <TypoListSubtext2 className="text-container">
                      <div className="text-container">
                        {item.note && item.note.replace(/<(.|\n)*?>/g, '').replace(/\&nbsp;/g, ' ')}
                      </div>
                    </TypoListSubtext2>
                    <TypoListSubtext>
                      {`${moment(item.rymindr_date).format('dddd DD MMMM YYYY')}  | ${moment(
                        item.rymindr_date + ' ' + item.rymindr_time
                      ).format('LT')}`}
                    </TypoListSubtext>
                  </>
                }
              />
            </ListItemStyled>
            <Divider variant="inset" component="li" />
          </Hidden>
          <Hidden smDown implementation="css">
            <ListItem
              key={item.id}
              button
              onClick={() =>
                fetchRymidrDetails(
                  item.rymindr_type == 'Holiday' && item.is_bank_holiday == 1 ? item.id : item.id,
                  item.is_bank_holiday,
                  item.rymindr_type
                )
              }
              // selected={selectedItem ? selectedItem === item.rymindr_id : rymindrDetails.rymindr_id === item.rymindr_id}
              selected={
                item.rymindr_type == 'Holiday'
                  ? selectedItem
                    ? selectedItem === item.id
                    : !!(rymindrDetails && rymindrDetails.id === item.id)
                  : selectedItem
                    ? selectedItem === item.id
                    : !!(rymindrDetails && rymindrDetails.id === item.id)
              }
            >
              <ListItemAvatar>
                <AvatarShareStyled alt="semy Sharp" src={item.category_image} />
              </ListItemAvatar>
              <ListItemText
                // primary={item.rymindr_type}
                secondary={
                  <>
                    <TypoListSubtext2>
                      {/* <div class="text-container"> */}
                      <div>
                        {item.note && (
                          <p>
                            {
                              expanded
                                ? item.note.replace(/<(.|\n)*?>/g, '').replace(/\&nbsp;/g, ' ')
                                : item.note.replace(/<(.|\n)*?>/g, '').replace(/\&nbsp;/g, ' ').length > 200
                                  ? `${item.note.replace(/<(.|\n)*?>/g, '').replace(/\&nbsp;/g, ' ').slice(0, 200)}...`
                                  : item.note.replace(/<(.|\n)*?>/g, '').replace(/\&nbsp;/g, ' ')
                            }

                          </p>
                        )}
                      </div>
                      {/* </div> */}
                    </TypoListSubtext2>
                    <TypoListSubtext>
                      {`${moment(item.rymindr_date).format('dddd DD MMMM YYYY')}  | ${moment(
                        item.rymindr_date + ' ' + item.rymindr_time
                      ).format('LT')}`}
                    </TypoListSubtext>
                  </>
                }
              />
            </ListItem>

            <Divider variant="inset" component="li" />
          </Hidden>
        </>
      ))
    ) : (
      <PaperStyled>
        <Box className={classes.content} style={{ paddingTop: '0%' }}>
          <Typography className={classes.commingSoonDesc} style={{ paddingTop: '2px' }}>
            No Rymindrs found.
            {/* <br/>Go ahead and create your first Rymindr. */}
          </Typography>
        </Box>
      </PaperStyled>
    );

  useEffect(() => {
    const dataToSend = {
      user_id,
    };
    getUpcommingRymindrs(dataToSend);
    console.log('dataToSend', dataToSend);
    // setRymindrDetails(upcommingrymindr && upcommingrymindr.upcomingList[0]);
  }, []);

  const generateQrCode = (rymindr_id) => {
    // alert('generateQrCode'+rymindr_id);
    const data = {
      user_id,
      rymindr_id,
    };
    generateQrCodeDB(data, history);
    if (rymindrDetails) {
      const dataTosend = {
        user_id,
        rymindr_id,
        is_page: 'history',
      };
      getRymidrDetails(dataTosend);
    } else console.log('not found');
  };

  // useMemo(() => {
  //   if (error == `Cannot read proprty 'push' of undefined`) {
  //     return true;
  //   } else {
  //     error && enqueueSnackbar(error, { variant: 'error' });
  //   }
  // }, [error]);

  useEffect(() => {
    let mounted = true;
    setTimeout(function () {
      if (mounted) {
        // console.log("error====", error);
        if (error == `Cannot read proprty 'push' of undefined`) {
          return true;
        } else {
          error && enqueueSnackbar(error, { variant: 'error' });
        }
      }
    }, 0);
    return function cleanup() {
      mounted = false;
    };
  }, [error]);

  // Query string params
  const { param, paramType } = useParams();

  useMemo(() => {
    if (param == undefined && searchVal == '') {
      setUpcomingRymindr(upcommingrymindr.upcomingList);
      setUpcomingRymindrCount(upcommingrymindr.count);
    }
  }, [props]);

  useEffect(() => {
    setRymindrCount(upcommingrymindr.count);
  }, [upcommingrymindr])

  console.log(upcommingrymindr, 'UpcomingRymindrs@@@');

  const handlesearch = (e) => {
    setSearch(e.target.value);
    const searchStr = e.target.value;
    console.log(searchStr, 'searchStr');
    if (searchStr.length > 1) {
      const filteredList = upcommingrymindr.upcomingList.filter(
        (obj) =>
          obj.category_name.toLowerCase().includes(searchStr.toLowerCase()) ||
          obj.rymindr_type.toLowerCase().includes(searchStr.toLowerCase()) ||
          obj.rymindr_date.toLowerCase().includes(searchStr.toLowerCase()) ||
          (obj.note !== null ? obj.note.toLowerCase().includes(searchStr.toLowerCase()) : null)
      );
      console.log('filteredList', filteredList);
      setUpcomingRymindr(filteredList);
      if (filteredList.length > 0) {
        setRymindrDetails(filteredList[0]);
      } else {
        setRymindrDetails({});
      }
    } else {
      setUpcomingRymindr(upcommingrymindr.upcomingList);
      if (upcommingrymindr.upcomingList.length > 0) {
        setRymindrDetails(upcommingrymindr.upcomingList[0]);
      } else {
        setRymindrDetails([]);
      }
    }
  };
  console.log(UpcomingRymindrs, 'UpcomingRymindrs');

  const downloadQRCode = () => {
    handleCloseImageModal();
    setOpenQR(false);
    setOpenDownloadImage(true);

    // Wait for a short delay to ensure the DOM is updated
    setTimeout(() => {
      // Find the 'qr-img' element
      const qrImgElement = document.getElementById('qr-img');

      // If the element is found
      if (qrImgElement) {
        // Use htmlToImage to convert the element to PNG
        htmlToImage.toPng(qrImgElement, { backgroundColor: '#FFFFFF' })
          .then(function (dataUrl) {
            // Create a temporary link to trigger download
            var link = document.createElement('a');
            link.href = dataUrl;
            link.download = 'qr-code.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(function (error) {
            console.error('Error generating QR code image:', error);
          })
          .finally(function () {
            // Reset download image state after completion
            setOpenDownloadImage(false);
          });
      } else {
        console.error("QR image element not found");
        setOpenDownloadImage(false);
      }
    }, 500); // Adjust delay as needed
  };


  const [isHidden, setIsHidden] = React.useState(true);
  const shareUrl = 'http://github.com';
  const title = 'GitHub';

  const [width, setWidth] = React.useState(0);
  const [width1, setWidth1] = React.useState(0);
  const measuredRef = React.useCallback((node) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);
  const measuredRef1 = React.useCallback((node) => {
    if (node !== null) {
      setWidth1(node.getBoundingClientRect().width);
    }
  }, []);
  useEffect(() => {
    const path = props.location.pathname;
    console.log('path: ', path);
    let ind = sidebar.find((x) => x.url == path);
    if (ind && ind.is_accessible == 0) {
      setAccessable(true);
    }
  }, [sidebar]);

  const data = Location; // Access the state object
  console.log("data@@@", data);

  console.log("id, date", id, date)
  // useEffect(() => {
  //   if (param !== undefined) {
  //     if (upcommingrymindr.hasOwnProperty('upcomingList')) {
  //       const filteredList = upcommingrymindr.upcomingList.filter(
  //         (obj) =>
  //           obj.category_name.toLowerCase().includes(param.toLowerCase()) ||
  //           obj.rymindr_type.toLowerCase().includes(param.toLowerCase()) ||
  //           obj.rymindr_date.toLowerCase().includes(param.toLowerCase()) ||

  //           (obj.note !== null ? obj.note.toLowerCase().includes(param.toLowerCase()) : null)
  //       );
  //       setUpcomingRymindr(filteredList);
  //       console.log(param, filteredList, 'filteredList');
  //     }
  //   }
  // }, [upcommingrymindr]);


  // useEffect(() => {
  //   if (param !== undefined && upcommingrymindr.hasOwnProperty('upcomingList')) {
  //     // Filter based on param
  //     const filteredByParam = upcommingrymindr.upcomingList.filter(
  //       obj =>
  //         obj.category_name.toLowerCase().includes(param.toLowerCase()) ||
  //         obj.rymindr_type.toLowerCase().includes(param.toLowerCase()) ||
  //         obj.rymindr_date.toLowerCase().includes(param.toLowerCase()) ||
  //         (obj.note !== null && obj.note.toLowerCase().includes(param.toLowerCase()))
  //     );

  //     // If id and date are provided, filter further based on id and date
  //     if (id && date) {
  //       const filteredList = filteredByParam.filter(
  //         obj => obj.id === id && obj.rymindr_date === date
  //       );
  //       setUpcomingRymindr(filteredList);
  //       console.log(param, filteredList, filteredByParam, 'filteredList');
  //     } else {
  //       // If id and date are not provided, use the filteredByParam list directly
  //       setUpcomingRymindr(filteredByParam);
  //       console.log(param, filteredByParam,filteredByParam, 'filteredByParam');
  //     }
  //   }
  // }, [upcommingrymindr, id, date, param]);
  useEffect(() => {
    if (param !== undefined && upcommingrymindr.hasOwnProperty('upcomingList')) {
      // Filter based on param
      const filteredByParam = upcommingrymindr.upcomingList.filter(
        obj =>
          obj.category_name.toLowerCase().includes(param.toLowerCase()) ||
          obj.rymindr_type.toLowerCase().includes(param.toLowerCase()) ||
          obj.rymindr_date.toLowerCase().includes(param.toLowerCase()) ||
          (obj.note !== null && obj.note.toLowerCase().includes(param.toLowerCase()))
      );

      let filteredList = [];
      if (id && date) {
        // If id and date are provided, filter further based on id and date
        filteredList = filteredByParam.filter(
          obj => obj.id === id && obj.rymindr_date === date
        );
      } else {
        // If id and date are not provided, use the filteredByParam list directly
        filteredList = [...filteredByParam];
      }

      // Extract data from filteredByParam that is not present in filteredList
      const additionalData = filteredByParam.filter(obj => !filteredList.some(item => item.id === obj.id));

      // Push additional data to filteredList
      filteredList.push(...additionalData);

      setUpcomingRymindr(filteredList);
      console.log(param, filteredList, 'filteredList');
    }
  }, [upcommingrymindr, id, date, param]);

  return is_accessible ? (
    <>
      <div style={is_accessible ? { pointerEvents: 'none', userSelect: 'none', filter: 'blur(5px)' } : {}}>
        <Grid
          noWrap
          className="main-wrap-head"
          id="main-rymindr"
          container
          style={{ marginBottom: 5 }}
          alignItems="center"
        >
          {/* <FullscreenSpinner open={loading} /> */}
          {/* <ResponsiveDialog
            open={openDelete}
            handleClose={handleClose}
            deleteRymindr={deleteRymindr}
            type="Rymindr"
          /> */}

          <ResponsiveDialog
            open={openDelete}
            handleClose={handleClose}
            getRymindrListData={selectdeleterymindr}
            deleteRymindr={deleteRymindr}
            type={setType}
          />
          {isSubscribed == 1 && (
            <Grid item xs={8}>
              <Box display="flex" alignItems="center">
                {showPanel ? null : (
                  <Hidden mdUp implementation="css">
                    <IconButton color="inherit" onClick={handleHideDetail}>
                      <ArrowBack />
                    </IconButton>
                  </Hidden>
                )}
                <TypoHeadStyled>
                  Upcoming <TypoHeadInnerStyled component="span">Rymindr</TypoHeadInnerStyled>
                </TypoHeadStyled>
              </Box>
            </Grid>
          )}
          {isSubscribed == 0 && (
            <Grid item xs={4}>
              <Box display="flex" alignItems="center">
                {showPanel ? null : (
                  <Hidden mdUp implementation="css">
                    <IconButton color="inherit" onClick={handleHideDetail}>
                      <ArrowBack />
                    </IconButton>
                  </Hidden>
                )}
                <TypoHeadStyled>
                  Upcoming <TypoHeadInnerStyled component="span">Rymindr</TypoHeadInnerStyled>
                </TypoHeadStyled>
              </Box>
            </Grid>
          )}
          {isSubscribed == 0 && (
            <Grid item xs={4}>
              <Box display="flex" alignItems="center">
                <Typography noWrap>
                  <Box fontWeight="fontWeightBold" m={1}>
                    {rymindrCount > -1 && (
                      <h6 style={{ paddingLeft: '5px', color: 'red' }}>
                        {5 - rymindrCount} Free Rymindrs Left
                      </h6>
                    )}
                  </Box>
                </Typography>

                <TypographyColor>
                  <Button
                    style={{
                      borderRadius: 35,
                      backgroundColor: 'rgb(244 169 64)',
                      padding: '10px 25px',
                      fontSize: '15px',
                      color: 'white',
                    }}
                    variant="contained"
                    onClick={() => history.push('/upgrade-rymindr')}
                  >
                    Upgrade Now
                  </Button>
                </TypographyColor>
              </Box>
            </Grid>
          )}
          <Grid item xs={4}>
            <Box display="flex" justifyContent="flex-end">
              <Tooltip
                title={
                  isSubscribed == 0 && UpcomingRymindrCount > 4
                    ? "You've reached the limit with the free plan.  Upgrade to unlock"
                    : ''
                }
                placement="top"
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => history.push('/create-rymindr')}
                  disabled={isSubscribed == 0 && UpcomingRymindrCount > 4 ? true : false}
                  startIcon={
                    <img
                      src={require('../../../assets/images/createrymindricon.png')}
                      style={{ height: 20, width: 20 }}
                    />
                  }
                >
                  CREATE NEW RYMINDR
                </Button>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>

        <Grid className="main-wrap-body upcmgRym-cont-wrap" container alignItems="stretch">
          {showPanel ? (
            <Grid item xs={12} md={4} className="pr-25 leftSide-cont">
              <Paper className={classes.paper}>
                <SearchFieldStyled
                  placeholder="Search Rymindr"
                  id="input-with-icon-textfield"
                  variant="outlined"
                  fullWidth
                  size="small"
                  onKeyUp={handlesearch}
                  // defaultValue={param !== undefined ? param : ''}
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />

                <List>{UpcomingRymindrs != undefined ? Rymindrlist : loading && <CircularSpinner />}</List>
              </Paper>
            </Grid>
          ) : null}
          {rymindrDetails != null ? (
            rymindrDetails.rymindr_type ? (
              <Grid
                ref={measuredRef}
                item
                xs={12}
                md={8}
                className={clsx(classes.rightPanel, 'rightSide-cont')}
                style={{ display: showPanel ? 'none' : 'block' }}
              >
                <Paper className={classes.paper}>
                  <GridStyled container alignItems="center">
                    <Grid xs={6}>
                      <BoxStyled alignItems="center">
                        <AvatarShareStyled alt="semy Sharp" src={rymindrDetails.category_image} />
                        <Typography noWrap>
                          <Box fontWeight="fontWeightBold" m={1}>
                            <h6 style={{ paddingLeft: '5px' }}>{rymindrDetails.rymindr_type}</h6>
                          </Box>
                        </Typography>
                      </BoxStyled>
                    </Grid>
                    <Grid xs={6}>
                      {rymindrDetails.rymindr_type == 'Holiday' && rymindrDetails.is_bank_holiday == 1 ? null : (
                        <Box display="flex" justifyContent="flex-end">
                          <ButtonPlain
                            onClick={() => history.push('/edit-rymindr/' + rymindrDetails.rymindr_id)}
                            disableRipple
                            startIcon={<Edit style={{ color: '#1872c0' }} />}
                          >
                            Edit
                          </ButtonPlain>
                          <ButtonPlain
                            disableRipple
                            onClick={() =>
                              handleDelete(
                                rymindrDetails.recurring_type,
                                rymindrDetails.id,
                                rymindrDetails.rymindr_id,
                                rymindrDetails.qiscus_id,
                                moment(rymindrDetails.rymindr_date).format('dddd DD MMMM YYYY')
                              )
                            }
                            startIcon={<Delete style={{ color: '#ec4d4b' }} />}
                          >
                            Delete
                          </ButtonPlain>
                        </Box>
                      )}
                    </Grid>
                  </GridStyled>

                  <ListStyled>
                    {rymindrDetails.rymindr_title ? (
                      <>
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <AvatarStyled
                              alt="semy Sharp"
                              variant="square"
                              src={require('../../../assets/images/note.png')}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={<TypoTitleStyled>Title</TypoTitleStyled>}
                            secondary={<Typography>{rymindrDetails.rymindr_title}</Typography>}
                          />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </>
                    ) : null}
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <AvatarStyled
                          alt="semy Sharp"
                          variant="square"
                          src={require('../../../assets/images/time.png')}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={<TypoTitleStyled>Date & Time</TypoTitleStyled>}
                        secondary={
                          <TypoContentStyled>
                            {`${moment(rymindrDetails.rymindr_date).format('dddd DD MMMM YYYY')}  | ${moment(
                              rymindrDetails.rymindr_date + ' ' + rymindrDetails.rymindr_time
                            ).format('LT')}`}
                          </TypoContentStyled>
                        }
                      />
                      {/* {console.log(
                      "rymindrDetails1111111",
                      rymindrDetails.qr_code
                    )}
                    {console.log(
                      "rymindrDetailsqrImg1111",
                      rymindrDetails.qrImg
                    )} */}

                      {rymindrDetails.rymindr_type == 'Holiday' && rymindrDetails.is_bank_holiday == 1 ? null : (
                        <BoxColorStyled display="flex" className="qrcode-box">
                          <Box display="flex" className="own-desc">
                            <BoxImgStyled className="own-name">
                              <AvatarStyled
                                alt="semy Sharp"
                                variant="square"
                                src={require('../../../assets/images/owner.png')}
                              />

                              <TypoTitleStyled className="own-name">Owner</TypoTitleStyled>
                            </BoxImgStyled>
                            <BoxStyled alignItems="center" className="own-pic">
                              <AvatarComntStyled alt="semy Sharp" src={rymindrDetails.profile_image} />
                              <Typography>{`${rymindrDetails.first_name}  ${rymindrDetails.last_name}`}</Typography>
                            </BoxStyled>
                          </Box>
                          {rymindrDetails.qr_code != null || qrImg != null ? (
                            <Box display="flex" alignItems="center" className="qrcode">
                              <BoxImgStyled className="qrcode-icon">
                                <img
                                  alt="qr"
                                  src={require('../../../assets/images/qr.png')}
                                  onClick={() =>
                                    handleClickQROpen(
                                      rymindrDetails.qr_code,
                                      rymindrDetails.user_qr_code_base64,
                                      rymindrDetails.qiscus_id
                                    )
                                  }
                                  style={{
                                    height: '20px',
                                    cursor: 'pointer',
                                  }}
                                />
                                <TypoTitleStyled>QR Code</TypoTitleStyled>
                              </BoxImgStyled>
                            </Box>
                          ) : (
                            <>
                              <Button
                                variant="outlined"
                                color="primary"
                                style={{ alignSelf: 'center' }}
                                onClick={() => generateQrCode(rymindrDetails.rymindr_id)}
                              >
                                {loading && <CircularSpinner />}
                                GET QR Code
                              </Button>
                            </>
                          )}
                        </BoxColorStyled>
                      )}

                      {/* QR CODE POPUP */}
                      <Dialog
                        open={openQR}
                        onClose={handleCloseQRModal}
                        aria-labelledby="form-dialog-title"
                        maxWidth="sm"
                        fullWidth
                      >
                        <DialogTitleStyled id="form-dialog-title">
                          <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box display="flex">
                              <TypoPopHeadStyled>QR Code</TypoPopHeadStyled>
                            </Box>
                            <IconButton color="default" onClick={handleCloseQRModal}>
                              <Close />
                            </IconButton>
                          </Box>
                        </DialogTitleStyled>
                        <DialogContentStyled>
                          <TypoBusStyled component="h2">{rymindrDetails.sc_bessi_name}</TypoBusStyled>
                          <TypoTxtStyled>Scan</TypoTxtStyled>
                          <img
                            src={rymindrDetails.qr_code_base64}
                            alt="semy Sharp"
                            style={{ height: 280, width: 280 }}
                          />
                          <TypoTxtStyled>Rymindr</TypoTxtStyled>
                          <TypoTxtStyled>{rymindrDetails.rymindr_type}</TypoTxtStyled>
                          <TypoTxtStyled>
                            {`${moment(rymindrDetails.rymindr_date).format('DD MMMM YYYY')} @ ${moment(
                              rymindrDetails.rymindr_date + ' ' + rymindrDetails.rymindr_time
                            ).format('LT')}`}
                          </TypoTxtStyled>

                          <Box display="flex" justifyContent="space-around" mt={3} mb={3}>
                            <Button onClick={downloadQRCode} style={{ marginRight: '8px' }}>
                              <GetApp color="primary" style={{ marginRight: 17 }} /> Download
                            </Button>{' '}
                            {/* <Button onClick={showShare}>
                            <Share color="primary" style={{ marginRight: 20 }} /> Share
                          </Button> */}
                          </Box>

                          {!isHidden && (
                            <Box display="flex" justifyContent="space-around" mt={3} mb={3}>
                              {/* <Link><img alt='semy Sharp' style={{ height: 48, width: 50 }} src={require('../../assets/images/ic_g+.png')} /></Link>
                      <Link><img alt='semy Sharp' style={{ height: 48, width: 48 }} src={require('../../assets/images/ic_in.png')} /></Link> */}
                              <LinkedinShareButton
                                url="https://www.linkedin.com/company/rymindr"
                                quote="Rymindr"
                                className="Demo__some-network__share-button"
                              >
                                <LinkedinIcon size={48} round />
                              </LinkedinShareButton>

                              {/* <Link><img alt='semy Sharp' style={{ height: 48, width: 48 }} src={require('../../assets/images/ic_twitter.png')} /></Link>
                      <Link><img alt='semy Sharp' style={{ height: 48, width: 48 }} src={require('../../assets/images/ic_fb.png')} /></Link> */}
                              <FacebookShareButton
                                url="https://www.facebook.com/Rymindr/"
                                quote="Rymindr"
                                className="Demo__some-network__share-button"
                              >
                                <FacebookIcon size={48} round />
                              </FacebookShareButton>

                              {/* <Link><img alt='semy Sharp' style={{ height: 48, width: 48 }} src={require('../../assets/images/ic_wts.png')} /></Link>
                  <Link><img alt='semy Sharp' style={{ height: 48, width: 48 }} src={require('../../assets/images/ic_email.png')} /></Link> */}
                              <EmailShareButton>
                                <EmailIcon size={48} round />
                              </EmailShareButton>
                            </Box>
                          )}
                        </DialogContentStyled>
                      </Dialog>
                      {/* QR CODE POPUP */}

                      {/* QR CODE DOWNLOAD POPUP openDownloadImage */}

                      <Dialog
                        className="myNode barcode-popup"
                        id="my-node"
                        open={openDownloadImage}
                        onClose={handleCloseDownloadModal}
                        aria-labelledby="form-dialog-title"
                        maxWidth="sm"
                        fullWidth
                      >
                        <div id="qr-img">
                          <DialogContentStyled>
                            <div className="logoHead">
                              <img src={require('../../../assets/images/rymindr_smiles_logo.png')} />
                            </div>
                            <br />
                            <TypoBusStyled component="h2">{rymindrDetails.sc_bessi_name}</TypoBusStyled>
                            <TypoTxtStyled>Scan</TypoTxtStyled>
                            <img src={rymindrDetails.qr_code_base64} alt="semy Sharp" className="barcode-img" />
                            <TypoTxtStyled>Rymindr</TypoTxtStyled>
                            <TypoTxtStyled>{rymindrDetails.rymindr_type}</TypoTxtStyled>
                            <TypoTxtStyled>
                              {`${moment(rymindrDetails.rymindr_date).format('DD MMMM YYYY')} @ ${moment(
                                rymindrDetails.rymindr_date + ' ' + rymindrDetails.rymindr_time
                              ).format('LT')}`}
                            </TypoTxtStyled>
                            <div className="poweredBy">
                              {/* <p>Powered by</p> */}
                              <img src={require('../../../assets/images/footer_logo.png')} style={{ height: '46px' }} />
                            </div>
                          </DialogContentStyled>
                        </div>
                      </Dialog>
                      {/* QR CODE DOWNLOAD POPUP */}
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <AvatarStyled
                          alt="semy Sharp"
                          variant="square"
                          src={require('../../../assets/images/note.png')}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={<TypoTitleStyled>About</TypoTitleStyled>}
                        secondary={
                          <Typography>
                            {rymindrDetails.rymindr_type == 'Holiday' && rymindrDetails.is_bank_holiday == 1
                              ? rymindrDetails.note
                                ? rymindrDetails.note
                                : rymindrDetails.description
                              : parse(rymindrDetails.note)}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    {rymindrDetails.rymindr_type == 'Holiday' &&
                      rymindrDetails.is_bank_holiday == 1 ? null : users_list.length > 0 ? (
                        <Box display="flex">
                          <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              <AvatarStyled
                                alt="semy Sharp"
                                variant="square"
                                src={require('../../../assets/images/share.png')}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={<TypoTitleStyled>Shared with</TypoTitleStyled>}
                              secondary={
                                <Box className="share-avatar-box" display="flex" justifyContent="space-between">
                                  <BoxStyled className={('share-avatar-box-inner', classes.overFlowHide)}>
                                    {users_list.map((record, id) => (
                                      <>
                                        {id >= 10 ? (
                                          false
                                        ) : record.is_status == 0 ? (
                                          <StyledBadgePending
                                            ref={measuredRef1}
                                            overlap="circle"
                                            anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'right',
                                            }}
                                            // variant='dot'
                                            badgeContent={
                                              <Update
                                                style={{
                                                  color: 'white',
                                                  fontSize: 10,
                                                }}
                                              />
                                            }
                                          >
                                            <AvatarShareStyled
                                              key={record.user_id}
                                              alt="semy Sharp"
                                              src={record.profile_image}
                                            />
                                          </StyledBadgePending>
                                        ) : record.is_status == 1 ? (
                                          <StyledBadge
                                            overlap="circle"
                                            anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'right',
                                            }}
                                            // variant='dot'
                                            badgeContent={
                                              <Check
                                                style={{
                                                  color: 'white',
                                                  fontSize: 10,
                                                }}
                                              />
                                            }
                                          >
                                            <AvatarShareStyled
                                              key={record.user_id}
                                              alt="semy Sharp"
                                              src={record.profile_image}
                                            />
                                          </StyledBadge>
                                        ) : (
                                          <StyledBadgeReject
                                            overlap="circle"
                                            anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'right',
                                            }}
                                            // variant='dot'
                                            badgeContent={
                                              <Close
                                                style={{
                                                  color: 'white',
                                                  fontSize: 10,
                                                }}
                                              />
                                            }
                                          >
                                            <AvatarShareStyled
                                              key={record.user_id}
                                              alt="semy Sharp"
                                              src={record.profile_image}
                                            />
                                          </StyledBadgeReject>
                                        )}
                                      </>
                                    ))}
                                    {/* {console.log("users_list-", users_list)} */}
                                    <Box
                                      style={{
                                        fontSize: 18,
                                        color: '#1abaff',
                                        padding: '10px 2px 0px 15px',
                                        cursor: 'pointer',
                                      }}
                                      onClick={handleClickOpen}
                                    >
                                      {users_list.length >= 10 && rymindrDetails &&
                                        rymindrDetails.remainingCount !== undefined
                                        && rymindrDetails.remainingCount !== null
                                        ? '+ ' + rymindrDetails.remainingCount
                                        : ''}
                                    </Box>
                                  </BoxStyled>
                                  <Box style={{ position: 'relative' }}>
                                    <Box>
                                      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                                        {' '}
                                        Status{' '}
                                      </Button>
                                    </Box>
                                  </Box>
                                </Box>
                              }
                            />
                          </ListItem>
                        </Box>
                      ) : null}
                    {rymindrDetails.rymindr_type == 'Holiday' && rymindrDetails.is_bank_holiday == 1 ? null : (
                      <Divider variant="inset" component="li" />
                    )}
                    {rymindrDetails.rymindr_type == 'Holiday' &&
                      rymindrDetails.is_bank_holiday == 1 ? null : rymindrDetails.attachment ? (
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <AvatarStyled
                              alt="semy Sharp"
                              variant="square"
                              src={require('../../../assets/images/attach.png')}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={<TypoTitleStyled>Attachment</TypoTitleStyled>}
                            secondary={
                              <Box display="flex" alignItems="center">
                                {rymindrDetails.attachment.length > 0
                                  ? rymindrDetails.attachment.map((file, index) => {
                                    if (file.image_name != undefined) {
                                      const extension_arr = file.image_name.split('.');
                                      const extension = extension_arr[1];
                                      const imagePath = 'https://rymindrapi.com/RymindrApi/public/attachment';

                                      if (
                                        extension == 'jpeg' ||
                                        extension == 'JPEG' ||
                                        extension == 'jpg' ||
                                        extension == 'png' ||
                                        extension == 'gif'
                                      ) {
                                        return (
                                          // <TypoStyled variant='subtitle1' component='p' key={index}>
                                          //   <img src={file.file_name} onClick={() => handleClickImageOpen(file.file_name)} className={classes.iconAttach} />{file.image_name}
                                          // </TypoStyled>
                                          <TypoStyled variant="subtitle1" component="p" key={index}>
                                            <img
                                              alt="igmg"
                                              src={file.file_name}
                                              onClick={() => handleClickImageOpen(file.file_name)}
                                              className={classes.iconAttach}
                                            />
                                            Image
                                          </TypoStyled>
                                        );
                                      } else if (extension == 'mp3') {
                                        return (
                                          // <TypoStyled variant='subtitle1' component='p' key={index}>
                                          //   <a href={file.file_name} target='_blank'>
                                          //     <Headset className={classes.iconAttach} />{file.image_name}
                                          //   </a>
                                          // </TypoStyled>
                                          <TypoStyled variant="subtitle1" component="p" key={index}>
                                            <a href={file.file_name} target="_blank">
                                              <Headset className={classes.iconAttach} />
                                              Audio
                                            </a>
                                          </TypoStyled>
                                        );
                                      } else if (extension == 'mp4') {
                                        return (
                                          // <TypoStyled variant='subtitle1' component='p' key={index}>
                                          //   <a href={file.file_name} target='_blank'>
                                          //     <Videocam className={classes.iconAttach} /> {file.image_name}
                                          //   </a>
                                          // </TypoStyled>
                                          <TypoStyled variant="subtitle1" component="p" key={index}>
                                            <a href={file.file_name} target="_blank">
                                              <Videocam className={classes.iconAttach} />
                                              Video
                                            </a>
                                          </TypoStyled>
                                        );
                                      } else if (extension == 'doc' || extension == 'docx' || extension == 'pdf') {
                                        return (
                                          // <TypoStyled variant='subtitle1' component='p' key={index}>
                                          //   <a href={file.file_name} target='_blank'>
                                          //     <Description className={classes.iconAttach} /> {file.image_name}
                                          //   </a>
                                          // </TypoStyled>
                                          <TypoStyled variant="subtitle1" component="p" key={index}>
                                            <a href={file.file_name} target="_blank">
                                              <Description className={classes.iconAttach} />
                                              Document
                                            </a>
                                          </TypoStyled>
                                        );
                                      } else {
                                        return (
                                          <TypoStyled variant="subtitle1" component="p" key={index}>
                                            <a href={file.file_name} target="_blank">
                                              <Description className={classes.iconAttach} />
                                              Document
                                            </a>
                                          </TypoStyled>
                                        );
                                      }
                                    } else {
                                    }
                                  })
                                  : 'No Attachments'}
                              </Box>
                            }
                          />
                        </ListItem>
                      ) : null}
                    {rymindrDetails.rymindr_type == 'Holiday' && rymindrDetails.is_bank_holiday == 1 ? null : (
                      <Divider variant="inset" component="li" />
                    )}
                    {rymindrDetails.rymindr_type == 'Holiday' && rymindrDetails.is_bank_holiday == 1 ? null : (
                      <Comments type="rymindr" postId={rymindrDetails.rymindr_id} postUserId={rymindrDetails.user_id} />
                    )}
                  </ListStyled>
                </Paper>

                <Dialog
                  open={openImage}
                  onClose={handleCloseImageModal}
                  aria-labelledby="form-dialog-title"
                  maxWidth="xl"
                >
                  <DialogTitleStyled id="form-dialog-title">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Box display="flex">
                        <TypoPopHeadStyled>Attachment</TypoPopHeadStyled>
                      </Box>
                      <IconButton color="default" onClick={handleCloseImageModal}>
                        <Close />
                      </IconButton>
                    </Box>
                  </DialogTitleStyled>
                  <DialogContent>
                    <center>
                      <img
                        alt="img"
                        src={selectedImage}
                        style={{
                          width: '100%',
                          height: '100%',
                          maxHeight: 800,
                          maxWidth: 1200,
                        }}
                      // style={{ maxWidth:'-webkit-fill-available' }}
                      />{' '}
                    </center>
                  </DialogContent>
                </Dialog>
                {/* STATUS POPUP */}
                <Dialog open={open} onClose={handleCloseModal} maxWidth="md" fullWidth>
                  <DialogTitleStyled id="form-dialog-title">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Box display="flex">
                        <TypoPopHeadStyled>Rymindr Status</TypoPopHeadStyled>
                        <TypoPopHeadStyled style={{ color: '#607383' }} className="ml-20">
                          {' '}
                          {accepted} out of {total} people read
                          {/* {rymindrDetails.remainingCount > 0 ? rymindrDetails.remainingCount + users_list.length : users_list.length}  */}{' '}
                        </TypoPopHeadStyled>
                      </Box>
                      <IconButton color="default" onClick={handleCloseModal}>
                        <Close />
                      </IconButton>
                    </Box>
                  </DialogTitleStyled>
                  <DialogContent id="scrollElement" style={{ maxHeight: '806px' }}>
                    <List>
                      <div>
                        {/* {console.log("state-", state)}
                      {console.log("ele1-", statusList)} */}
                        {
                          state.length > 0 ?
                            <>
                              {state?.map((element) => (
                                <ListItem alignItems="center">
                                  <ListItemAvatar>
                                    <AvatarShareStyled alt="semy Sharp" src={element.profile_image} />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={
                                      <Box display="flex" justifyContent="space-between">
                                        <Typography>{element.first_name + ' ' + element.last_name}</Typography>
                                        <Box></Box>
                                        <Box display="flex" justifyContent="center" alignItems="center">
                                          {element.is_status == 0 ? (
                                            <>
                                              <FiberManualRecord
                                                style={{
                                                  color: '#1773bf',
                                                  fontSize: 14,
                                                }}
                                              />
                                              <TypoStatusStyled className="ml-20 mr-20">Pending</TypoStatusStyled>
                                            </>
                                          ) : element.is_status == 1 ? (
                                            <>
                                              <FiberManualRecord
                                                style={{
                                                  color: '#44b700',
                                                  fontSize: 14,
                                                }}
                                              />
                                              <TypoStatusStyledAccept className="ml-20 mr-20">
                                                Accepted
                                              </TypoStatusStyledAccept>
                                            </>
                                          ) : (
                                            <>
                                              <FiberManualRecord
                                                style={{
                                                  color: '#FF0000',
                                                  fontSize: 14,
                                                }}
                                              />
                                              <TypoStatusStyledReject className="ml-20 mr-20">
                                                Declined
                                              </TypoStatusStyledReject>
                                            </>
                                          )}
                                        </Box>
                                      </Box>
                                    }
                                  />
                                </ListItem>
                                // <Divider variant="inset" component="li" />
                              ))}
                            </> :
                            <CircularProgress
                              variant="indeterminate"
                              disableShrink
                              sx={{
                                color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                                animationDuration: '550ms',

                                [`& .${circularProgressClasses.circle}`]: {
                                  strokeLinecap: 'round',
                                },
                              }}
                              size={30}
                              thickness={4}
                              {...props}
                            />
                        }
                        {/* {state?.map((element) => (
                          <ListItem alignItems="center">
                            <ListItemAvatar>
                              <AvatarShareStyled alt="semy Sharp" src={element.profile_image} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Box display="flex" justifyContent="space-between">
                                  <Typography>{element.first_name + ' ' + element.last_name}</Typography>
                                  <Box></Box>
                                  <Box display="flex" justifyContent="center" alignItems="center">
                                    {element.is_status == 0 ? (
                                      <>
                                        <FiberManualRecord
                                          style={{
                                            color: '#1773bf',
                                            fontSize: 14,
                                          }}
                                        />
                                        <TypoStatusStyled className="ml-20 mr-20">Pending</TypoStatusStyled>
                                      </>
                                    ) : element.is_status == 1 ? (
                                      <>
                                        <FiberManualRecord
                                          style={{
                                            color: '#44b700',
                                            fontSize: 14,
                                          }}
                                        />
                                        <TypoStatusStyledAccept className="ml-20 mr-20">
                                          Accepted
                                        </TypoStatusStyledAccept>
                                      </>
                                    ) : (
                                      <>
                                        <FiberManualRecord
                                          style={{
                                            color: '#FF0000',
                                            fontSize: 14,
                                          }}
                                        />
                                        <TypoStatusStyledReject className="ml-20 mr-20">
                                          Declined
                                        </TypoStatusStyledReject>
                                      </>
                                    )}
                                  </Box>
                                </Box>
                              }
                            />
                          </ListItem>
                          // <Divider variant="inset" component="li" />
                        ))} */}
                      </div>
                    </List>
                    {Loading ? (
                      <Box sx={{ width: 'fit-content', margin: 'auto' }}>
                        <CircularProgress
                          variant="indeterminate"
                          disableShrink
                          sx={{
                            color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                            animationDuration: '550ms',

                            [`& .${circularProgressClasses.circle}`]: {
                              strokeLinecap: 'round',
                            },
                          }}
                          size={30}
                          thickness={4}
                          {...props}
                        />
                      </Box>
                    ) : (
                      <div style={{ minHeight: 40, width: 'auto' }}></div>
                    )}
                  </DialogContent>
                </Dialog>
                {/* STATUS POPUP */}
              </Grid>
            ) : (
              <Grid
                ref={measuredRef}
                item
                xs={12}
                md={8}
                className={classes.rightPanel}
                style={{ display: showPanel ? 'none' : 'block' }}
              >
                <PaperStyled>
                  <Box className={classes.content}>
                    <CardMedia
                      className={classes.icon}
                      image={RymindrOriginal}
                      title="Upcoming Rymindr"
                      component="img"
                    />
                    <Typography className={classes.commingSoonDesc} style={{ paddingTop: '2px' }}>
                      No record Found
                    </Typography>
                  </Box>
                </PaperStyled>
              </Grid>
            )
          ) : (
            <Grid
              ref={measuredRef}
              item
              xs={12}
              md={8}
              className={classes.rightPanel}
              style={{ display: showPanel ? 'none' : 'block' }}
            >
              <PaperStyled>
                <Box className={classes.content}>
                  <CardMedia className={classes.icon} image={RymindrOriginal} title="Upcoming " component="img" />
                </Box>
              </PaperStyled>
            </Grid>
          )}
        </Grid>
      </div>
      {is_accessible ? (
        <>
          <p
            style={{
              fontSize: '20px',
              textAlign: 'center',
              position: 'fixed',
              top: '50vh',
              left: '45vw',
              background: '#a9a9a99e',
              fontWeight: 'bold',
              padding: '100px',
              width: '40%',
            }}
          >
            You don't have permission to access the page content , Please contact account admin.
          </p>
        </>
      ) : null}
    </>
  ) : (
    <>
      <Grid
        noWrap
        className="main-wrap-head"
        id="main-rymindr"
        container
        style={{ marginBottom: 5 }}
        alignItems="center"
      >
        {/* <FullscreenSpinner open={loading} /> */}
        {/* <ResponsiveDialogDelete
          open={openDelete}
          handleClose={handleClose}
          deleteRymindr={deleteRymindr}
          type="Rymindr"
        /> */}
        <ResponsiveDialog
          open={openDelete}
          handleClose={handleClose}
          getRymindrListData={selectdeleterymindr}
          deleteRymindr={deleteRymindr}
          type={setType}
        />
        {isSubscribed == 1 && (
          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              {showPanel ? null : (
                <Hidden mdUp implementation="css">
                  <IconButton color="inherit" onClick={handleHideDetail}>
                    <ArrowBack />
                  </IconButton>
                </Hidden>
              )}
              <TypoHeadStyled>
                Upcoming <TypoHeadInnerStyled component="span">Rymindr</TypoHeadInnerStyled>
              </TypoHeadStyled>
            </Box>
          </Grid>
        )}
        {isSubscribed == 0 && (
          <Grid item xs={4}>
            <Box display="flex" alignItems="center">
              {showPanel ? null : (
                <Hidden mdUp implementation="css">
                  <IconButton color="inherit" onClick={handleHideDetail}>
                    <ArrowBack />
                  </IconButton>
                </Hidden>
              )}
              <TypoHeadStyled>
                Upcoming <TypoHeadInnerStyled component="span">Rymindr</TypoHeadInnerStyled>
              </TypoHeadStyled>
            </Box>
          </Grid>
        )}
        {isSubscribed == 0 && (
          <Grid item xs={4}>
            <Box display="flex" alignItems="center">
              <Typography noWrap>
                <Box fontWeight="fontWeightBold" m={1}>
                  {rymindrCount > -1 && (
                    <h6 style={{ paddingLeft: '5px', color: 'red' }}>
                      {5 - rymindrCount} Free Rymindrs Left
                    </h6>
                  )}
                </Box>
              </Typography>

              <TypographyColor>
                <Button
                  style={{
                    borderRadius: 35,
                    backgroundColor: 'rgb(244 169 64)',
                    padding: '10px 25px',
                    fontSize: '15px',
                    color: 'white',
                  }}
                  variant="contained"
                  onClick={() => history.push('/upgrade-rymindr')}
                >
                  Upgrade Now
                </Button>
              </TypographyColor>
            </Box>
          </Grid>
        )}
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end">
            <Tooltip
              title={
                isSubscribed == 0 && UpcomingRymindrCount > 4
                  ? "You've reached the limit with the free plan.  Upgrade to unlock"
                  : ''
              }
              placement="top"
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => history.push('/create-rymindr')}
                disabled={isSubscribed == 0 && rymindrCount > 4 ? true : false}
                startIcon={
                  <img
                    src={require('../../../assets/images/createrymindricon.png')}
                    style={{ height: 20, width: 20 }}
                  />
                }
              >
                CREATE NEW RYMINDR
              </Button>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>

      <Grid className="main-wrap-body upcmgRym-cont-wrap" container alignItems="stretch">
        {showPanel ? (
          <Grid item xs={12} md={4} className="pr-25 leftSide-cont">
            <Paper className={classes.paper}>
              <SearchFieldStyled
                placeholder="Search Rymindr"
                id="input-with-icon-textfield"
                variant="outlined"
                fullWidth
                size="small"
                onKeyUp={handlesearch}
                defaultValue={param !== undefined ? param : ''}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />

              <List>{UpcomingRymindrs != undefined ? Rymindrlist : loading && <CircularSpinner />}</List>
            </Paper>
          </Grid>
        ) : null}
        {rymindrDetails != null ? (
          rymindrDetails.rymindr_type ? (
            <Grid
              ref={measuredRef}
              item
              xs={12}
              md={8}
              className={clsx(classes.rightPanel, 'rightSide-cont')}
              style={{ display: showPanel ? 'none' : 'block' }}
            >
              <Paper className={classes.paper}>
                <GridStyled container alignItems="center">
                  <Grid xs={6}>
                    <BoxStyled alignItems="center">
                      <AvatarShareStyled alt="semy Sharp" src={rymindrDetails.category_image} />
                      <Typography noWrap>
                        <Box fontWeight="fontWeightBold" m={1}>
                          <h6 style={{ paddingLeft: '5px' }}>{rymindrDetails.rymindr_type}</h6>
                        </Box>
                      </Typography>
                    </BoxStyled>
                  </Grid>
                  <Grid xs={6}>
                    {rymindrDetails.rymindr_type == 'Holiday' && rymindrDetails.is_bank_holiday == 1 ? null : (
                      <Box display="flex" justifyContent="flex-end">
                        <ButtonPlain
                          onClick={() => history.push('/edit-rymindr/' + rymindrDetails.rymindr_id)}
                          disableRipple
                          startIcon={<Edit style={{ color: '#1872c0' }} />}
                        >
                          Edit
                        </ButtonPlain>
                        <ButtonPlain
                          disableRipple
                          onClick={() =>
                            handleDelete(
                              rymindrDetails.recurring_type,
                              rymindrDetails.id,
                              rymindrDetails.rymindr_id,
                              rymindrDetails.qiscus_id,
                              moment(rymindrDetails.rymindr_date).format('dddd DD MMMM YYYY')
                            )
                          }
                          startIcon={<Delete style={{ color: '#ec4d4b' }} />}
                        >
                          Delete
                        </ButtonPlain>
                      </Box>
                    )}
                  </Grid>
                </GridStyled>

                <ListStyled>
                  {rymindrDetails.rymindr_title ? (
                    <>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <AvatarStyled
                            alt="semy Sharp"
                            variant="square"
                            src={require('../../../assets/images/note.png')}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<TypoTitleStyled>Title</TypoTitleStyled>}
                          secondary={<Typography>{rymindrDetails.rymindr_title}</Typography>}
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </>
                  ) : null}
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <AvatarStyled
                        alt="semy Sharp"
                        variant="square"
                        src={require('../../../assets/images/time.png')}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<TypoTitleStyled>Date & Time</TypoTitleStyled>}
                      secondary={
                        <TypoContentStyled>
                          {`${moment(rymindrDetails.rymindr_date).format('dddd DD MMMM YYYY')}  | ${moment(
                            rymindrDetails.rymindr_date + ' ' + rymindrDetails.rymindr_time
                          ).format('LT')}`}
                        </TypoContentStyled>
                      }
                    />
                    {/* {console.log(
                      "rymindrDetails1111111",
                      rymindrDetails.qr_code
                    )}
                    {console.log(
                      "rymindrDetailsqrImg1111",
                      rymindrDetails.qrImg
                    )} */}

                    {rymindrDetails.rymindr_type == 'Holiday' && rymindrDetails.is_bank_holiday == 1 ? null : (
                      <BoxColorStyled display="flex" className="qrcode-box">
                        <Box display="flex" className="own-desc">
                          <BoxImgStyled className="own-name">
                            <AvatarStyled
                              alt="semy Sharp"
                              variant="square"
                              src={require('../../../assets/images/owner.png')}
                            />

                            <TypoTitleStyled className="own-name">Owner</TypoTitleStyled>
                          </BoxImgStyled>
                          <BoxStyled alignItems="center" className="own-pic">
                            <AvatarComntStyled alt="semy Sharp" src={rymindrDetails.profile_image} />
                            <Typography>{`${rymindrDetails.first_name}  ${rymindrDetails.last_name}`}</Typography>
                          </BoxStyled>
                        </Box>
                        {rymindrDetails.qr_code != null || qrImg != null ? (
                          <Box display="flex" alignItems="center" className="qrcode">
                            <BoxImgStyled className="qrcode-icon">
                              <img
                                alt="qr-code"
                                src={require('../../../assets/images/qr.png')}
                                onClick={() =>
                                  handleClickQROpen(
                                    rymindrDetails.qr_code,
                                    rymindrDetails.user_qr_code_base64,
                                    rymindrDetails.qiscus_id
                                  )
                                }
                                style={{
                                  height: '20px',
                                  cursor: 'pointer',
                                }}
                              />
                              <TypoTitleStyled>QR Code</TypoTitleStyled>
                            </BoxImgStyled>
                          </Box>
                        ) : (
                          <>
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{ alignSelf: 'center' }}
                              onClick={() => generateQrCode(rymindrDetails.rymindr_id)}
                            >
                              {loading && <CircularSpinner />}
                              GET QR Code
                            </Button>
                          </>
                        )}
                      </BoxColorStyled>
                    )}

                    {/* QR CODE POPUP */}
                    <Dialog
                      open={openQR}
                      onClose={handleCloseQRModal}
                      aria-labelledby="form-dialog-title"
                      maxWidth="sm"
                      fullWidth
                    >
                      <DialogTitleStyled id="form-dialog-title">
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Box display="flex">
                            <TypoPopHeadStyled>QR Code</TypoPopHeadStyled>
                          </Box>
                          <IconButton color="default" onClick={handleCloseQRModal}>
                            <Close />
                          </IconButton>
                        </Box>
                      </DialogTitleStyled>
                      <DialogContentStyled>
                        <TypoBusStyled component="h2">{rymindrDetails.sc_bessi_name}</TypoBusStyled>
                        <TypoTxtStyled>Scan</TypoTxtStyled>
                        <img src={rymindrDetails.qr_code_base64} alt="semy Sharp" style={{ height: 280, width: 280 }} />
                        <TypoTxtStyled>Rymindr</TypoTxtStyled>
                        <TypoTxtStyled>{rymindrDetails.rymindr_type}</TypoTxtStyled>
                        <TypoTxtStyled>
                          {`${moment(rymindrDetails.rymindr_date).format('DD MMMM YYYY')} @ ${moment(
                            rymindrDetails.rymindr_date + ' ' + rymindrDetails.rymindr_time
                          ).format('LT')}`}
                        </TypoTxtStyled>

                        <Box display="flex" justifyContent="space-around" mt={3} mb={3}>
                          <Button onClick={downloadQRCode} style={{ marginRight: '8px' }}>
                            <GetApp color="primary" style={{ marginRight: 17 }} /> Download
                          </Button>{' '}
                          {/* <Button onClick={showShare}>
                            <Share color="primary" style={{ marginRight: 20 }} /> Share
                          </Button> */}
                        </Box>

                        {!isHidden && (
                          <Box display="flex" justifyContent="space-around" mt={3} mb={3}>
                            {/* <Link><img alt='semy Sharp' style={{ height: 48, width: 50 }} src={require('../../assets/images/ic_g+.png')} /></Link>
                      <Link><img alt='semy Sharp' style={{ height: 48, width: 48 }} src={require('../../assets/images/ic_in.png')} /></Link> */}
                            <LinkedinShareButton
                              url="https://www.linkedin.com/company/rymindr"
                              quote="Rymindr"
                              className="Demo__some-network__share-button"
                            >
                              <LinkedinIcon size={48} round />
                            </LinkedinShareButton>

                            {/* <Link><img alt='semy Sharp' style={{ height: 48, width: 48 }} src={require('../../assets/images/ic_twitter.png')} /></Link>
                      <Link><img alt='semy Sharp' style={{ height: 48, width: 48 }} src={require('../../assets/images/ic_fb.png')} /></Link> */}
                            <FacebookShareButton
                              url="https://www.facebook.com/Rymindr/"
                              quote="Rymindr"
                              className="Demo__some-network__share-button"
                            >
                              <FacebookIcon size={48} round />
                            </FacebookShareButton>

                            {/* <Link><img alt='semy Sharp' style={{ height: 48, width: 48 }} src={require('../../assets/images/ic_wts.png')} /></Link>
                  <Link><img alt='semy Sharp' style={{ height: 48, width: 48 }} src={require('../../assets/images/ic_email.png')} /></Link> */}
                            <EmailShareButton>
                              <EmailIcon size={48} round />
                            </EmailShareButton>
                          </Box>
                        )}
                      </DialogContentStyled>
                    </Dialog>
                    {/* QR CODE POPUP */}

                    {/* QR CODE DOWNLOAD POPUP openDownloadImage */}

                    <Dialog
                      className="myNode barcode-popup"
                      id="my-node"
                      open={openDownloadImage}
                      onClose={handleCloseDownloadModal}
                      aria-labelledby="form-dialog-title"
                      maxWidth="sm"
                      fullWidth
                    >
                      <div id="qr-img">
                        <DialogContentStyled>
                          <div className="logoHead">
                            <img src={require('../../../assets/images/rymindr_smiles_logo.png')} />
                          </div>
                          <br />
                          <TypoBusStyled component="h2">{rymindrDetails.sc_bessi_name}</TypoBusStyled>
                          <TypoTxtStyled>Scan</TypoTxtStyled>
                          <img src={rymindrDetails.qr_code_base64} alt="semy Sharp" className="barcode-img" />
                          <TypoTxtStyled>Rymindr</TypoTxtStyled>
                          <TypoTxtStyled>{rymindrDetails.rymindr_type}</TypoTxtStyled>
                          <TypoTxtStyled>
                            {`${moment(rymindrDetails.rymindr_date).format('DD MMMM YYYY')} @ ${moment(
                              rymindrDetails.rymindr_date + ' ' + rymindrDetails.rymindr_time
                            ).format('LT')}`}
                          </TypoTxtStyled>
                          <div className="poweredBy">
                            {/* <p>Powered by</p> */}
                            <img src={require('../../../assets/images/footer_logo.png')} style={{ height: '46px' }} />
                          </div>
                        </DialogContentStyled>
                      </div>
                    </Dialog>
                    {/* QR CODE DOWNLOAD POPUP */}
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <AvatarStyled
                        alt="semy Sharp"
                        variant="square"
                        src={require('../../../assets/images/note.png')}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<TypoTitleStyled>About</TypoTitleStyled>}
                      secondary={
                        <Typography>
                          {rymindrDetails.rymindr_type == 'Holiday' && rymindrDetails.is_bank_holiday == 1
                            ? rymindrDetails.note
                              ? rymindrDetails.note
                              : rymindrDetails.description
                            : parse(rymindrDetails.note)}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  {rymindrDetails.rymindr_type == 'Holiday' &&
                    rymindrDetails.is_bank_holiday == 1 ? null : users_list.length > 0 ? (
                      <Box display="flex">
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <AvatarStyled
                              alt="semy Sharp"
                              variant="square"
                              src={require('../../../assets/images/share.png')}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={<TypoTitleStyled>Shared with</TypoTitleStyled>}
                            secondary={
                              <Box className="share-avatar-box" display="flex" justifyContent="space-between">
                                <BoxStyled className={('share-avatar-box-inner', classes.overFlowHide)}>
                                  {users_list.map((record, id) => (
                                    <>
                                      {id >= 10 ? (
                                        false
                                      ) : record.is_status == 0 ? (
                                        <StyledBadgePending
                                          ref={measuredRef1}
                                          overlap="circle"
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                          }}
                                          // variant='dot'
                                          badgeContent={
                                            <Update
                                              style={{
                                                color: 'white',
                                                fontSize: 10,
                                              }}
                                            />
                                          }
                                        >
                                          <AvatarShareStyled
                                            key={record.user_id}
                                            alt="semy Sharp"
                                            src={record.profile_image}
                                          />
                                        </StyledBadgePending>
                                      ) : record.is_status == 1 ? (
                                        <StyledBadge
                                          overlap="circle"
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                          }}
                                          // variant='dot'
                                          badgeContent={
                                            <Check
                                              style={{
                                                color: 'white',
                                                fontSize: 10,
                                              }}
                                            />
                                          }
                                        >
                                          <AvatarShareStyled
                                            key={record.user_id}
                                            alt="semy Sharp"
                                            src={record.profile_image}
                                          />
                                        </StyledBadge>
                                      ) : (
                                        <StyledBadgeReject
                                          overlap="circle"
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                          }}
                                          // variant='dot'
                                          badgeContent={
                                            <Close
                                              style={{
                                                color: 'white',
                                                fontSize: 10,
                                              }}
                                            />
                                          }
                                        >
                                          <AvatarShareStyled
                                            key={record.user_id}
                                            alt="semy Sharp"
                                            src={record.profile_image}
                                          />
                                        </StyledBadgeReject>
                                      )}
                                    </>
                                  ))}
                                  {/* {console.log("users_list-", users_list)} */}
                                  <Box
                                    style={{
                                      fontSize: 18,
                                      color: '#1abaff',
                                      padding: '10px 2px 0px 15px',
                                    }}
                                    onClick={handleClickOpen}
                                  >
                                    {
                                      users_list.length == "undefined" || users_list.length == "null" ||
                                        users_list.length == undefined || users_list.length == null ? " " :
                                        <>
                                          {users_list.length >= 10 ? '+ ' + rymindrDetails.remainingCount : ''} </>
                                    }

                                  </Box>
                                </BoxStyled>
                                <Box style={{ position: 'relative' }}>
                                  <Box>
                                    <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                                      {' '}
                                      Status{' '}
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            }
                          />
                        </ListItem>
                      </Box>
                    ) : null}
                  {rymindrDetails.rymindr_type == 'Holiday' && rymindrDetails.is_bank_holiday == 1 ? null : (
                    <Divider variant="inset" component="li" />
                  )}
                  {rymindrDetails.rymindr_type == 'Holiday' &&
                    rymindrDetails.is_bank_holiday == 1 ? null : rymindrDetails.attachment ? (
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <AvatarStyled
                            alt="semy Sharp"
                            variant="square"
                            src={require('../../../assets/images/attach.png')}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<TypoTitleStyled>Attachment</TypoTitleStyled>}
                          secondary={
                            <Box display="flex" alignItems="center">
                              {rymindrDetails.attachment.length > 0
                                ? rymindrDetails.attachment.map((file, index) => {
                                  if (file.image_name != undefined) {
                                    const extension_arr = file.image_name.split('.');
                                    const extension = extension_arr[1];
                                    const imagePath = 'https://rymindrapi.com/RymindrApi/public/attachment';

                                    if (
                                      extension == 'jpeg' ||
                                      extension == 'JPEG' ||
                                      extension == 'jpg' ||
                                      extension == 'png' ||
                                      extension == 'gif'
                                    ) {
                                      return (
                                        // <TypoStyled variant='subtitle1' component='p' key={index}>
                                        //   <img src={file.file_name} onClick={() => handleClickImageOpen(file.file_name)} className={classes.iconAttach} />{file.image_name}
                                        // </TypoStyled>
                                        <TypoStyled variant="subtitle1" component="p" key={index}>
                                          <img
                                            alt="igmg"
                                            src={file.file_name}
                                            onClick={() => handleClickImageOpen(file.file_name)}
                                            className={classes.iconAttach}
                                          />
                                          Image
                                        </TypoStyled>
                                      );
                                    } else if (extension == 'mp3') {
                                      return (
                                        // <TypoStyled variant='subtitle1' component='p' key={index}>
                                        //   <a href={file.file_name} target='_blank'>
                                        //     <Headset className={classes.iconAttach} />{file.image_name}
                                        //   </a>
                                        // </TypoStyled>
                                        <TypoStyled variant="subtitle1" component="p" key={index}>
                                          <a href={file.file_name} target="_blank">
                                            <Headset className={classes.iconAttach} />
                                            Audio
                                          </a>
                                        </TypoStyled>
                                      );
                                    } else if (extension == 'mp4') {
                                      return (
                                        // <TypoStyled variant='subtitle1' component='p' key={index}>
                                        //   <a href={file.file_name} target='_blank'>
                                        //     <Videocam className={classes.iconAttach} /> {file.image_name}
                                        //   </a>
                                        // </TypoStyled>
                                        <TypoStyled variant="subtitle1" component="p" key={index}>
                                          <a href={file.file_name} target="_blank">
                                            <Videocam className={classes.iconAttach} />
                                            Video
                                          </a>
                                        </TypoStyled>
                                      );
                                    } else if (extension == 'doc' || extension == 'docx' || extension == 'pdf') {
                                      return (
                                        // <TypoStyled variant='subtitle1' component='p' key={index}>
                                        //   <a href={file.file_name} target='_blank'>
                                        //     <Description className={classes.iconAttach} /> {file.image_name}
                                        //   </a>
                                        // </TypoStyled>
                                        <TypoStyled variant="subtitle1" component="p" key={index}>
                                          <a href={file.file_name} target="_blank">
                                            {console.log("file.file_name", file.file_name)}
                                            <Description className={classes.iconAttach} />
                                            Document
                                          </a>
                                        </TypoStyled>
                                      );
                                    } else {
                                      return (
                                        <TypoStyled variant="subtitle1" component="p" key={index}>
                                          <a href={file.file_name} target="_blank">
                                            <Description className={classes.iconAttach} />
                                            Document
                                          </a>
                                        </TypoStyled>
                                      );
                                    }
                                  } else {
                                  }
                                })
                                : 'No Attachments'}
                            </Box>
                          }
                        />
                      </ListItem>
                    ) : null}
                  {rymindrDetails.rymindr_type == 'Holiday' && rymindrDetails.is_bank_holiday == 1 ? null : (
                    <Divider variant="inset" component="li" />
                  )}
                  {rymindrDetails.rymindr_type == 'Holiday' && rymindrDetails.is_bank_holiday == 1 ? null : (
                    <Comments type="rymindr" postId={rymindrDetails.rymindr_id} postUserId={rymindrDetails.user_id} />
                  )}
                </ListStyled>
              </Paper>

              <Dialog
                open={openImage}
                onClose={handleCloseImageModal}
                aria-labelledby="form-dialog-title"
                maxWidth="xl"
              >
                <DialogTitleStyled id="form-dialog-title">
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex">
                      <TypoPopHeadStyled>Attachment</TypoPopHeadStyled>
                    </Box>
                    <IconButton color="default" onClick={handleCloseImageModal}>
                      <Close />
                    </IconButton>
                  </Box>
                </DialogTitleStyled>
                <DialogContent>
                  <center>
                    <img
                      alt="img"
                      src={selectedImage}
                      style={{
                        width: '100%',
                        height: '100%',
                        maxHeight: 800,
                        maxWidth: 1200,
                      }}
                    // style={{ maxWidth:'-webkit-fill-available' }}
                    />{' '}
                  </center>
                </DialogContent>
              </Dialog>
              {/* STATUS POPUP */}
              <Dialog open={open} onClose={handleCloseModal} maxWidth="md" fullWidth>
                <DialogTitleStyled id="form-dialog-title">
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex">
                      <TypoPopHeadStyled>Rymindr Status</TypoPopHeadStyled>
                      <TypoPopHeadStyled style={{ color: '#607383' }} className="ml-20">
                        {' '}
                        {accepted} out of {total} people read
                        {/* {rymindrDetails.remainingCount > 0 ? rymindrDetails.remainingCount + users_list.length : users_list.length}  */}{' '}
                      </TypoPopHeadStyled>
                    </Box>
                    <IconButton color="default" onClick={handleCloseModal}>
                      <Close />
                    </IconButton>
                  </Box>
                </DialogTitleStyled>
                <DialogContent id="scrollElement" style={{ maxHeight: '806px' }}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                    validate="true"
                  >
                    <Grid item xs={12}>
                      {/* <p style={{ paddingTop: "8px", fontSize: "18px", marginBottom: "0px" }}>
                      Search Contact
                    </p> */}
                      <TextField
                        error={!!formvalidation.contact}
                        helperText={formvalidation.contact}
                        id="contact"
                        // label="Contact"
                        placeholder="Search by mobile number or name , min 4 words"
                        name="contact"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => handleChange(e)}
                        // onKeyUp={(e) => { handleBettersearch(e) }}
                        value={formvalues.contact}
                        margin="normal"
                      />
                    </Grid>
                  </form>
                  <List>
                    {state.length > 0 ? (
                      state?.map((element) => {
                        return (
                          <ListItem alignItems="center">
                            <ListItemAvatar>
                              <AvatarShareStyled alt="semy Sharp" src={element.profile_image} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Box display="flex" justifyContent="space-between">
                                  <Typography style={{ paddingTop: '5px' }}>
                                    {element.first_name + ' ' + element.last_name}
                                    <br />
                                    {element.mobile_no}
                                  </Typography>
                                  <div style={{ marginRight: "10%", display: "flex" }}>
                                    {element.group_data && (
                                      <Box display="flex" justifyContent="center" alignItems="center">
                                        {element.group_data.slice(0, 3).map(group => (
                                          <Box key={group._id} display="flex" justifyContent="center" alignItems="center">
                                            <Tooltip title={group.group_name}>
                                              <div style={{ padding: "10px" }}>
                                                <Avatar alt="Group Icon" src={group.group_icon} />
                                              </div>
                                            </Tooltip>
                                          </Box>
                                        ))}
                                        {element.group_data.length > 3 && (
                                          <Box display="flex" justifyContent="center" alignItems="center">
                                            <div style={{ padding: "10px" }}>
                                              <Avatar>+{element.group_data.length - 3}</Avatar>
                                            </div>
                                          </Box>
                                        )}
                                      </Box>
                                    )}
                                  </div>
                                  <Box display="flex" justifyContent="center" alignItems="center">
                                    {element.is_status == 0 ? (
                                      <>
                                        <FiberManualRecord
                                          style={{
                                            color: '#1773bf',
                                            fontSize: 14,
                                          }}
                                        />
                                        <TypoStatusStyled className="ml-20 mr-20">Pending</TypoStatusStyled>
                                      </>
                                    ) : element.is_status == 1 ? (
                                      <>
                                        <FiberManualRecord
                                          style={{
                                            color: '#44b700',
                                            fontSize: 14,
                                          }}
                                        />
                                        <TypoStatusStyledAccept className="ml-20 mr-20">
                                          Accepted
                                        </TypoStatusStyledAccept>
                                      </>
                                    ) : (
                                      <>
                                        <FiberManualRecord
                                          style={{
                                            color: '#FF0000',
                                            fontSize: 14,
                                          }}
                                        />
                                        <TypoStatusStyledReject className="ml-20 mr-20">
                                          Declined
                                        </TypoStatusStyledReject>
                                      </>
                                    )}
                                  </Box>
                                </Box>
                              }
                            />
                          </ListItem>
                        );
                      })
                    ) : (
                      <p>No Contacts Found</p>
                    )}
                  </List>
                  {Loading ? (
                    <Box sx={{ width: 'fit-content', margin: 'auto' }}>
                      <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        sx={{
                          color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                          animationDuration: '550ms',

                          [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                          },
                        }}
                        size={30}
                        thickness={4}
                        {...props}
                      />
                    </Box>
                  ) : (
                    <div style={{ minHeight: 40, width: 'auto' }}></div>
                  )}
                </DialogContent>
              </Dialog>
              {/* STATUS POPUP */}
            </Grid>
          ) : (
            <Grid
              ref={measuredRef}
              item
              xs={12}
              md={8}
              className={classes.rightPanel}
              style={{ display: showPanel ? 'none' : 'block' }}
            >
              <PaperStyled>
                <Box className={classes.content}>
                  <CardMedia
                    className={classes.icon}
                    image={RymindrOriginal}
                    title="Upcoming Rymindr"
                    component="img"
                  />
                  <Typography className={classes.commingSoonDesc} style={{ paddingTop: '2px' }}>
                    No record Found
                  </Typography>
                </Box>
              </PaperStyled>
            </Grid>
          )
        ) : (
          <Grid
            ref={measuredRef}
            item
            xs={12}
            md={8}
            className={classes.rightPanel}
            style={{ display: showPanel ? 'none' : 'block' }}
          >
            <PaperStyled>
              <Box className={classes.content}>
                <CardMedia className={classes.icon} image={RymindrOriginal} title="Upcoming " component="img" />
              </Box>
            </PaperStyled>
          </Grid>
        )}
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.rymidr.loading,
    error: state.rymidr.error,
    upcommingrymindr: state.rymidr.upcommingrymindr,
    rymindrDetails2: state.rymidr.rymindrDetails,
    success_message: state.rymidr.success_message,
    qrImg: state.rymidr.qrImg,
    sidebar: state.sidebar.ryminderlist,
    statusList: state.rymidr.statusList,
    contactSearchList: state.rymidr.contactListSearch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUpcommingRymindrs: (data) => dispatch(getUpcommingRymindrs(data)),
    getRymidrDetails: (data) => dispatch(getRymidrDetails(data)),
    delete_rymindr2: (data) => dispatch(delete_rymindr2(data)),
    generateQrCodeDB: (data, history) => dispatch(generateQrCodeDB(data, history)),
    rymindrSearchContact: (data) => dispatch(rymindrSearchContact(data)),
    statusData: (dataTosend) => dispatch(statusData(dataTosend)),
  };
};

UpcomingRymindr.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  getUpcommingRymindrs: PropTypes.func.isRequired,
  upcommingrymindr: PropTypes.any.isRequired,
  getRymidrDetails: PropTypes.func.isRequired,
  rymindrDetails2: PropTypes.any.isRequired,
  delete_rymindr2: PropTypes.func.isRequired,
  success_message: PropTypes.any.isRequired,
  generateQrCodeDB: PropTypes.func.isRequired,
  qrImg: PropTypes.string.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(UpcomingRymindr));
