import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  useParams,
  useLocation,
} from "react-router-dom";
import * as actions from "../../store/actions/index";
import React, { useState, useMemo, useEffect } from "react";
import { Avatar, Button, IconButton } from "@material-ui/core";
import { searchUser } from "../../store/actions/accountsettingAction";
import { makeStyles, styled } from "@material-ui/core/styles";
import BusinessAccount from "./BusinessAccount";
import ChangePassword from "./ChangePassword";
import DefaultCategory from "./DefaultCategory";
import DeleteAccount from "./DeleteAccount";
import EditProfile from "./EditProfile";
import Notifications from "./Notifications";
import UserRoles from "./UserRoles";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Close } from "@material-ui/icons";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import CircularSpinner from "../../component/CircularSpinner";
import { inviteUsersFail } from "../../store/actions/auth";
import PaymentSetting from "./PaymentSetting";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    position: "relative",
    height: 100 + "%",
    overflowX: "hidden",
    overflowY: "auto",
    paddingBottom: 20,
  },
  rightPanel: {
    [theme.breakpoints.up("md")]: {
      display: "block !important",
    },
  },
  iconAttach: {
    color: theme.palette.primary.dark,
    background: theme.palette.primary.light,
    height: 32,
    width: 32,
    borderRadius: 60,
    padding: 5,
    float: "left",
    marginTop: -5,
    marginRight: "10px",
  },
  feedImg: {
    float: "left",
    marginRight: 20,
    marginBottom: 10,
    maxWidth: 100 + "%",
    borderRadius: 5,
  },
}));
const TypoPopHeadStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontSize: 18,
  fontWeight: "600",
}));
const AvatarStyled = styled(Avatar)({
  height: 55,
  width: 55,
  marginRight: 10,
});
const TypoHeadStyled = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.primary.main,
  textTransform: "uppercase",
  fontWeight: "bold",
}));

const TypoHeadInnerStyled = styled(TypoHeadStyled)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const ListStyled = styled(List)(({ theme }) => ({
  padding: 0,
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  padding: 15,
  color: theme.palette.text.primary,
  textAlign: "left",
  display: "block",
  borderRadius: 10,
  marginTop: 5,
  marginBottom: 5,
  "& .active": {
    background: theme.palette.primary.light,
  },
}));

function AccountSettings(props) {
  const {
    onClose,
    value: valueProp,
    getSelectedRymindr,
    success_message,
    open,
    deleteUserRoll,
    sidebar,
    enqueueSnackbar,
    userRollesDetails,
    getRymindrListData,
    ...other
  } = props;

  const {
    user_id,
    business_code,
    first_name,
    last_name,
    sc_bessi_name,
    parent_id,
  } = JSON.parse(localStorage.getItem("userData"));

  const history = useHistory();
  const location = useLocation();
  const href = window.location.href;
  const match = useRouteMatch();
  const { urlId } = useParams();
  const classes = useStyles();
  const FormFields = { email: "" };

  const [open1, setOpen1] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [formvalidation, setFormvalidation] = useState(FormFields);
  const [formvalues, setFormvalues] = useState(FormFields);

  const [values, setValues] = useState({
    email: "",
  });
  const handlevalidation = () => {
    let error = false;
    const formerr = { ...formvalidation };

    if (!formvalues.email) {
      error = true;
      formerr.email = "Email is required!";
      setFormvalidation(formerr);
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formvalues.email)
    ) {
      error = true;
      formerr.email = "Invalid email address";
      setFormvalidation(formerr);
    }

    if (!formvalues.password) {
      error = true;
      formerr.password = "Password is required!";
      setFormvalidation(formerr);
    }
    return error;
  };
  const handleChange = (e) => {
    const form = { ...formvalues };
    const formerr = { ...formvalidation };
    form[e.target.name] = e.target.value;
    formerr[e.target.name] = "";
    setFormvalues(form);
    setFormvalidation(FormFields);
    // if (form.email.includes(".com")) {
    props.searchUser(form);
    // }
  };

  const invitePeopleSubmitHandler = () => {
    const form = { ...formvalues };

    setValues(form);
    if (handlevalidation()) {
      props.inviteUser(form);
      handleClose();
      setFormvalues(FormFields);
      setValues("");
      setFormvalues("");
    }

    return false;
  };

  useEffect(() => {
    success_message && enqueueSnackbar(success_message, { variant: "success" });
  }, [success_message, enqueueSnackbar]);

  const handleClickOpen = () => {
    setOpen1(true);
  };

  const handleClose = () => {
    setOpen1(false);
  };

  const [sidebarlist, setSidebar] = useState([]);
  const [userRoleAccess, setRoleAccess] = useState(false);
  const [PaymentAccess, setPaymentAccess] = useState(false);
  useEffect(() => {
    setSidebar(sidebar);
    let ind = sidebar.find((x) => x.url == "/account-settings/user-roles");
    if (ind && ind.is_accessible == 1) {
      setRoleAccess(true);
    }

    let ind1 = sidebar.find(
      (x) => x.url == "/account-settings/payment-setting"
    );
    if (ind1 && ind1.is_accessible == 1) {
      setPaymentAccess(true);
    }
  }, [sidebar]);
  return (
    <>
      <Grid
        className="main-wrap-head"
        container
        style={{ marginBottom: 20 }}
        alignItems="center"
      >
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <TypoHeadStyled variant="h4">
                  ACCOUNT{" "}
                  <TypoHeadInnerStyled component="span">
                    SETTINGS
                  </TypoHeadInnerStyled>
                </TypoHeadStyled>
              </div>
              {location.pathname === "/account-settings/user-roles" ? (
                <div>
                  <Box style={{ textAlign: "right" }}>
                    <img alt="" src="../../assets/images/user_icon.png" />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickOpen}
                    >
                      ADD NEW USER ROLE
                    </Button>
                  </Box>
                </div>
              ) : (
                ""
              )}
            </div>
          </Box>
        </Grid>
      </Grid>

      <Grid
        className="main-wrap-body account-cont-wrap"
        container
        alignItems="stretch"
      >
        <Grid item xs={12} md={4} className="pr-25 leftSide-cont">
          <Paper className={classes.paper}>
            <ListStyled>
              <ListItemStyled
                button
                onClick={() =>
                  history.push("/account-settings/business-account")
                }
                selected={urlId === "business-account"}
              >
                <ListItemText>Rymindr Codes</ListItemText>
              </ListItemStyled>

              <Divider />
              <ListItemStyled
                button
                onClick={() => history.push("/account-settings/edit-profile")}
                selected={urlId === "edit-profile"}
                className={
                  window.location.pathname === "/account-settings/edit-profile"
                    ? "active"
                    : null
                }
              >
                <ListItemText>Edit Profile</ListItemText>
              </ListItemStyled>
              <Divider />
              <ListItemStyled
                button
                onClick={() =>
                  history.push("/account-settings/default-category")
                }
                selected={urlId === "default-category"}
                to="/account-settings/default-category"
                className={
                  window.location.pathname ===
                  "/account-settings/default-category"
                    ? "active"
                    : null
                }
              >
                <ListItemText>Default Category</ListItemText>
              </ListItemStyled>
              <Divider />
              <ListItemStyled
                button
                onClick={() =>
                  history.push("/account-settings/change-password")
                }
                selected={urlId === "change-password"}
              >
                <ListItemText>Change Password</ListItemText>
              </ListItemStyled>
              <Divider />
              {/* <ListItemStyled
                button
                onClick={() => history.push('/account-settings/notifications')}
                selected={urlId === 'notifications'}
              >
                <ListItemText>Notifications</ListItemText>
              </ListItemStyled>
              <Divider /> */}
              <ListItemStyled
                button
                onClick={() => history.push("/account-settings/delete-account")}
                selected={urlId === "delete-account"}
              >
                <ListItemText>Delete Account</ListItemText>
              </ListItemStyled>
              <Divider />
              {userRoleAccess ? (
                <>
                  <ListItemStyled
                    button
                    onClick={() => history.push("/account-settings/user-roles")}
                    selected={urlId === "user-roles"}
                  >
                    <ListItemText>User Roles</ListItemText>
                  </ListItemStyled>
                  <Divider />
                </>
              ) : null}
              {PaymentAccess ? (
                <>
                  <ListItemStyled
                    button
                    onClick={() =>
                      history.push("/account-settings/payment-setting")
                    }
                    selected={urlId === "payment-setting"}
                  >
                    <ListItemText>Payment-Settings</ListItemText>
                  </ListItemStyled>
                  <Divider />{" "}
                </>
              ) : null}
            </ListStyled>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8} className="rightSide-cont">
          <Paper className={classes.paper}>
            <Switch>
              <Route
                path="/account-settings/business-account"
                component={BusinessAccount}
              />
              <Route
                path="/account-settings/edit-profile"
                component={EditProfile}
              />
              <Route
                path="/account-settings/default-category"
                component={DefaultCategory}
              />
              <Route
                path="/account-settings/change-password"
                component={ChangePassword}
              />
              <Route
                path="/account-settings/notifications"
                component={Notifications}
              />
              <Route
                path="/account-settings/delete-account"
                component={DeleteAccount}
              />
              <Route
                path="/account-settings/user-roles"
                component={UserRoles}
              />
              <Route
                path="/account-settings/payment-setting"
                component={PaymentSetting}
              />
            </Switch>
          </Paper>
        </Grid>
      </Grid>

      {/* <div style={{ width: "100%", height: "300px", borderRadius: "16px" }}> */}
      <Dialog open={open1} onClose={handleClose} fullWidth maxWidth="md">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <TypoPopHeadStyled
            style={{
              paddingLeft: "20px",
              paddingTop: "15px",
            }}
          >
            Add User Role
          </TypoPopHeadStyled>
          <IconButton color="default" onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        {/* <p style={{ paddingLeft: "20px", paddingTop: "10px", fontSize: "17px" }}>New members will gain access to public Spaces, Docs and Dashboards,</p> */}
        <DialogContent>
          <form
            validate="true"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <DialogContentText>
              <Grid item xs={12}>
                <p style={{ paddingTop: "10px", fontSize: "18px" }}>
                  Search email
                </p>
                <TextField
                  error={!!formvalidation.email}
                  helperText={formvalidation.email}
                  id="email"
                  label="Email"
                  name="email"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleChange(e)}
                  value={formvalues.email}
                  margin="normal"
                />
              </Grid>
            </DialogContentText>
          </form>
        </DialogContent>
        <DialogActions style={{ paddingBottom: "20px", paddingRight: "20px" }}>
          {formvalues.email != "" ? (
            props.searcherr === "User not found!" ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ fontWeight: "bold" }}
                  onClick={invitePeopleSubmitHandler}
                >
                  {props.inviteLoading && <CircularSpinner />}
                  Send Invite
                </Button>
              </>
            ) : (
              <>
                <p
                  style={{
                    color: "red",
                    justifyContent: "left",
                    fontSize: "18px",
                    marginBottom: "0px",
                    paddingRight: "20px",
                  }}
                >
                  {props.searcherr}
                </p>
              </>
            )
          ) : null}
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* </div> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.account.loading,
    searcherr: state.account.searcherr,
    inviteLoading: state.account.inviteLoading,
    searchuserdetails: state.account.searchuserdetails,
    success_message: state.contact.success_message,
    sidebar: state.sidebar.ryminderlist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    inviteUser: (email) => dispatch(actions.inviteUser(email.email)),
    Fail: (err) => dispatch(inviteUsersFail(err)),
    searchUser: (data) => dispatch(searchUser(data)),
  };
};

AccountSettings.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  getUserDetails: PropTypes.func.isRequired,
  success_message: PropTypes.any.isRequired,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(AccountSettings));
