import * as actionTypes from './actionTypes';
import Axios from '../../helper/axios';
import axios from 'axios';

export const isLoading = () => {
  return {
    type: actionTypes.ISLOADING,
  };
};

export const Success = (response) => {
  return response;
};

export const Fail = (error) => {
  return {
    type: actionTypes.ERROR,
    error: error,
  };
};

export const SuccessMessage = (message) => {
  return {
    type: actionTypes.SUCCESS_MESSAGE_CENTER,
    success_message: message,
  };
};

export const getCategories = (data) => {
  return (dispatch) => {
    //dispatch(isLoading());
    Axios()
      .get('users/getCategoryListMessageCenter')
      .then((response) => {
        if (response.data.status === '1') {
          dispatch(
            Success({
              type: actionTypes.GETCATEGORY,
              payload: response.data.data,
              //default_cat: response.data.default_cat,
            })
          );
        } else {
          dispatch(Fail(response.data.message));
        }
      })
      .catch((err) => {
        dispatch(Fail(err.message));
      });
  };
};

export const getRymindrList = (data) => {
  return (dispatch) => {
    const dataTosend = { ...data };
    Axios()
      .post('users/getRymindrListForMessage', dataTosend)
      .then((response) => {
        if (response.data.status === '1') {
          dispatch(
            Success({
              type: actionTypes.GETUSERRYMINDRLIST,
              payload: response.data.data,
              //default_cat: response.data.default_cat,
            })
          );
        } else {
          dispatch(
            Success({
              type: actionTypes.GETUSERRYMINDRLIST,
              payload: [],
            })
          );
        }
      })
      .catch((err) => {
        console.log('getRymindrListForMessage', err.message);
        dispatch(Fail(err.message));
      });
  };
};

export const getRymindrListCalendar = (data) => {
  return async (dispatch) => {
    try {
      dispatch(isLoading());
      const { user_id, business_code, first_name, last_name } = JSON.parse(localStorage.getItem('userData'));
      const dataTosend = { ...data };
      const response = await Axios().post('users/getRymindrListForCalendar', dataTosend);
      console.log('responsee=action', response.data);
      if (response.data.status === '1') {
        dispatch(
          Success({
            type: actionTypes.GETRYMINDRLIST,
            payload: response.data.data,
            data: response.data.calendarData,
          })
        );
      } else {
        dispatch(Fail(response.data.message));
      }
    } catch (error) {
      dispatch(Fail(error.message));
    }
  };
};

export const searchRymindrList = (data) => {
  // alert('search-action');
  return (dispatch) => {
    //dispatch(isLoading());
    //Axios().get('users/getRymindrListForMessage')
    const dataTosend = { ...data };
    Axios()
      .post('users/getRymindrListForMessage', dataTosend)
      .then((response) => {
        if (response.data.status === '1') {
          dispatch(
            Success({
              type: actionTypes.GETRYMINDRLIST,
              payload: response.data.data,
              //default_cat: response.data.default_cat,
            })
          );
        } else {
          dispatch(Fail(response.data.message));
        }
      })
      .catch((err) => {
        dispatch(Fail(err.message));
      });
  };
};

export const createMessageCenter = (data, unmatch, history, sendAttachment) => {
  return async (dispatch) => {
    try {
      dispatch(isLoading());
      const { user_id, business_code, first_name, last_name } = JSON.parse(localStorage.getItem('userData'));
      const dataTosend = { ...data };
      const response = await Axios().post('users/createMessageCenter', dataTosend);
      if (response.data.status === '1') {
        const message_id = response.data.data;
        let seldFiles = [];
        if (sendAttachment) {
          var img = sendAttachment;
          console.log('sendAttachment', sendAttachment);
          var len = img.length;
          for (var i = 0; i < len; i++) {
            if (img[i].type == 'application/pdf') {
              var image = img[i].base64.split(';base64,');
              var image = image[1];
              var extn = img[i].name.split('.');
            } else if (img.type == '') {
              var image = img[i].base64.split(';base64,');
              var image = image[1];
              var extn = img[i].name.split('.');
            } else {
              var image = img[i].base64.replace(/^data:image\/\w+;base64,/, '');
              var extn = img[i].name.split('.');
            }
            // let att_data = {
            //   attachment: image,
            //   message_center_id: message_id,
            //   user_id: user_id,
            //   extn: extn[1],
            //   attachment_object: img[i].name,
            // };
            // await Axios().post('users/messageCenterAttachment', att_data);

            const att_data = {
              attachment: image,
              extn: extn[1],
              attachment_object: img[i].name,
            };
            seldFiles.push(att_data);
          }
        }
        let dateTosend = {
          message_center_id: message_id,
          user_id: user_id,
          sendAttachment: seldFiles,
          deleteAttachment: [],
        };
        console.log('dateTosend=====', dateTosend);
        await Axios().post('users/messageCenterAttachment', dateTosend);
      
        dispatch(
          Success({
            type: actionTypes.CREATERYMINDR,
            payload: response.data.data,
          })
        );
        dispatch(SuccessMessage('Message has been created sucessfully.'));
        history.push('/message-center');
        // dispatch(
        //   Success({
        //     type: actionTypes.GETMESSAGECENTERLIST,
        //     payload: [],
        //   })
        // );
        dispatch(getMessageDetails({ user_id }));
      } else {
        dispatch(Fail(response.data.message));
      }
    } catch (error) {
      dispatch(Fail(error.message));
    }
  };
};

export const updateMessageCenter = (data, unmatch, history, sendAttachment, deleteAttachment) => {
  return async (dispatch) => {
    try {
      dispatch(isLoading());
      const { user_id, business_code, first_name, last_name } = JSON.parse(localStorage.getItem('userData'));
      const dataTosend = { ...data };
      //console.log('req', data);
      const response = await Axios().post('users/updateMessageCenter', dataTosend);
      if (response.data.status === '1') {
        const message_id = response.data.data;
        let seldFiles = [];
        if (sendAttachment) {
          // const response_att = await Axios().post('users/deleteAttachmentsMessageCenter', dataTosend);
          var img = sendAttachment;
          console.log('sendAttachmentAction====', sendAttachment);
          var len = img.length;
          for (var i = 0; i < len; i++) {
            if (img[i].base64 != undefined) {
              if (img[i].type == 'application/pdf') {
                var image = img[i].base64.split(';base64,');
                var image = image[1];
                var extn = img[i].name.split('.');
              } else if (img.type == '') {
                var image = img[i].base64.split(';base64,');
                var image = image[1];
                var extn = img[i].name.split('.');
              } else {
                var image = img[i].base64.replace(/^data:image\/\w+;base64,/, '');
                var extn = img[i].name.split('.');
              }
              // let att_data = {
              //   attachment: image,
              //   message_center_id: message_id,
              //   user_id: user_id,
              //   extn: extn[1],
              //   attachment_object: img[i].name, //img[i]
              // };

              // console.log('att_data===', att_data);
              // await Axios().post('users/updatemessageCenterAttachment', att_data);
              const att_data = {
                attachment: image,
                extn: extn[1],
                attachment_object: img[i].name,
              };
              seldFiles.push(att_data);
            }
          }
        }
        let dateTosend = {
          message_center_id: message_id,
          user_id: user_id,
          sendAttachment: seldFiles,
          deleteAttachment: deleteAttachment,
        };
        console.log('dateTosend=====', dateTosend);
        await Axios().post('users/messageCenterAttachment', dateTosend);
        dispatch(
          Success({
            type: actionTypes.CREATERYMINDR,
            payload: response.data.data,
          })
        );
        dispatch(SuccessMessage('Message has been updated sucessfully.'));
        history.push('/message-center');
      } else {
        dispatch(Fail(response.data.message));
      }
    } catch (error) {
      dispatch(Fail(error.message));
    }
  };
};

export const getMessageDetails = (data) => {
  return (dispatch) => {
    //dispatch(isLoading());
    const dataTosend = { ...data };
    Axios()
      .post('users/getMessageDetails', dataTosend)
      .then((response) => {
        if (response.data.status === '1') {
          dispatch(
            Success({
              type: actionTypes.GETMESSAGECENTERLIST,
              payload: response.data.data,
              //default_cat: response.data.default_cat,
            })
          );
        } else {
          dispatch(
            Success({
              type: actionTypes.GETMESSAGECENTERLIST,
              payload: [],
              //default_cat: response.data.default_cat,
            })
          );
          //dispatch(Fail(response.data.message));
        }
      })
      .catch((err) => {
        dispatch(Fail(err.message));
      });
  };
};

export const getEmergencyAlertStatus = (data) => {
  return (dispatch) => {
    //dispatch(isLoading());
    const dataTosend = { ...data };
    console.log("dataTosend", dataTosend)
    Axios()
      .post('alerts/status-emergency-alert', dataTosend)
      .then((response) => {
        console.log('response', response);
        if (response.data.status === '1') {
          dispatch(
            Success({
              type: actionTypes.EMERGENCYALERTCONTACTSTATUS,
              payload: response.data.data,
            })
          );
        } else {
          dispatch(Fail(response.data.message));
        }
      })
      .catch((err) => {
        dispatch(Fail(err.message));
      });
  };
};

export const getMessageContactsDetail = (data) => {
  return (dispatch) => {
    //dispatch(isLoading());
    const dataTosend = { ...data };
    Axios()
      .post('users/messageCenterDetailsWeb', dataTosend)
      .then((response) => {
        if (response.data.status == '1') {
          dispatch(
            Success({
              type: actionTypes.GETMESSAGECONTACTDETAIL,
              payload: response.data.data,
            })
          );
        } else {
          dispatch(Fail(response.data.message));
        }
      })
      .catch((err) => {
        dispatch(Fail(err.message));
      });
  };
};

export const getMessageDetails1111 = (data) => {
  return async (dispatch) => {
    const dataTosend = { ...data };
    await Axios()
      .post('users/getMessageDetails', dataTosend)
      .then((response) => {
        if (response.data.status === '1') {
          dispatch(
            Success({
              type: actionTypes.GETMESSAGECENTERLIST,
              payload: response.data.data,
            })
          );
        } else {
          dispatch(Fail(response.data.message));
        }
      })
      .catch((err) => {
        dispatch(Fail(err.message));
      });
  };
};

export const messageDetailClear = () => {
  return (dispatch) => {
    dispatch(
      Success({
        type: actionTypes.MESSAGECENTERLISTCLEAR,
        payload: [],
      })
    );
  };
};

export const getMessageDetailsById = (data) => {
  return (dispatch) => {
    //dispatch(isLoading());
    const dataTosend = { ...data };
    Axios()
      .post('users/getMessageDetailsById', dataTosend)
      .then((response) => {
        if (response.data.status === '1') {
          dispatch(
            Success({
              type: actionTypes.GETMESSAGECENTERLISTBYID,
              payload: response.data.data,
            })
          );
        } else {
          dispatch(Fail(response.data.message));
        }
      })
      .catch((err) => {
        dispatch(Fail(err.message));
      });
  };
};

export const deleteMessage = (msgId, user_id) => {
  return async (dispatch) => {
    try {
      const response = await Axios().post('users/deleteMessageCenter', {
        message_center_ids: msgId,
      });
      if (response.data.status === '1') {
        dispatch(
          Success({
            type: actionTypes.GETMESSAGECENTERLISTAFTERDELETE,
            payload: response.data.data,
          })
        );
        dispatch(getMessageDetails({ user_id }));
        dispatch(SuccessMessage('Message has been deleted sucessfully.'));
      } else {
        dispatch(Fail(response.data.message));
      }
    } catch (error) {
      dispatch(Fail(error.message));
    }
  };
};

// export const searchMessageCenterContact = (msgId, user_id) => {
//   return async (dispatch) => {
//     try {
//       const response = await Axios().post("users/getMessageStatus");
//       if (response.data.status === "1") {
//         dispatch(
//           Success({
//             type: actionTypes.SEARCHCONTACT,
//             payload: response.data.data,
//           })
//         );
//         // dispatch(getMessageDetails({ user_id }));
//         // dispatch(SuccessMessage("Message has been deleted sucessfully."));
//       } else {
//         dispatch(Fail(response.data.message));
//       }
//     } catch (error) {
//       dispatch(Fail(error.message));
//     }
//   };
// };

export const searchMessageCenterContact = (data) => {
  return async (dispatch) => {
    try {
      const response = await Axios().post('users/getMessageStatus', data);
      console.log('response-', response);
      if (response.data.status == '1') {
        console.log('response.data.data', response.data.data.data);
        dispatch(
          Success({
            type: actionTypes.SEARCHCONTACT,
            payload: response.data.data,
          })
        );
      } else {
        dispatch(Fail(response.data.message));
      }
    } catch (error) {
      dispatch(Fail(error.message));
    }
  };
};
export const getRssFeeds = (data) => {
  return async (dispatch) => {
    try {
      dispatch(isLoading());
      const { user_id, business_code, first_name, last_name } = JSON.parse(localStorage.getItem('userData'));
      const dataTosend = { ...data };
      const response = await Axios().post('users/getRssFeeds', dataTosend);
      if (response.data.status === '1') {
        //console.log('responsee=action', response);
        dispatch(
          Success({
            type: actionTypes.GETRSSFEEDS,
            payload: response.data.data,
          })
        );
      } else {
        dispatch(Fail(response.data.message));
      }
    } catch (error) {
      // dispatch(Fail(error.message));
    }
  };
};

//  ********************* Code By Divyansh *******************

export const getAlretList = (data) => {
  return (dispatch) => {
    //dispatch(isLoading());
    const dataTosend = { ...data };
    Axios()
      .post('alerts/list-emergency-alert', dataTosend)
      .then((response) => {
        console.log('response', response);
        if (response.data.status === '1') {
          dispatch(
            Success({
              type: actionTypes.EMERGENCYALERTLIST,
              payload: response.data,
              //default_cat: response.data.default_cat,
            })
          );
        } else {
          dispatch(
            Success({
              type: actionTypes.EMERGENCYALERTLIST,
              payload: [],
              //default_cat: response.data.default_cat,
            })
          );
          //dispatch(Fail(response.data.message));
        }
      })
      .catch((err) => {
        dispatch(Fail(err.message));
      });
  };
};

// Added by payal

export const getTemplateList = (data) => {
  return (dispatch) => {
    //dispatch(isLoading());
    const dataTosend = { ...data };

    Axios()
      .post('alerts/list-alert-template', dataTosend)
      .then((response) => {
        console.log('response', response);
        if (response.data.status === '1') {
          dispatch(
            Success({
              type: actionTypes.ALERTTEMPLATELIST,
              payload: response.data,
              //default_cat: response.data.default_cat,
            })
          );
        } else {
          dispatch(
            Success({
              type: actionTypes.EMERGENCYALERTLIST,
              payload: [],
              //default_cat: response.data.default_cat,
            })
          );
          //dispatch(Fail(response.data.message));
        }
      })
      .catch((err) => {
        dispatch(Fail(err.message));
      });
  };
};

export const CreateAlertTemplate = (data, history, sendAttachment) => {
  return (dispatch) => {
    dispatch(isLoading());
    const dataTosend = { ...data };
    const { user_id, business_code, first_name, last_name } = JSON.parse(localStorage.getItem('userData'));

    Axios()
      .post('alerts/create-alert-template', dataTosend)
      .then(async (response) => {
        console.log('response sendAttachment', sendAttachment);
        if (response.data.status === '1') {
          const message_id = response.data.data;
          let seldFiles = [];
          let dublicate = [];
          if (sendAttachment) {
            var img = sendAttachment;
            console.log('sendAttachment', sendAttachment);
            var len = img.length;
            for (var i = 0; i < len; i++) {
              if (img[i]._id) {
                dublicate.push(img[i]);
              } else {
                if (img[i].type == 'application/pdf') {
                  var image = img[i].base64.split(';base64,');
                  var image = image[1];
                  var extn = img[i].image_name.split('.');
                } else if (img.type == '') {
                  var image = img[i].base64.split(';base64,');
                  var image = image[1];
                  var extn = img[i].image_name.split('.');
                } else {
                  var image = img[i].base64.replace(/^data:image\/\w+;base64,/, '');
                  var extn = img[i].image_name.split('.');
                }

                const att_data = {
                  attachment: image,
                  extn: extn[1],
                  attachment_object: img[i].image_name,
                };
                seldFiles.push(att_data);
              }
            }
          }
          let dateTosend = {
            template_id: message_id,
            dublicateAttachement: dublicate,
            user_id: user_id,
            sendAttachment: seldFiles,
            deleteAttachment: [],
          };
          console.log('dateTosend&&&&&&&&&&&&&&', seldFiles);
          await Axios().post('alerts/upload-template-attachment', dateTosend);
          dispatch(
            Success({
              type: actionTypes.EMERGENCYALERTLIST,
              payload: [],
            })
          );
          dispatch(
            Success({
              type: actionTypes.CREATEALERTTEMPLATE,
              payload: response.data,
              //default_cat: response.data.default_cat,
            })
          );
          dispatch(SuccessMessage('Template created successfully!!'));
          history.push('/alert-template');
        } else {
          dispatch(
            Success({
              type: actionTypes.CREATEALERTTEMPLATE,
              payload: [],
              //default_cat: response.data.default_cat,
            })
          );

          dispatch(Fail(response.data.message));
          history.push('/alert-template');
        }
      })
      .catch((err) => {
        dispatch(Fail(err.message));
      });
  };
};

export const createAlert = (data, history, sendAttachment) => {
  return async (dispatch) => {
    dispatch(isLoading());
    const dataTosend = { ...data };
    const { user_id, business_code, first_name, last_name } = JSON.parse(localStorage.getItem('userData'));
    
    try {
      const response = await Axios().post('alerts/create-emergency-alert', dataTosend);
      console.log('response', response);
      
      if (response.data.status === '1') {
        const message_id = response.data.data;
        let seldFiles = [];
        let dublicate = [];
        
        if (sendAttachment) {
          var img = sendAttachment;
          console.log('sendAttachment', sendAttachment);
          var len = img.length;
          
          for (var i = 0; i < len; i++) {
            if (img[i]._id) {
              dublicate.push(img[i]);
            } else {
              if (img[i].type == 'application/pdf') {
                var image = img[i].base64.split(';base64,');
                var image = image[1];
                var extn = img[i].image_name.split('.');
              } else if (img.type == '') {
                var image = img[i].base64.split(';base64,');
                var image = image[1];
                var extn = img[i].image_name.split('.');
              } else {
                var image = img[i].base64.replace(/^data:image\/\w+;base64,/, '');
                var extn = img[i].image_name.split('.');
              }

              const att_data = {
                attachment: image,
                extn: extn[1],
                attachment_object: img[i].image_name,
              };
              seldFiles.push(att_data);
            }
          }
        }
        
        let dateTosend = {
          alert_id: message_id,
          dublicateAttachement: dublicate,
          user_id: user_id,
          sendAttachment: seldFiles,
          deleteAttachment: [],
        };
        
        await Axios().post('alerts/upload-emergency-alert-attachment', dateTosend);
        
        dispatch(
          Success({
            type: actionTypes.CREATERYMINDR,
            payload: response.data.data,
          })
        );
        dispatch(
          Success({
            type: actionTypes.CREATEEMERGENCYALERT,
            payload: response.data,
          })
        );
        dispatch(SuccessMessage('Emergency Alert created successfully!!'));
        history.push('/emergency-alert');
        
      } else {
        dispatch(
          Success({
            type: actionTypes.CREATEEMERGENCYALERT,
            payload: [],
          })
        );
      }
    } catch (err) {
      dispatch(Fail(err.message));
    }
  };
};

// export const createAlert = (data, history, sendAttachment) => {
//   debugger
//   return (dispatch) => {
//     dispatch(isLoading());
//     const dataTosend = { ...data };
//     const { user_id, business_code, first_name, last_name } = JSON.parse(localStorage.getItem('userData'));
//     Axios()
//       .post('alerts/create-emergency-alert', dataTosend)
//       .then(async (response) => {
//         console.log('response', response);
//         if (response.data.status === '1') {
//           const message_id = response.data.data;
//           let seldFiles = [];
//           let dublicate = [];
//           if (sendAttachment) {
//             var img = sendAttachment;
//             console.log('sendAttachment', sendAttachment);
//             var len = img.length;
//             for (var i = 0; i < len; i++) {
//               if (img[i]._id) {
//                 dublicate.push(img[i]);
//               } else {
//                 if (img[i].type == 'application/pdf') {
//                   var image = img[i].base64.split(';base64,');
//                   var image = image[1];
//                   var extn = img[i].image_name.split('.');
//                 } else if (img.type == '') {
//                   var image = img[i].base64.split(';base64,');
//                   var image = image[1];
//                   var extn = img[i].image_name.split('.');
//                 } else {
//                   var image = img[i].base64.replace(/^data:image\/\w+;base64,/, '');
//                   var extn = img[i].image_name.split('.');
//                 }

//                 const att_data = {
//                   attachment: image,
//                   extn: extn[1],
//                   attachment_object: img[i].image_name,
//                 };
//                 seldFiles.push(att_data);
//               }
//             }
//           }
//           debugger
//           let dateTosend = {
//             alert_id: message_id,
//             dublicateAttachement: dublicate,
//             user_id: user_id,
//             sendAttachment: seldFiles,
//             deleteAttachment: [],
//           };
//           await Axios().post('alerts/upload-emergency-alert-attachment', dateTosend);
//           dispatch(
//             Success({
//               type: actionTypes.CREATERYMINDR,
//               payload: response.data.data,
//             })
//           );
//           dispatch(
//             Success({
//               type: actionTypes.CREATEEMERGENCYALERT,
//               payload: response.data,
//               //default_cat: response.data.default_cat,
//             })
//           );
//           dispatch(SuccessMessage('Emergency Alert created successfully!!'));
//           history.push('/emergency-alert');
//         } else {
//           dispatch(
//             Success({
//               type: actionTypes.CREATEEMERGENCYALERT,
//               payload: [],
//               //default_cat: response.data.default_cat,
//             })
//           );
//           // history.push('/emergency-alert');
//           //dispatch(Fail(response.data.message));
//         }
//       })
//       .catch((err) => {
//         dispatch(Fail(err.message));
//       });
//   };
// };

export const getAlertDetailsById = (data) => {
  return (dispatch) => {
    //dispatch(isLoading());
    const dataTosend = { ...data };
    Axios()
      .post('alerts/details-emergency-alert', dataTosend)
      .then((response) => {
        console.log('response', response);
        if (response.data.status === '1') {
          dispatch(
            Success({
              type: actionTypes.GETALERTDETAILSBYID,
              payload: response.data.data,
            })
          );
        } else {
          dispatch(Fail(response.data.message));
        }
      })
      .catch((err) => {
        dispatch(Fail(err.message));
      });
  };
};

export const getAlertTemplateDetails = (data) => {
  return (dispatch) => {
    //dispatch(isLoading());
    const dataTosend = { ...data };
    Axios()
      .post('alerts/detalis-alert-template', dataTosend)
      .then((response) => {
        console.log('response', response);
        if (response.data.status === '1') {
          dispatch(
            Success({
              type: actionTypes.GETTEMPLATEDETAILS,
              payload: response.data.data,
            })
          );
        } else {
          dispatch(Fail(response.data.message));
        }
      })
      .catch((err) => {
        dispatch(Fail(err.message));
      });
  };
};

export const deleteAlert = (alertId, user_id) => {
  console.log('alertId', alertId, user_id);
  return async (dispatch) => {
    try {
      const response = await Axios().post('alerts/delete-emergency-alert', {
        alert_id: alertId,
        user_id: user_id,
      });
      console.log('response', response);
      if (response.data.status === '1') {
        dispatch(
          Success({
            type: actionTypes.DELETEALERT,
            payload: response.data.data,
          })
        );
        dispatch(getAlretList({ user_id }));
        dispatch(SuccessMessage('Alert has been deleted sucessfully.'));
      } else {
        dispatch(Fail(response.data.message));
      }
    } catch (error) {
      dispatch(Fail(error.message));
    }
  };
};

export const deleteAlertTemplate = (alertId, user_id) => {
  return async (dispatch) => {
    try {
      const response = await Axios().post('alerts/delete-alert-template', {
        template_id: alertId,
        user_id: user_id,
      });
      if (response.data.status === '1') {
        dispatch(
          Success({
            type: actionTypes.DELETEALERT,
            payload: response.data.data,
          })
        );
        dispatch(getTemplateList({ user_id }));
        dispatch(SuccessMessage('Template has been deleted sucessfully.'));
      } else {
        dispatch(Fail(response.data.message));
      }
    } catch (error) {
      dispatch(Fail(error.message));
    }
  };
};
