import * as actionTypes from '../actions/actionTypes'
const initialState = {
    loading: false,
    error: null,
    ryminderlist: []
};


const sidebarReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ISLOADING:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case actionTypes.ERROR:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        case actionTypes.SIDEBAR_RYMINDR_DATA:
            return {
                ...state,
                ryminderlist: action.payload,
                error: null,
                loading: false,
            };

        default:
            return state;
    }
};
export default sidebarReducer;
// export const sidebarReducer = (state = { sidebar: [] }, action) => {
//     switch (action.type) {
//         case actionTypes.SIDEBAR_RYMINDR_DATA:
//             return { consumer: action.payload };
//         case actionTypes.SIDEBAR_RYMINDR_DATA:
//             return { errorsss: action.payload };
//         default:
//             return state;
//     }
// };
// export default sidebarReducer;