import React from 'react'
import {PaymentElement} from '@stripe/react-stripe-js';
const NewCheckOutForm = () => {
  return (
    <form>
    <PaymentElement />
    <button>Pay Now</button>
  </form>
  )
}

export default NewCheckOutForm