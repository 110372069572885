import * as actionTypes from "./actionTypes";
import Axios from "../../helper/axios";

export const isLoading = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const Success = (response) => {
  return response;
};

export const Fail = (error) => {
  return {
    type: actionTypes.ERROR,
    error: error,
  };
};

export const getSidebarList = (data) => {
  console.log(data);
  return (dispatch) => {
    dispatch(isLoading());
    Axios()
      .post("web/main-navigation", data)
      .then((response) => {
        console.log("data", response);
        if (response.data.status === "1") {
          console.log(response.data, "response.data");
          dispatch(
            Success({
              type: actionTypes.SIDEBAR_RYMINDR_DATA,
              payload: response.data.data,
            })
          );
        } else {
          dispatch(Fail(response.data.message));
        }
      })
      .catch((err) => {
        dispatch(Fail(err.message));
      });
  };
};
