import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import { useLocation } from 'react-router-dom';
import firebase from 'firebase';
import { getSidebarList } from '../../store/actions/sidebar';
import { useDispatch, useSelector } from 'react-redux';

const ListStyled = styled(List)(({ theme }) => ({
  padding: '20px',
  '& a': {
    borderRadius: 8,
    paddingTop: '8px !important',
    paddingBottom: '8px !important',
    '& div': {
      minWidth: 48,
    },
    '& span': {
      textTransform: 'uppercase',
      fontSize: 13,
    },
    '& .comingTxt span': {
      fontSize: 9,
      textTransform: 'none',
      color: 'rgba(64, 87, 106, 0.15)',
      textAlign: 'right',
      position: 'absolute',
      right: 5,
      top: 16,
    },
    '&.active': {
      // background: theme.palette.primary.light,
      background: 'rgb(71 152 201)',
    },
  },
  '& :hover': {
    // background: theme.palette.primary.light,
    background: 'rgb(71 152 201)',
    color: 'white',
  },
}));

const img = '../../assets/images/dashboard.svg';

const DATA = [
  {
    icon: require('../../assets/images/dashboard.svg'),
    name: 'DASHBOARD',
    route: '/',
  },
  {
    icon: require('../../assets/images/contacts.svg'),
    name: 'Contacts',
    route: '/contacts',
  },
  {
    icon: require('../../assets/images/rymindr_hand_2.png'),
    name: 'Rymindrs',
    route: '/rymindrs',
  },
  {
    icon: require('../../assets/images/live_chat.svg'),
    name: 'Live chat',
    route: '/live-chat',
  },

  {
    icon: require('../../assets/images/calendar.svg'),
    name: 'Calendar',
    route: '/calendar',
  },
  {
    icon: require('../../assets/images/holidays.svg'),
    name: 'Term dates / holidays',
    route: '/term-dates-holidays',
  },
  {
    icon: require('../../assets/images/bookings.svg'),
    name: 'BOOKINGS',
    route: '/bookings',
  },
  {
    icon: require('../../assets/images/credits.svg'),
    name: 'Form Builders',
    route: '/form-builders',
  },
  {
    icon: require('../../assets/images/payment.svg'),
    name: 'Payments',
    route: '/payments',
    coming: true,
  },
  {
    icon: require('../../assets/images/credits.svg'),
    name: 'Rymindr credits',
    route: '/credits',
    coming: true,
  },
  // {
  //   icon: require('../../assets/images/resources.svg'),
  //   name: 'Resources',
  //   route: '/resources',
  //   coming: true
  // },
  {
    icon: require('../../assets/images/shop.svg'),
    name: 'Rymindr shop',
    route: '/shop',
    coming: true,
  },
  {
    icon: require('../../assets/images/rewards.svg'),
    name: 'Rymindr Rewards',
    route: '/rewards',
    coming: true,
  },
  {
    icon: require('../../assets/images/fund.svg'),
    name: 'Create fund raisers',
    route: '/fundraisers',
    coming: true,
  },
  {
    icon: require('../../assets/images/feed.svg'),
    name: 'Message Center',
    route: '/message-center',
  },
  {
    icon: require('../../assets/images/feed.svg'),
    name: 'LIVE FEED',
    route: '/live-feed',
  },
  {
    icon: require('../../assets/images/news.svg'),
    name: 'RYMINDR NEWS',
    route: '/rymindr-news',
  },
  {
    icon: require('../../assets/images/account.svg'),
    name: 'Account settings',
    route: '/account-settings/business-account',
  },
  {
    icon: require('../../assets/images/integration.svg'),
    name: 'Integrations',
    route: '/integrations',
    coming: true,
  },
  {
    icon: require('../../assets/images/history.svg'),
    name: 'History',
    route: '/history-rymindrs',
  },
];

const Sidebar = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [currentUrl, setCurrentUrl] = useState('');
  const [accessable, setAccessable] = useState(1);

  const { sidebar } = useSelector((state) => state);

  useEffect(() => {
    if (sidebar && sidebar.ryminderlist) {
      setData(sidebar.ryminderlist);
    }
  }, [sidebar]);

  const route1 = useLocation();
  const { user_id, business_code, _id } = JSON.parse(localStorage.getItem('userData'));

  useEffect(() => {
    dispatch(getSidebarList({ user_id: _id }));
  }, []);
  var messageRef = firebase.database().ref();

  const [msgNotifications, updateMsgNotifications] = useState([]);
  const [unreadMsgNotifications, updateUnreadMsgNotifications] = useState([]);
  const [isChangeManu, setIsChangeManu] = useState(1);

  // useEffect(() => {
  //   let element = document.getElementsByClassName("makeStyles-content-18 makeStyles-contentShift-19 main_cont_wrap")
  //   if(element){
  //   if (!accessable && window.location.hash.includes(currentUrl)) {
  //     element[0].style.opacity = '0.7'
  //     element[0].style.pointerEvents = 'none'
  //     element[0].style.filter = "blur(2px)";
  //     element[0].style.userSelect = "none";
  //   }
  //   else {
  //     element[0].style.filter = 'none';
  //     element[0].style.pointerEvents = 'visible';
  //     element[0].style.opacity = 'inherit';
  //   }
  // }
  // }, [accessable])

  // const listenMessages = () => {
  //     messageRef.on('value', function() {
  //         let unreadMessages = [];

  //         messageRef.orderByChild("created_at").on("child_added", snapshot => {

  //             let msgNodes = snapshot.val();
  //             let message = Object.values(msgNodes);

  //             if(message.length > 0) {
  //               for(let i=0; i<message.length; i++)
  //               {
  //                   // Only unread messages will be shown here
  //                   if( message[i]['message_read_'+user_id] === false )
  //                   {
  //                       unreadMessages.push(message[i]);
  //                   }
  //               }
  //               if(unreadMessages.length > 0 ) {
  //                 updateMsgNotifications(unreadMessages);
  //               }
  //             }
  //         });
  //     });
  // }

  setTimeout(() => {
    updateUnreadMsgNotifications(msgNotifications);
  }, 1000);

  var messageRef = firebase.database().ref();
  useEffect(() => {
    listenMessages();
    return () => {
      messageRef.off();
    };
  }, [isChangeManu]);

  const listenMessages = () => {
    messageRef.on('value', function () {
      let unreadMessages = [];
      messageRef.orderByChild('created_at').on('child_added', (snapshot) => {
        let msgNodes = snapshot.val();
        let message = Object.values(msgNodes);

        if (message.length > 0) {
          for (let i = 0; i < message.length; i++) {
            // Only unread messages will be shown here
            if (message[i]['message_read_' + user_id] === false) {
              unreadMessages.push(message[i]);
            }
          }
          //if(unreadMessages.length > 0 ) {
          updateMsgNotifications(unreadMessages);
          //}
        }
      });
    });
  };

  const testfunct = (value) => {
    var x = Math.floor(Math.random() * 10 + 1);
    setIsChangeManu(x);
    setAccessable(value.is_accessible);
    setCurrentUrl(value.url);
  };

  return (
    <>
      <Scrollbars style={{ background: '#1773bf', color: 'white' }}>
        <Divider />
        <ListStyled>
          {data.map(({ icon, lighticon, name, url, coming, is_accessible, menuType }, key) =>
            menuType == 'main-navigation' ? (
              <>
                <ListItem
                  button
                  component={Link}
                  to={{
                    pathname: url,
                    state: { is_access: is_accessible },
                  }}
                  key={name}
                  className={route1.pathname === url ? 'active' : null}
                  onClick={() => {
                    testfunct({ icon, lighticon, name, url, coming, is_accessible });
                  }}
                >
                  <ListItemIcon>
                    {' '}
                    <img src={lighticon} />
                  </ListItemIcon>

                  {/* =={msgNotifications.length} */}
                  {name == 'Live chat' ? (
                    msgNotifications.length > 0 ? (
                      <>
                        <ListItemText>{name}</ListItemText>
                        <span
                          style={{
                            backgroundColor: '#FF8A00',
                            padding: 8,
                            paddingTop: 2,
                            paddingBottom: 2,
                            borderRadius: 30,
                            fontSize: 11,
                            color: 'white',
                            marginLeft: 10,
                            textTransform: 'none',
                          }}
                        >
                          {msgNotifications.length + ' New'}
                        </span>
                      </>
                    ) : (
                      <ListItemText>{name}</ListItemText>
                    )
                  ) : (
                    <ListItemText>{name}</ListItemText>
                  )}
                  {/*<ListItemText>{name}</ListItemText>*/}
                  {coming ? <ListItemText className="comingTxt">Coming soon</ListItemText> : null}
                  {is_accessible == 0 ? (
                    <ListItemIcon>
                      {' '}
                      <img width="15" src={require('../../assets/images/icons8-lock-24.png')} />
                    </ListItemIcon>
                  ) : null}
                </ListItem>

                {key === 5 ? <Divider variant="inset" component="li" className="mt-20 mb-20" /> : null}
                {key === 8 ? <Divider variant="inset" component="li" className="mt-20 mb-20" /> : null}
                {key === 12 ? <Divider variant="inset" component="li" className="mt-20 mb-20" /> : null}
                {key === 15 ? <Divider variant="inset" component="li" className="mt-20 mb-20" /> : null}
              </>
            ) : null
          )}
        </ListStyled>
      </Scrollbars>
    </>
  );
};

export default withRouter(Sidebar);
