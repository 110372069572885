import React, { useState, useEffect, useMemo } from "react";
import { withSnackbar } from "notistack";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import RymindrOriginal from "../../assets/images/rymindr_original.png";
import {
  Box,
  Divider,
  ListItemAvatar,
  Avatar,
  Button,
  IconButton,
  Typography,
  ListItem,
  List,
  CardMedia,
} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Flag, Send, Close, BeachAccess } from "@material-ui/icons";
import DoneIcon from "@material-ui/icons/Done";
import Add from "@material-ui/icons/Add";

import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { authFail } from "../../store/actions";
import {
  deleteUserRoll,
  getUserRollDetails,
  permissionMenu,
  setUserPermission,
} from "../../store/actions/accountsettingAction";
import { connect, useDispatch, useSelector } from "react-redux";

const UserRoles = (props) => {
  const {
    onClose,
    value: valueProp,
    getSelectedRymindr,
    success_message,
    open,
    deleteUserRoll,
    enqueueSnackbar,
    userRollDelete,
    userRollesDetails,
    permissionUpdate,
    getRymindrListData,
    ...other
  } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      position: "relative",
      height: 100 + "%",
    },

    paper2: {
      padding: theme.spacing(2),

      color: theme.palette.text.secondary,
      position: "relative",
      height: 100 + "%",
    },

    rightPanel: {
      [theme.breakpoints.up("md")]: {
        display: "block !important",
      },
    },
    icon: {
      width: 150,
      marginBottom: 40,
    },
    content: {
      textAlign: "center",
      textAlign: "-webkit-center",
      paddingTop: "15%",
      height: "80vh",
    },

    icon2: {
      width: 150,
      marginBottom: 40,
    },
    content2: {
      textAlign: "center",
      textAlign: "-webkit-center",
      padding: "5%",
      background: "#d2edfe",
    },
    contentArea2: {
      height: "10vh",
      borderRadius: 15,
    },
    notifyMeButton2: {
      background: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
      width: 200,
      cursor: "auto",
      "&:hover": {
        backgroundColor: theme.palette.warning.main,
      },
    },
    commingSoonDesc3: {
      marginBottom: 10,
      fontSize: 25,
      fontWeight: "bold",
    },
    commingSoonDesc2: {
      marginBottom: 15,
      fontSize: 20,
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const userRollList = useSelector((state) => state?.account?.userrolldetails);
  const permissionMenuList = useSelector(
    (state) => state?.account?.permissionDeteils
  );

  const userRollListLength = userRollList ? userRollList.length : 0;
  const [data, setData] = useState([]);
  const [person, setPerson] = useState(null);
  const [profileimg, setProfile] = useState(null);
  const [permissionData, setPermissionData] = useState([]);
  const userId = localStorage.getItem("token");
  const [open1, setOpen] = useState(false);
  const [removeUserRoll, setRemoveUserRoll] = useState(false);
  const [added, setAdded] = useState([]);
  const [listData, setListdata] = useState(permissionMenuList);
  const [count, setCount] = useState(0);

  const handleModelOpen = (
    _id,
    email_id,
    contact_person,
    permission_id,
    profile
  ) => {
    setPerson(contact_person);
    setProfile(profile);
    const id = localStorage.setItem("Id", _id);
    const setEmail = localStorage.setItem("email", email_id);
    setAdded(permission_id);
    setOpen(!open1);
    return false;
  };

  const getEmail = localStorage.getItem("email");

  const getId = localStorage.getItem("Id");

  useEffect(() => {
    dispatch(getUserRollDetails());
  }, []);

  useMemo(() => {
    success_message && enqueueSnackbar(success_message, { variant: "success" });
  }, [success_message, enqueueSnackbar]);

  useEffect(() => {
    dispatch(permissionMenu());
  }, []);

  useEffect(() => {
    if (permissionMenuList) {
      setPermissionData(permissionMenuList);
    }
  }, [permissionMenuList]);

  useEffect(() => {
    dispatch(getUserRollDetails());
  }, [userRollDelete, permissionUpdate]);

  useEffect(() => {
    if (userId && userRollList) {
      setData(userRollList);
    }
  }, [userRollList, userId, userRollDelete]);

  const setRolePermission = (id) => {
    var arr = [...added];
    arr.push(id);
    setAdded(arr);
    dispatch(getUserRollDetails());
  };

  const handleCancel = () => {
    setOpen(false);
    setCount(added.length);
    // Refresh the list
    setListdata(permissionMenuList);
    // Set the added items
    setAdded(added);
    localStorage.removeItem("email");
    localStorage.removeItem("contact_person");
    localStorage.removeItem("lastname");
    localStorage.removeItem("Id");
  };

  const getdeleted = (item_id, item_index) => {
    let copyAdded = [...added];
    copyAdded = copyAdded.filter((cp) => {
      return cp !== item_id;
    });
    setAdded(copyAdded);
    dispatch(getUserRollDetails());
  };

  const setRolesPermission = async (user_id) => {
    dispatch(setUserPermission({ id: user_id, permission_id: added }));
    handleCancel();
    // dispatch(getUserRollDetails());
  };

  const deletUserRoll = async (delete_id) => {
    const deleted = await deleteUserRoll({ delete_id: delete_id });
    if (deleted.status === true) setRemoveUserRoll(true);
    // dispatch(getUserRollDetails());
  };

  const TypoStyled = styled(Typography)(({ theme }) => ({
    fontSize: 17,
    color: theme.palette.text.primary,
    fontWeight: "600",
    paddingTop: 20,
  }));

  const TypoSubStyled = styled(TypoStyled)(({ theme }) => ({
    fontSize: 16,
    fontWeight: "500",
    marginBottom: 30,
  }));
  const AvatarStyled = styled(Avatar)({
    height: 55,
    width: 55,
    marginRight: 10,
    marginTop: "15px",
  });
  const LinkStyled = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: "none",
  }));
  const TypoPopHeadStyled = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.contrastText,
    fontSize: 18,
    fontWeight: "600",
  }));
  const ButtonSuccess = styled(Button)(({ theme }) => ({
    background: "#00d264",
    fontWeight: "700",
    color: theme.palette.success.contrastText,
  }));

  const ButtonGiveAccess = styled(Button)(({ theme }) => ({
    background: "#ffa31a",
    fontWeight: "700",
    width: "28%",
    color: theme.palette.success.contrastText,
  }));

  const ListItemStyled = styled(ListItem)(({ theme }) => ({
    borderRadius: 8,
  }));
  const ListStyled = styled(List)(({ theme }) => ({
    maxHeight: 550,
    overflow: "auto",
  }));
  return (
    <>
      <div>
        <Dialog
          open={open1}
          onClose={handleCancel}
          fullWidth
          maxWidth="md"
          aria-labelledby="confirmation-dialog-title"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <TypoPopHeadStyled
              style={{
                paddingLeft: "20px",
                paddingTop: "25px",
                paddingBottom: "20px",
              }}
            >
              Set Permissions
            </TypoPopHeadStyled>
            <IconButton color="default" onClick={handleCancel}>
              <Close />
            </IconButton>
          </Box>
          <Divider />
          <DialogContent>
            <div
              style={{
                display: "flex",
                paddingTop: "20px",
                justifyItems: "end",
              }}
            >
              <ListItemAvatar>
                {profileimg ? (
                  <AvatarStyled alt="semy Sharp" src={profileimg} />
                ) : (
                  <AvatarStyled
                    alt="semy Sharp"
                    src={require("../../assets/images/profile.jpeg")}
                  />
                )}
              </ListItemAvatar>
              <div
                style={{
                  paddingTop: "33px",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                {person == null ? (
                  <p
                    style={{
                      fontSize: "20px",
                      paddingRight: "15px",
                      marginTop: "-15px",
                    }}
                  >
                    {getEmail}
                  </p>
                ) : (
                  <p
                    style={{
                      fontSize: "20px",
                      paddingRight: "15px",
                      marginTop: "-15px",
                    }}
                  >
                    {person}
                  </p>
                )}
                <p
                  style={{
                    fontSize: "15px",
                    paddingRight: "15px",
                    marginTop: "-15px",
                  }}
                >
                  {person ? getEmail : ""}
                </p>
              </div>
            </div>
            <p
              style={{
                fontSize: "20px",
                paddingLeft: "63px",
                paddingTop: "20px",
                fontWeight: "500",
              }}
            >
              Grant Access
            </p>
            <div>
              <ul>
                {Array.isArray(permissionData) &&
                  permissionData.map((data, index) => {
                    const addedArray = Array.isArray(added) ? added : [];
                    const find_key = addedArray.find(
                      (o) => o.toString() == data._id.toString()
                    );
                    return (
                      <li key={index}>
                        <div
                          style={{
                            paddingTop: "20px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <p
                              style={{
                                fontSize: "20px",
                                width: "50%",
                                paddingLeft: "66px",
                                paddingTop: "5px",
                              }}
                            >
                              {data.name}
                            </p>
                            <div
                              style={{
                                //   justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Box
                                style={{
                                  textAlign: "right",
                                }}
                              >
                                <img
                                  alt=""
                                  src="../../assets/images/user_icon.png"
                                />
                                {find_key ? (
                                  <ButtonSuccess
                                    variant="contained"
                                    startIcon={<DoneIcon />}
                                    onClick={() => getdeleted(data._id, index)}
                                  >
                                    Access Given{" "}
                                  </ButtonSuccess>
                                ) : (
                                  <ButtonGiveAccess
                                    variant="contained"
                                    startIcon={<Add />}
                                    onClick={() =>
                                      setRolePermission(data._id, index)
                                    }
                                  >
                                    Give Access
                                  </ButtonGiveAccess>
                                )}
                              </Box>
                            </div>
                          </div>
                        </div>
                        <div style={{ paddingLeft: "64px" }}>
                          <Divider />
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setRolesPermission(getId)} color="primary">
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div style={{ paddingLeft: "10px" }}>
        <div
          style={{
            paddingTop: "20px",
            fontSize: "16px",
            fontWeight: "600",
            color: "rgb(64, 87, 106)",
            paddingBottom: "20px",
          }}
        >
          Members ({userRollListLength})
        </div>
        <Divider />
        {userRollListLength !== 0 ? (
          <>
            <ul>
              {Array.isArray(data) &&
                data.map((details, index) => {
                  return (
                    <li key={index}>
                      <div
                        style={{
                          display: "flex",
                          paddingTop: "20px",
                          paddingBottom: "20px",
                          justifyItems: "end",
                        }}
                      >
                        {details.profile_image == "" ? (
                          <ListItemAvatar>
                            <AvatarStyled
                              alt="semy Sharp"
                              src={require("../../assets/images/admin.png")}
                            />
                          </ListItemAvatar>
                        ) : (
                          <ListItemAvatar>
                            <AvatarStyled
                              alt="semy Sharp"
                              src={details.profile_image}
                            />
                          </ListItemAvatar>
                        )}

                        <div
                          style={{
                            paddingTop: "18px",
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          {details.is_verify == 1 ? (
                            <p
                              className="dash-feed-cont"
                              style={{
                                fontSize: "18px",
                                paddingRight: "15px",
                                fontWeight: "400",
                                color: "rgb(64, 87, 106)",
                                width: "365px",
                              }}
                            >
                              {details.contact_person}
                              <br /> <small>{details.email}</small>
                            </p>
                          ) : (
                            <p
                              className="dash-feed-cont"
                              style={{
                                fontSize: "18px",
                                paddingRight: "15px",
                                width: "365px",
                                fontWeight: "400",
                                color: "rgb(64, 87, 106)",
                              }}
                            >
                              {details.email}
                            </p>
                          )}
                          {details.permission.join(", ") ? (
                            <>
                              <p
                                style={{
                                  fontSize: "13px",
                                  paddingRight: "15px",
                                  color: "rgb(23, 186, 255)",
                                  paddingTop: "5px",
                                  width: "200px",
                                  justifyContent: "left",
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                                onClick={() =>
                                  handleModelOpen(
                                    details._id.toString(),
                                    details.email,
                                    details.contact_person,
                                    details.permission_id,
                                    details.profile_image
                                  )
                                }
                              >
                                {details.permission.join(", ")}
                              </p>
                            </>
                          ) : (
                            <>
                              <p
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "clip",
                                  fontSize: "15px",
                                  paddingRight: "152px",
                                  color: "rgb(23, 186, 255)",
                                  paddingTop: "5px",
                                  width: "200px",
                                  justifyContent: "left",
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                                onClick={() =>
                                  handleModelOpen(
                                    details._id.toString(),
                                    details.email,
                                    details.contact_person,
                                    details.permission_id,
                                    details.profile_image
                                  )
                                }
                              >
                                Access & Permissions
                              </p>
                            </>
                          )}

                          {/* <button style={{ fontSize: "20px" }}>Pending</button> */}
                          {details.is_verify == 1 ? (
                            <Box>
                              <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                style={{
                                  border: "2px solid rgb(37, 159, 12)",
                                  color: "rgb(37, 159, 12)",
                                  backgroundColor: "white",
                                  padding: "5px 10px",
                                  // maxWidth: "200px"
                                }}
                              >
                                Verified
                              </Button>
                            </Box>
                          ) : (
                            <Box>
                              <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                style={{
                                  border: "2px solid gold",
                                  color: "gold",
                                  backgroundColor: "white",
                                  padding: "5px 10px",
                                }}
                              >
                                Pending
                              </Button>
                            </Box>
                          )}

                          <p
                            style={{
                              fontSize: "15px",
                              color: "red",
                              paddingTop: "5px",
                              cursor: "pointer",
                              textDecoration: "none",
                              paddingRight: "20px",
                            }}
                            onClick={() =>
                              deletUserRoll(details._id.toString())
                            }
                          >
                            Remove
                          </p>
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </>
        ) : (
          <>
            <Box className={classes.content} style={{ margin: "auto" }}>
              <CardMedia
                className={classes.icon}
                image={RymindrOriginal}
                title="Message Center"
                component="img"
              />
              <Typography className={classes.commingSoonDesc}>
                No record Found
              </Typography>
            </Box>

            {/* <div style={{textAlign: "center", paddingTop: "250px", fontSize: "17px"}}>
                                    No record found
                                </div> */}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loginLoading: state.auth.loginLoading,
    error: state.account.error,
    success_message: state.account.success_message,
    userRollDelete: state.account.userRollDelete,
    permissionUpdate: state.account.permissionUpdate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteUserRoll: (data, history) => dispatch(deleteUserRoll(data, history)),
    setUserPermission: (data, history) =>
      dispatch(setUserPermission(data, history)),
    Fail: (err) => dispatch(authFail(err)),
  };
};
UserRoles.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  success_message: PropTypes.any.isRequired,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(UserRoles));
