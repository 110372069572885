import { makeStyles, styled, withStyles } from '@material-ui/core/styles';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import RymindrOriginal from '../../assets/images/rymindr_original.png';
import alertImg from '../../assets/images/alert_web.png';
import ResponsiveDialog from './Modal/AlertModalDelete';
import {
  Box,
  Button,
  Divider,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  InputAdornment,
  TextField,
  IconButton,
  Hidden,
  MenuItem,
  Badge,
  Menu,
  Dialog,
  DialogContent,
  DialogTitle,
  AppBar,
  Tabs,
  Tab,
  CardMedia,
} from '@material-ui/core';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import {
  Comment,
  Description,
  Delete,
  Edit,
  Search,
  ArrowBack,
  Chat,
  Send,
  Videocam,
  Headset,
  PersonAdd,
  Print,
  Drafts,
  MoreVert,
  ThumbUp,
  Message,
  Close,
  FiberManualRecord,
  Check,
  Update,
} from '@material-ui/icons';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import clsx from 'clsx';
import Axios from '../../helper/axios';
import ReactDOM from 'react-dom';
import Pdf from 'react-to-pdf';
import parse from 'html-react-parser';
import io from 'socket.io-client';

import {
  getAlretList,
  getAlertDetailsById,
  deleteAlert,
  getTemplateList,
  getAlertTemplateDetails,
  deleteAlertTemplate,
} from '../../store/actions/messageCenterAction';

//  *********** UI Styles Start *****************
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    position: 'relative',
    height: 100 + '%',
    maxHeight: 1000,
    overflow: 'auto',
  },
  rightPanel: {
    [theme.breakpoints.up('md')]: {
      display: 'block !important',
    },
  },
  iconAttach: {
    color: theme.palette.primary.dark,
    background: theme.palette.primary.light,
    height: 32,
    width: 32,
    borderRadius: 16,
    padding: 5,
    float: 'left',
    marginTop: -5,
    marginRight: '10px',
  },
  icon: {
    width: 150,
    marginBottom: 40,
  },
  content: {
    textAlign: 'center',
    textAlign: '-webkit-center',
    paddingTop: '15%',
    height: '80vh',
  },
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  borderRadius: 8,
}));

const ButtonColor = styled(Button)(({ theme }) => ({
  background: theme.palette.props.main,
  color: theme.palette.props.contrastText,
}));

const AvatarShareStyled = styled(Avatar)({
  height: 50,
  width: 50,
});

const TypoListSubtext = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.contrastText,
}));

const SearchFieldStyled = styled(TextField)(({ theme }) => ({
  padding: 20,
  paddingLeft: 0,
  paddingRight: 0,
}));

const TypoHeadStyled = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
  fontWeight: 'bold',
}));

const TypoHeadInnerStyled = styled(TypoHeadStyled)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const GridStyled = styled(Grid)({
  padding: '15px 15px 15px 30px',
});

const BoxStyled = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
});

const ButtonPlain = styled(Button)(({ theme }) => ({
  color: '#98a5af',
  fontSize: 12,
  textTransform: 'capitalize',
  background: 'none',
  boxShadow: 'none',
}));

const ListStyled = styled(List)(({ theme }) => ({
  paddingLeft: 20,
}));

const AvatarStyled = styled(Avatar)({
  height: 'auto',
  width: 25,
  '& img': {
    height: 'auto',
  },
});

const TypoTitleStyled = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.contrastText,
  marginBottom: 10,
}));

const TypoContentStyled = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text.primary,
}));

const TypoStyled = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text.primary,
  marginTop: 10,
  marginBottom: 10,
  marginRight: 10,
  cursor: 'pointer',
}));

const DialogTitleStyled = styled(DialogTitle)({
  borderBottom: '1px solid #e0e0e0',
  padding: '10px 24px',
});

const TypoPopHeadStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontSize: 18,
  fontWeight: '600',
}));

const StyledReadBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const StyledUnreadBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#1773bf',
    color: '#1773bf',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadgeReject = withStyles((theme) => ({
  badge: {
    backgroundColor: '#e50214',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

//  *********** UI Styles Start *****************

const Template = (props) => {
  const history = useHistory();
  // const [showParticularALert, setShowParticularALert] = useState([]);
  const [showPanel, setShowPanel] = useState(true);
  const [showAlertList, setShowAlertList] = useState([]);
  const [alertDetails, setAlertDetails] = useState('');
  const [selectedImage, setSelectedImage] = React.useState('');
  const [openImage, setOpenImage] = React.useState(false);
  const [selected, setSelected] = React.useState('');
  const [messageDetail, setMessageDetail] = useState([]);

  const handleClickImageOpen = (file) => {
    setOpenImage(true);
    setSelectedImage(file);
  };

  const handleCloseImageModal = () => {
    setOpenImage(false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenDelete(false);
  };

  const [openDelete, setOpenDelete] = useState(false);
  const [deleteMsgId, setDeleteMsgId] = useState(null);

  const handleDelete = (id) => {
    if (id != undefined) {
      setDeleteMsgId(id);
      setOpenDelete(true);
    }
  };

  const deleteMsg = (delete_id) => {
    dispatch(deleteAlertTemplate(deleteMsgId, alertDetails.user_id));
    setOpenDelete(false);
    // const getIndex = messageDetail.findIndex((element) => element.id == delete_id);
    // messageDetail.splice(getIndex, 1);
    // setMessageDetail(messageDetail);
    // console.log('messageDetail-', messageDetail);
  };

  // const deleteMsg = (delete_id) => {
  //   // deleteAlert(deleteMsgId, alertDetails.user_id).then(() => {
  //   //   setOpenDelete(false);
  //   //   // window.location.reload();
  //   //   // console.log('before delete_messg',messageDetail);
  //   //   const getIndex = messageDetail.findIndex((element) => element.id == delete_id);
  //   //   messageDetail.splice(getIndex, 1);
  //   //   setMessageDetail(messageDetail);
  //   //   console.log('messageDetail-', messageDetail);
  //   //   // console.log('after delete_messg',messageDetail);
  //   //   // if (message_details.length > 0) {
  //   //   //   handleShowDetailRight(message_details[0].id);
  //   //   // } else {
  //   //   //   setShowParticularMessage([]);
  //   //   // }
  //   // });

  // };

  const classes = useStyles();

  //  ***************************************************************************************************

  const { user_id, business_code, first_name, last_name, is_subscribe } = JSON.parse(localStorage.getItem('userData'));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTemplateList({ user_id: user_id }));
  }, []);

  const { templatelist } = useSelector((state) => state.messageCenterReducer);
  console.log('alertList', templatelist);
  useEffect(() => {
    if (templatelist && templatelist.data && templatelist.data.length > 0) {
      setShowAlertList(templatelist.data);
      handleShowDetailRight(templatelist.data[0]);
    }
  }, [templatelist]);

  //  ***************************************************************************************************

  const { templatedetails } = useSelector((state) => state.messageCenterReducer);
  useEffect(() => {
    if (templatedetails) {
      setAlertDetails(templatedetails);
    }
  }, [templatedetails]);
  console.log('alertDetails', templatedetails);

  const handleShowDetailRight = (alert) => {
    // console.log('alert',alert);
    dispatch(getAlertTemplateDetails({ user_id: alert.user_id, template_id: alert._id }));
    setSelected(alert._id);
  };

  //  ***************************************************************************************************

  // console.log(alertListing, 'hhhhh');

  return (
    <>
      <Grid className="main-wrap-head" container style={{ marginBottom: 20 }} alignItems="stretch">
        <Grid item xs={4}>
          <Box display="flex" alignItems="center">
            <TypoHeadStyled variant="h4">
              <ArrowBackIcon onClick={() => history.push('/emergency-alert')} />
              Alert <TypoHeadInnerStyled component="span">Template</TypoHeadInnerStyled>
            </TypoHeadStyled>
          </Box>
        </Grid>

        <Grid item xs={8}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              size="large"
              className="mr-10"
              startIcon={<PersonAdd />}
              onClick={() => history.push('/create-template')}
            >
              Add new Template
            </Button>{' '}
            {/* <ButtonColor variant="contained" color="primary" size="large">
              create new template
            </ButtonColor> */}
          </Box>
        </Grid>
      </Grid>

      <Grid className="main-wrap-body msg-cont-wrap" container alignItems="stretch">
        {showPanel ? (
          <Grid item xs={12} md={4} className="pr-25 sidebar-list leftSide-cont">
            <Paper className={classes.paper}>
              <SearchFieldStyled
                id="input-with-icon-textfield"
                variant="outlined"
                fullWidth
                size="small"
                // onKeyUp={handleOnChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
              <List>
                {showAlertList.length > 0 ? (
                  <>
                    {showAlertList.map((alert, key) => {
                      // return <p>{alert.title}</p>
                      return (
                        <>
                          <Hidden mdUp implementation="css">
                            <ListItemStyled
                              button
                              // onClick={() => handleShowDetail(alert._id)}
                              key={key}
                              // selected={
                              //   selectedItem
                              //     ? selectedItem === item.id
                              //     : messageDetail.id === item.id
                              // }
                            >
                              <ListItemAvatar>
                                <AvatarShareStyled alt="semy Sharp" src={alertImg} />
                              </ListItemAvatar>
                              <ListItemText
                                primary={alert.title}
                                secondary={
                                  <TypoListSubtext>
                                    {alert.day} {alert.created_at}
                                  </TypoListSubtext>
                                }
                              />
                            </ListItemStyled>
                            <Divider variant="inset" component="li" />
                          </Hidden>

                          <Hidden smDown implementation="css">
                            <ListItemStyled
                              button
                              onClick={() => handleShowDetailRight(alert)}
                              key={key}
                              selected={selected ? selected === alert._id : templatedetails._id === alert._id}
                            >
                              <ListItemAvatar>
                                <AvatarShareStyled alt="semy Sharp" src={alertImg} />
                              </ListItemAvatar>
                              <ListItemText
                                primary={alert.title}
                                secondary={
                                  <TypoListSubtext>
                                    {moment(alert.created_at).format('dddd DD MMMM YYYY , LT')}
                                  </TypoListSubtext>
                                }
                              />
                            </ListItemStyled>
                            <Divider variant="inset" component="li" />
                          </Hidden>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <Typography className={classes.commingSoonDesc}>No records Found</Typography>
                )}
              </List>
            </Paper>
          </Grid>
        ) : (
          ''
        )}

        <Grid
          item
          xs={12}
          md={8}
          className={clsx(classes.rightPanel, 'rightSide-cont')}
          style={{ display: showPanel ? 'none' : 'block' }}
        >
          <Paper className={classes.paper}>
            {templatedetails && templatedetails?.title ? (
              <>
                <GridStyled container alignItems="center">
                  <Grid xs={6}>
                    <BoxStyled alignItems="center">
                      <AvatarShareStyled alt="semy Sharp" className="mr-0" src={alertImg} />
                      <Typography noWrap>
                        <Box fontWeight="fontWeightBold" m={1}>
                          {/* {alertDetails.title
                                ? alertDetails.title
                                : "No Subject"} */}
                          Template | {templatedetails?.title ? templatedetails?.title : 'No Title'}
                        </Box>
                      </Typography>
                    </BoxStyled>
                  </Grid>

                  <Grid xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                      <ButtonPlain
                        disableRipple
                        onClick={() => history.push(`/create-template/` + templatedetails._id)}
                        startIcon={<FileCopyIcon style={{ color: '#1872c0' }} />}
                      >
                        Duplicate
                      </ButtonPlain>
                      <ButtonPlain
                        disableRipple
                        onClick={() => handleDelete(alertDetails._id)}
                        startIcon={<Delete style={{ color: '#ec4d4b' }} />}
                      >
                        Delete
                      </ButtonPlain>
                    </Box>
                  </Grid>
                </GridStyled>

                <ListStyled>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <AvatarStyled alt="semy Sharp" variant="square" src={require('../../assets/images/note.png')} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<TypoTitleStyled>Alert</TypoTitleStyled>}
                      secondary={<TypoContentStyled>{templatedetails?.subject}</TypoContentStyled>}
                    />
                  </ListItem>

                  <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <AvatarStyled
                        alt="semy Sharp"
                        variant="square"
                        src={require('../../assets/images/calendar_icon.png')}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<TypoTitleStyled>Date & Time</TypoTitleStyled>}
                      secondary={
                        <TypoContentStyled>
                          {moment(templatedetails.created_at).format('dddd DD MMMM YYYY , LT')}
                        </TypoContentStyled>
                      }
                    />
                  </ListItem>

                  <Divider variant="inset" component="li" />

                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <AvatarStyled alt="semy Sharp" variant="square" src={require('../../assets/images/email.png')} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<TypoTitleStyled>Instruction</TypoTitleStyled>}
                      secondary={<Typography>{parse(templatedetails.message)}</Typography>}
                    />
                  </ListItem>

                  <Divider variant="inset" component="li" />
                  {alertDetails.attachement != '' ? (
                    <>
                      <Divider variant="inset" component="li" />
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <AvatarStyled
                            alt="semy Sharp"
                            variant="square"
                            src={require('../../assets/images/attach.png')}
                          />
                        </ListItemAvatar>

                        <ListItemText
                          primary={<TypoTitleStyled>Attachment</TypoTitleStyled>}
                          secondary={
                            <Box display="flex" alignItems="center">
                              {alertDetails.attachement
                                ? alertDetails.attachement.map((file, index) => {
                                    const extension_arr = file.image_name.split('.');
                                    // const extension = extension_arr[1];
                                    const temp_extension = extension_arr[1].split('"');
                                    const extension = temp_extension[0];
                                    if (
                                      extension == 'jpeg' ||
                                      extension == 'jpg' ||
                                      extension == 'png' ||
                                      extension == 'gif'
                                    ) {
                                      return (
                                        <TypoStyled variant="subtitle1" component="p" key={index}>
                                          <img
                                            src={`${file.file_name}`}
                                            className={classes.iconAttach}
                                            onClick={() => handleClickImageOpen(file.file_name)}
                                          />
                                          Image
                                        </TypoStyled>
                                      );
                                    } else if (extension == 'mp3') {
                                      return (
                                        // <TypoStyled variant='subtitle1' component='p' key={index}>
                                        //   <a style={{ textDecoration: 'none', color: 'black' }} href={`${file.attachment}`} target='_blank'>
                                        //     <Headset className={classes.iconAttach} />{docFile}
                                        //   </a>
                                        // </TypoStyled>
                                        <TypoStyled variant="subtitle1" component="p" key={index}>
                                          <a
                                            style={{
                                              textDecoration: 'none',
                                              color: 'black',
                                            }}
                                            href={`${file.attachment}`}
                                            target="_blank"
                                          >
                                            <Headset className={classes.iconAttach} />
                                            Audio
                                          </a>
                                        </TypoStyled>
                                      );
                                    } else if (extension == 'mp4') {
                                      return (
                                        // <TypoStyled variant='subtitle1' component='p' key={index}>
                                        //   <a style={{ textDecoration: 'none', color: 'black' }} href={`${file.attachment}`} target='_blank'>
                                        //     <Videocam className={classes.iconAttach} /> {docFile}
                                        //   </a>
                                        // </TypoStyled>
                                        <TypoStyled variant="subtitle1" component="p" key={index}>
                                          <a
                                            style={{
                                              textDecoration: 'none',
                                              color: 'black',
                                            }}
                                            href={`${file.attachment}`}
                                            target="_blank"
                                          >
                                            <Videocam className={classes.iconAttach} /> Video
                                          </a>
                                        </TypoStyled>
                                      );
                                    } else if (extension == 'doc' || extension == 'docx' || extension == 'pdf') {
                                      return (
                                        // <TypoStyled variant='subtitle1' component='p' key={index}>
                                        //   <a style={{ textDecoration: 'none', color: 'black' }} href={`${file.attachment}`} target='_blank'>
                                        //     <Description className={classes.iconAttach} /> {docFile}
                                        //   </a>
                                        // </TypoStyled>
                                        <TypoStyled variant="subtitle1" component="p" key={index}>
                                          <a
                                            style={{
                                              textDecoration: 'none',
                                              color: 'black',
                                            }}
                                            href={`${file.attachment}`}
                                            target="_blank"
                                          >
                                            <Description className={classes.iconAttach} />
                                            Document
                                          </a>
                                        </TypoStyled>
                                      );
                                    } else {
                                      return (
                                        <TypoStyled variant="subtitle1" component="p" key={index}>
                                          <a
                                            style={{
                                              textDecoration: 'none',
                                              color: 'black',
                                            }}
                                            href={`${file.attachment}`}
                                            target="_blank"
                                          >
                                            <Description className={classes.iconAttach} />
                                            Document
                                          </a>
                                        </TypoStyled>
                                      );
                                    }
                                  })
                                : 'No Attachments'}
                            </Box>
                          }
                        />
                      </ListItem>
                    </>
                  ) : (
                    ''
                  )}

                  {/* <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <AvatarStyled
                        alt="semy Sharp"
                        variant="square"
                        src={require('../../assets/images/user_icon.png')}
                      />
                    </ListItemAvatar>
                  </ListItem> */}
                </ListStyled>
              </>
            ) : (
              <Box className={classes.content}>
                <CardMedia className={classes.icon} image={RymindrOriginal} title="Emergency Alert" component="img" />
                <Typography className={classes.commingSoonDesc}>No record Found</Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
      {/* <Grid className="main-wrap-body msg-cont-wrap" container alignItems="stretch"></Grid> */}
      {/* IMAGE POPUP */}
      <Dialog open={openImage} onClose={handleCloseImageModal} aria-labelledby="form-dialog-title" maxWidth="xl">
        <DialogTitleStyled id="form-dialog-title">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex">
              <TypoPopHeadStyled>Attachment</TypoPopHeadStyled>
            </Box>
            <IconButton color="default" onClick={handleCloseImageModal}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitleStyled>
        <DialogContent>
          <center>
            <img
              src={`${selectedImage}`}
              // style={{ maxWidth:'-webkit-fill-available' }}
            />{' '}
          </center>
        </DialogContent>
      </Dialog>
      {/* IMAGE POPUP */}

      {/* DELETE POPUP */}
      <Grid className="main-wrap-head" container style={{ marginBottom: 15 }} alignItems="center">
        <ResponsiveDialog open={openDelete} handleClose={handleClose} deleteMsg={() => deleteMsg(alertDetails._id)} />
      </Grid>
    </>
  );
};

export default connect()(withSnackbar(Template));
