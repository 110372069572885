const firebaseConfig = {
  apiKey: "AIzaSyDVoA2XU7YDzVmm7RipAHqL7bTg17lHxEc",
  authDomain: "rymindr-me.firebaseapp.com",
  databaseURL: "https://rymindr-me.firebaseio.com",
  projectId: "rymindr-me",
  storageBucket: "rymindr-me.appspot.com",
  messagingSenderId: "1040706762557",
  appId: "1:1040706762557:web:caa5f6c0205d06a72e3754",
  measurementId: "G-36RQEFRJVG"
};

export { firebaseConfig };
