import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Link, useParams, useHistory } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import CircularSpinner from "../../component/CircularSpinner/index";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { connect } from "react-redux";
import { styled } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import { setupProfileFail } from "../../store/actions/auth";
import * as actions from "../../store/actions/index";

const GridStyled = styled(Grid)({
  height: "100%",
});

const PaperStyled = styled(Paper)({
  width: 450,
  textAlign: "center",
  padding: 30,
});

const TypoStyled = styled(Typography)(() => ({
  fontSize: 24,
  marginTop: 20,
  marginBottom: 30,
  fontWeight: "bold",
}));

const LinkStyled = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
}));

const SetupProfile = (props) => {
  const history = useHistory();
  const { success_message } = props;
  const { email, id } = useParams();
  const created_by = id;
  const FormFields = {
    contact_person: "",
    email: email,
    password: "",
    created_by: created_by,
  };
  const { enqueueSnackbar, error } = props;
  const [formvalues, setFormvalues] = useState(FormFields);
  const [formvalidation, setFormvalidation] = useState(FormFields);

  const handleChange = (e) => {
    const form = { ...formvalues };
    const formerr = { ...formvalidation };
    form[e.target.name] = e.target.value;
    formerr[e.target.name] = "";
    setFormvalues(form);
    setFormvalidation(FormFields);
  };

  // useEffect(() => {
  //   error && enqueueSnackbar(error, { variant: 'error' });
  // }, [error]);
  useEffect(() => {
    let mounted = true;
    setTimeout(function () {
      if (mounted) {
        error && enqueueSnackbar(error, { variant: "error" });
      }
    }, 0);
    setTimeout(function () {
      // Fail(null)
    }, 1000);
    return function cleanup() {
      mounted = false;
    };
  }, [error]);

  useMemo(() => {
    success_message && enqueueSnackbar(success_message, { variant: "success" });
  }, [success_message]);

  const [values, setValues] = useState({
    contact_person: "",
    email: email,
    password: "",
    created_by: id,
    showPassword: false,
  });

  const handlevalidation = () => {
    let error = false;
    const formerr = { ...formvalidation };
    if (!formvalues.contact_person) {
      error = true;
      formerr.contact_person = "Full name is required!";
      setFormvalidation(formerr);
    }

    if (!formvalues.password) {
      error = true;
      formerr.password = "Password is required!";
      setFormvalidation(formerr);
    }
    return error;
  };

  const submitHandler = (contact_person, email, password, created_by) => {
    //  
    // e.preventDefault();
    if (handlevalidation()) return false;
    props.setupProfile(
      {
        contact_person: contact_person,
        email: email,
        password: password,
        created_by: created_by,
      },
      history
    );
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <GridStyled
        container
        justify="center"
        alignItems="center"
        className="loginBg"
      >
        <PaperStyled>
          <img
            alt=""
            style={{ width: 130 }}
            src={require("../../assets/images/login_logo.png")}
          />
          <TypoStyled variant="h6">Create Profile</TypoStyled>
          <form validate="true">
            <TextField
              error={!!formvalidation.contact_person}
              helperText={formvalidation.contact_person}
              id="contact_person"
              // autoComplete='false'
              label="Full Name"
              placeholder="John Doe"
              name="contact_person"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              value={formvalues.contact_person}
              margin="normal"
            />
            <TextField
              style={{ paddingBottom: "15px" }}
              id="email"
              label="Email"
              placeholder="test1@hotmail.com"
              name="email"
              variant="outlined"
              fullWidth
              value={email}
              readOnly
              margin="normal"
            />
            {/* <FormControl fullWidth margin="normal"> */}
              <TextField
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                fullWidth
                placeholder="Minimum 8 Characters"
                error={!!formvalidation.password}
                label="Password"
                name="password"
                variant="outlined"
                onChange={handleChange}
                value={formvalues.password}
                // autoComplete="current-password"
                helperText={formvalidation.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            {/* </FormControl> */}

            <Box mt={4}>
              <Button
                className="MuiButton-join"
                variant="contained"
                color="primary"
                size="large"
                onClick={() =>
                  submitHandler(
                    formvalues.contact_person,
                    email,
                    formvalues.password,
                    created_by
                  )
                }
              >
                {props.setupProfileLoading && <CircularSpinner />}
                Join Rymindr
              </Button>
            </Box>
          </form>
          <Box flexDirection="row" mt={4}>
            <Typography
              style={{ fontSize: 14, fontWeight: "500" }}
              component="span"
            >
              By clicking Join Rymindr I agree to Rymindr
            </Typography>
            <LinkStyled style={{ fontSize: 14, fontWeight: "500" }} to="">
              {" "}
              Terms & Conditions, Privacy Policy.
            </LinkStyled>
          </Box>
        </PaperStyled>
      </GridStyled>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    setupProfileLoading: state.auth.setupProfileLoading,
    error: state.auth.error,
    success_message: state.contact.success_message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setupProfile: (contact_person, email, password, created_by) =>
      dispatch(
        actions.setupProfile(contact_person, email, password, created_by)
      ),
    setupProfilFail: (err) => dispatch(setupProfileFail(err)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(SetupProfile));
